import React, { useEffect, useState } from "react";
import personStar from "../../../Assets/Images/icons/personStar.svg";
import analytics from "../../../Assets/Images/icons/analytics.svg";
import switchUser from "../../../Assets/Images/icons/switchUser.svg";

const IllustrationHeader = ({ index }) => {
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");
  useEffect(() => {
    setText(
      index === 1
        ? "Primary Deals"
        : index === 2
        ? "Manage Portfolio"
        : index === 3
        ? "Secondary deals"
        : ""
    );
    setIcon(
      index === 1
        ? personStar
        : index === 2
        ? analytics
        : index === 3
        ? switchUser
        : ""
    );
  });
  return (
    <div className="flex flex-col items-center justify-center gap-1">
      <div className="flex flex-col items-center justify-center gap-2">
        <img
          src={icon}
          alt="Primary deals"
          className="w-5 2xl:w-[30px] laptop-design"
        />
        <p className="text-xs font-medium text-center md:text-base 2xl:text-res-base">
          {text}
        </p>
      </div>
    </div>
  );
};

export default IllustrationHeader;
