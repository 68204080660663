import React, { useState } from "react";
import { fetchBlobName, timeDiff } from "../functions";
import { ToggleResolveThread } from "../../../endpoints/deal";

import { Link } from "react-router-dom";
import moment from "moment";
import CustomTooltip from "./custom/customToolTip";

import editIcon from "../../../Assets/Images/InvestorPage/Dashboard/editIcon.svg";
import reply from "../../../Assets/Images/InvestorPage/Dashboard/reply.svg";
import resolved from "../../../Assets/Images/InvestorPage/Dashboard/resolved.svg";
import resolvedBlue from "../../../Assets/Images/InvestorPage/Dashboard/resolvedBlue.svg";
import FileImg from "../../../Assets/Images/signup/file.svg";
import download from "../../../Assets/Images/InvestorPage/Dashboard/download.svg";
import userProfile from "../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
import Toast from "../Toast";
const Message = (props) => {
  const token = localStorage.getItem("token");
  const handleReply = () => {
    props.handleReplyData(props.name, props.threadID);
  };
  const handleResolveThread = async (threadID, personalID, status) => {
    const data = {
      threadID: threadID,
      resolvedBy: personalID,
    };
    const result = await ToggleResolveThread(data, token);
    if (result.data) {
      props.fetchMessages();
      if (status) {
        Toast("Thread opened Successfully", "success");
      } else {
        Toast("Thread resolved Successfully", "success");
      }
    }
  };
  const handleEdit = (message, messageID, file) => {
    props.handleEdit(message, messageID, file);
  };
  return (
    <div
      className={`message-div ${props.isThreadResolved ? "resolved-div" : ""}`}
      style={{
        display:
          (props.option === "resolved" && props.isThreadResolved) ||
          (props.option === "unresolved" && !props.isThreadResolved) ||
          props.option === "all"
            ? "flex"
            : "none",
      }}
    >
      <div className="message-div-2">
        <img
          loading="lazy"
          srcSet={props.profilePhoto || userProfile}
          className="message-img"
        />
        <div className="nameandrole">
          <div className="message-div-3">
            {props.name}&nbsp;&nbsp;
            {props.personalID === props.userID ? (
              <button className="you-btn">you</button>
            ) : (
              ""
            )}
          </div>
          <div className="message-div-6">
            {props.role}
            {props.firmName ? ` | ${props.firmName}` : ""}
          </div>
        </div>

        <div className="message-div-4">
          {moment(props.time).format("DD/MM/YYYY h:mm A")}
        </div>
      </div>
      {props.file ? (
        <div className="NDA-document">
          <div className="NDA-document-left">
            <img src={FileImg} className="FileImg" />
            <div className="NDA-document-name">
              <p>{fetchBlobName(props.file)}</p>
            </div>
          </div>
          <div className="NDA-document-right">
            <Link to={props.file}>
              <img src={download} />
            </Link>
          </div>
        </div>
      ) : null}
      <div className="message-div-5">{props.message}</div>
      <div className="edit-option">
        {/* //There are Four types of condtionals in the below code snippet
        //1. If the thread is not resolved and the stage is latest stage and the user is investor or company and the stage is not requestAccepted then we show the reply icon
        //2. If the thread is not resolved and the stage is latest stage and the user is company and the stage is requestAccepted then we show nothing
        //3. If the thread resolved whatever the user and stage, we show the resolved icon.
        //4. If the stage is not latest and thread is not resolved then whatever the user is, we show nothing.
        */}
        {!props.isThreadResolved &&
        props.stage === props.latestStage &&
        (props.userRole === "Investor" ||
          (props.userRole === "Company" &&
            props.stage !== "requestAccepted")) ? (
          <CustomTooltip
            title="Reply to this message"
            position="bottom"
            theme="black"
          >
            <img
              className="edit-option-img"
              src={reply}
              onClick={handleReply}
            />
          </CustomTooltip>
        ) : !props.isThreadResolved &&
          props.userRole === "Company" &&
          props.stage === "requestAccepted" ? null : (
          props.isThreadResolved && (
            <div className="resolvedBy-div">
              <img
                className="edit-option-img"
                src={resolvedBlue}
                onClick={() =>
                  handleResolveThread(
                    props.threadID,
                    props.personalID,
                    props.isThreadResolved
                  )
                }
              />
              {props.resolvedBy && (
                <div className="resolved-by">
                  <span className="nda-bold-text">{props.resolvedBy}</span>{" "}
                  Resolved the thread on{" "}
                  <span className="nda-bold-text">
                    {moment(props.updatedAt).format("DD/MM/YYYY h:mm A")}
                  </span>
                </div>
              )}
            </div>
          )
        )}
        {!props.isThreadResolved &&
          props.stage === props.latestStage &&
          ((props.userRole === "Company" &&
            props.stage !== "requestAccepted") ||
            props.userRole === "Investor") && (
            <CustomTooltip
              title="Resolve this thread"
              position="bottom"
              theme="black"
            >
              <img
                className="edit-option-img"
                src={resolved}
                onClick={() =>
                  handleResolveThread(
                    props.threadID,
                    props.personalID,
                    props.isThreadResolved
                  )
                }
              />
            </CustomTooltip>
          )}
        {!props.isThreadResolved &&
          props.stage === props.latestStage &&
          props.personalID === props.userID &&
          timeDiff(props.time, 12) && (
            <CustomTooltip
              title="Edit this message"
              position="bottom"
              theme="black"
            >
              <img
                className="edit-option-img"
                src={editIcon}
                onClick={() =>
                  handleEdit(props.message, props.messageID, props.file)
                }
              />
            </CustomTooltip>
          )}
      </div>
    </div>
  );
};

export default Message;
