import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import founders1 from "../../../Assets/Images/LandingPage/illustrations/founders1.svg";
import founders2 from "../../../Assets/Images/LandingPage/illustrations/founders2.svg";
import founders3 from "../../../Assets/Images/LandingPage/illustrations/founders3.svg";
import investors1 from "../../../Assets/Images/LandingPage/illustrations/investors1.svg";
import investors2 from "../../../Assets/Images/LandingPage/illustrations/investors2.svg";
import investors3 from "../../../Assets/Images/LandingPage/illustrations/investors3.svg";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";

import blackArrow from "../../../Assets/Images/icons/blackArrow.svg";
import whiteArrow from "../../../Assets/Images/icons/whiteArrow.svg";
import { Link } from "react-router-dom";
import { RetroGrid } from "../../Common/animations/retroGrid";

const menuItems = {
  founders: [
    {
      title: "Raise",
      content:
        "Fundrev connects companies with investors aligned to their profiles, offering secure data rooms for due diligence, streamlining the fundraising process",
    },
    {
      title: "Investor Reporting",
      content:
        "Manage investor relationships effortlessly with centralized reporting and communication tools to keep stakeholders updated",
    },
    {
      title: "M&A",
      content:
        "Explore strategic opportunities to merge, acquire, or sell with tailored support for every stage of the process",
    },
  ],
  investors: [
    {
      title: "Invest",
      content:
        "Discover and invest in high-potential startups that match your investment thesis with personalized deal recommendations.",
    },
    {
      title: "Monitoring",
      content:
        "Keep track of your investments, analyze performance, and manage your portfolio effectively, all in one place",
    },
    {
      title: "Exits",
      content:
        "Plan and execute seamless exits, whether through secondary sales, acquisitions, or other liquidity options",
    },
  ],
};
const bgImages = {
  investors: [investors1, investors2, investors3],
  founders: [founders1, founders2, founders3],
};

function MenuItem({ title, isActive, content, onClick }) {
  return (
    <>
      <div
        className="flex gap-3.5 items-center self-start whitespace-nowrap cursor-pointer w-full"
        onClick={onClick}
      >
        <div
          className={`self-stretch my-auto text-base  md:text-xl 2xl:text-res-xl ${
            isActive && "mb-6"
          }`}
        >
          {title}
        </div>
        {!isActive && (
          <img
            loading="lazy"
            src={blackArrow}
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-[1.63] w-[13px]"
          />
        )}
      </div>
      <AnimatePresence initial={false}>
        {isActive && (
          <motion.div
            key="content"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="2xl:text-res-sm text-stone-500 max-lg:max-w-full overflow-hidden text-sm"
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
      <div className="my-6 w-full border-[0.5px] border-neutral-300 min-h-[1px] max-lg:max-w-full" />
    </>
  );
}

export function RoleBasedSection({
  role,
  activeSection,
  onSectionChange,
  investorsRef,
  foundersRef,
}) {
  const isInvestor = role === "investor";
  const sectionTitle = isInvestor ? "FOR INVESTORS" : "FOR COMPANIES";
  const heading = isInvestor ? "Curated Deals" : "Be Deal Ready 24x7";
  const menuData = isInvestor ? menuItems.investors : menuItems.founders;

  const imageUrls = isInvestor ? bgImages.investors : bgImages.founders;
  const subheading = isInvestor
    ? "Discover high-potential startups with personalized deal recommendations. Effortlessly manage your portfolio, track performance, and execute seamless exits through tailored liquidity solutions"
    : "Streamline fundraising with tailored investor recommendations, simplify investor relations with centralized reporting, and explore strategic M&A opportunities with end-to-end support—all in one platform";

  // Determine the image index based on the active section
  const activeIndex = menuData.findIndex(
    (item) => item.title === activeSection
  );
  const activeImageUrl = imageUrls[activeIndex] || imageUrls[0]; // Fallback to the first image if no activeSection

  const direction = isInvestor ? "reverse" : "normal";
  const btnAction = isInvestor ? "Check listings" : "Schedule A Call";
  const btnUrl = isInvestor
    ? "/investor/deals"
    : "https://calendly.com/hello-fundrev";

  return (
    <div className="lg:px-16 px-6 rounded-none">
      <div
        className={`flex gap-5 max-lg:flex-col ${
          direction === "reverse" ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <div className="max-lg:ml-0 max-lg:w-full  flex flex-col w-6/12 m-auto">
          <div
            className={`flex flex-col grow max-lg:mt-10 max-lg:max-w-full ${
              direction === "reverse"
                ? "lg:ml-[20px] 2xl:ml-[40px]"
                : "items-start"
            }`}
          >
            <div className="flex flex-col max-w-full lg:w-[495px] w-full">
              <div
                className="text-[13px] 2xl:text-xl text-base text-blue-500 tracking-[3.2px] max-lg:max-w-full scroll-m-28"
                ref={role === "investor" ? investorsRef : foundersRef}
              >
                {sectionTitle}
              </div>
              <div className="flex flex-col mt-4 md:mt-[30px] w-full">
                <div className="text-[28px] md:text-h2 2xl:text-res-h2 font-medium text-neutral-700 max-lg:max-w-full">
                  {heading}
                </div>
                <div className="2xl:text-res-sm text-stone-500 max-lg:max-w-full mt-8 text-sm">
                  {subheading}
                </div>
              </div>
              <CustomBlackBtn
                mode="blue"
                border="sm"
                style={{
                  marginTop: "30px",
                }}
                additionalClasses="2xl:px-[30px] 2xl:h-[60px]  2xl:text-res-base"
                text={
                  <Link to={btnUrl}>
                    <div className="flex gap-2">
                      <p>{btnAction}</p>
                      <img
                        src={whiteArrow}
                        alt="Arrow"
                        className="2xl:w-5 w-4"
                      />
                    </div>
                  </Link>
                }
              />
            </div>

            <div className="flex flex-col mt-10 text-xl font-medium  w-full lg:w-[495px] 2xl:w-[625px] text-neutral-700 max-lg:mt-10 max-lg:max-w-full">
              {menuData.map((item) => (
                <MenuItem
                  key={item.title}
                  title={item.title}
                  isActive={activeSection === item.title}
                  content={item.content}
                  onClick={() => onSectionChange(item.title)}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={`relative flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full justify-center ${
            direction === "reverse"
              ? "items-center lg:max-w-[580px] 2xl:max-w-[725px] w-full"
              : "items-start"
          }`}
        >
          <img
            loading="lazy"
            src={activeImageUrl}
            alt={`${role} illustration`}
            className="object-contain mt-2 w-full rounded-none aspect-[1.04] max-lg:mt-10 max-lg:max-w-full z-10"
          />
          <div className="faded-content absolute top-0 w-full h-full">
            <RetroGrid angle={0} />
          </div>
        </div>
      </div>
    </div>
  );
}
