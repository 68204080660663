import React, { useEffect, useState } from "react";
import { getInvestorToolkitInvestorData } from "../../../endpoints/startup";
import InvestorToolkitPlot from "./investorToolkitPlot";
import { Box, Typography } from "@mui/material";
import Back from "../../../Assets/Images/signup/back.svg";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLoader } from "../../Common/LoaderProvider";
import Toast from "../../Common/Toast";
import { ShinyButton } from "../../Common/animations/shinyButton";
const InvestorToolkitResult = ({ industry, setStep, alreadyExists }) => {
  const [data, setData] = useState(null);
  const loader = useLoader();
  const navigate = useNavigate();
  const getInvestorToolkitData = async () => {
    if (industry) {
      const response = await getInvestorToolkitInvestorData(industry);
      loader.stop();
      if (response) {
        setData(response);
      } else {
        loader.stop();
        Toast(
          `Something went wrong! Please try again.`,
          `info`,
          `investor-toolkit`
        );
        setStep(5);
      }
    } else {
      loader.stop();
      Toast(
        `Something went wrong! Please try again.`,
        `info`,
        `investor-toolkit`
      );
      setStep(5);
    }
  };

  useEffect(() => {
    loader.start();
    getInvestorToolkitData();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F6F8FF",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            "@media (max-width: 1000px)": {
              display: "none",
            },
            position: "absolute",
            left: 0,
          }}
        >
          <div className="investor-sign-up-page-header">
            <Link to="/">
              <img src={Logo} alt="fundrev_logo" />
            </Link>
          </div>
        </Box>
        <Box
          sx={{
            "@media (max-width: 775px)": {
              display: "none",
            },
            marginRight: "10px",
          }}
        >
          <img
            src={Back}
            alt="back"
            className="back-icon laptop-design"
            onClick={() => setStep(3)}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            height: "100vh",
            width: "694px",
            padding: "33px",
            overflowY: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                "@media (min-width: 1000px)": {
                  display: "none",
                },
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img src={Logo} alt="fundrev_logo" height="40px" width="180px" />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                flexWrap: "nowrap",
              }}
            >
              <Box
                sx={{
                  "@media (min-width: 775px)": {
                    display: "none",
                  },
                }}
              >
                <img
                  src={Back}
                  alt="back"
                  className="back-icon"
                  onClick={() => setStep(3)}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "40px",
                  fontWeight: "200",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                Investor Activity
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "35px",
                  color: "#696F79",
                  textAlign: "center",
                }}
              >
                {data?.totalInvestors} Investors
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                lineHeight: "35px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              are currently looking for startups like yours
            </Typography>
            <Box>
              <img
                src={data?.activeInvestor}
                alt="activeInvestor"
                style={{
                  height: "88px",
                  width: "226px",
                }}
              />
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Most Active Investor
            </Typography>
          </Box>
          <div
            className="sign-up-btn-section"
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
            onClick={() =>
              alreadyExists
                ? navigate("/signin", {
                    state: { sentemail: data.email, type: "Startup" },
                  })
                : setStep(5)
            }
          >
            <ShinyButton
              type="submit"
              className={`rounded-full h-12 px-6 py-3`}
            >
              Raise Capital
            </ShinyButton>
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            {data && (
              <InvestorToolkitPlot
                data={{
                  xLabels: data?.xLabels,
                  values: data?.dealCount,
                }}
                title={`Quarter on Quarter Deals`}
              />
            )}
            {data && (
              <InvestorToolkitPlot
                data={{
                  xLabels: data?.xLabels,
                  values: data?.RoundSize,
                }}
                title={`Round Size`}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                marginBottom: "8px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              Raise funds from top institutional investors on Fundrev
            </Typography>
            <Box
              sx={{
                "@media (max-width: 576px)": {
                  marginBottom: "50px",
                },
              }}
            >
              <div className="flex flex-wrap gap-6 items-center mt-4 w-full max-md:max-w-full">
                <div className="noName-investorFirms-section flex flex-wrap gap-6 items-center self-stretch my-auto min-w-[240px] justify-center max-md:max-w-full">
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/a91.png"
                      className="object-contain max-w-full aspect-[3] w-[120px]"
                      alt="a91"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-3.5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/firside.png"
                      className="object-contain max-w-full aspect-[2.79] w-[146px]"
                      alt="Fireside"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-6 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/adityaBirla.png"
                      className="object-contain max-w-full aspect-[3.53] w-[120px]"
                      alt="Aditya Birla"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-2.5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/rockstud.png"
                      className="object-contain max-w-full aspect-[1.88] w-[113px]"
                      alt="Rockstud"
                    />
                  </div>
                  <div className="more-companies-text self-stretch my-auto text-3xl font-light text-gray-500">
                    +24 more
                  </div>
                </div>
              </div>
            </Box>
            <div
              className="sign-up-btn-section laptop-design"
              style={{
                marginTop: "24px",
                marginBottom: "80px",
              }}
              onClick={() =>
                alreadyExists
                  ? navigate("/signin", {
                      state: { sentemail: data.email, type: "Startup" },
                    })
                  : setStep(5)
              }
            >
              <ShinyButton
                type="submit"
                className={`rounded-full h-12 px-6 py-3`}
              >
                Raise with Fundrev
              </ShinyButton>
            </div>
            <div
              className="sign-up-btn-section-phone phone-design"
              onClick={() =>
                alreadyExists
                  ? navigate("/signin", {
                      state: { sentemail: data.email, type: "Startup" },
                    })
                  : setStep(5)
              }
            >
              <CustomBlackBtnPhone type="submit" text="Raise with Fundrev" />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InvestorToolkitResult;
