import React, { useEffect } from "react";
import { Box } from "@mui/material";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import FinancialStatementTable from "./AnalyticsPlots/FinancialStatementTable";
import FinancialStatementSmallComparisonTable from "./AnalyticsPlots/FinancialStatementSmallComparisonTable";
import FinancialStatementBigComparisonTable from "./AnalyticsPlots/FinancialStatementBigComparisonTable";
import { dumpInvestorInteraction } from "../../../../../endpoints/common";
const FinancialStatementAnalytics = ({ data, deal, stage }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "companyOverview",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <>
      {data && (
        <div>
          <FinancialStatementTable data={data?.tableData} />
          <div
            style={{
              height: "10px",
            }}
          />
          <FinancialStatementBigComparisonTable data={data?.tableData} />
          <div
            style={{
              height: "10px",
            }}
          />
          {/* <FinancialStatementSmallComparisonTable data={data?.tableData} /> */}
          {/* <Box
            sx={{
              marginTop: 10,
              marginBottom: 10,
              background: "white",
              width: "1141px",
              height: "450px",
              borderRadius: "8px",
              paddingX: "10px",
            }}
          >
            <FinancialPlot data={data?.plotData} />
          </Box> */}
        </div>
      )}
    </>
  );
};

export default FinancialStatementAnalytics;
