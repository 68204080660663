import React, { useEffect } from "react";
import Header from "../../Components/LandingPage/sections/header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>Fundrev | Terms Of Use</title>
      </Helmet>
      <div className="landing-page-terms min-h-screen p-6">
        <div
          style={{
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
          }}
          className="mx-auto bg-white p-8 mt-[70px] mb-[20px] max-w-[1300px] rounded-[10px]"
        >
          <h1 className="text-3xl font-bold mb-6 text-center">Terms of Use</h1>

          <ol className="list-decimal pl-6 space-y-6">
            <li>
              <h2 className="text-xl font-semibold mb-3">Introduction</h2>
              <p>
                Welcome to Fundrev! These Terms of Use ("Terms") govern your
                access to and use of our platform including any content,
                functionality, and services offered on or through the website
                (collectively the "Platform"). By accessing or using the
                Platform, you agree to comply with and be bound by these Terms.
                If you do not agree to these Terms, you must not use the
                Platform.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Definitions</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Fundrev:</strong> Refers to Fundrev Technologies
                  Private Limited with CIN U63999KA2024PTC192050 having its
                  registered office at WEWORK PRESTIGE CUBE NO 26 LASKAR HOSUR
                  ROAD Adugodi Bangalore South Bangalore- 560030 Karnataka.
                </li>
                <li>
                  <strong>Platform:</strong> The website operated by Fundrev
                  including all services and content provided through it.
                </li>
                <li>
                  <strong>User:</strong> Any individual or entity accessing or
                  using the Platform, including but not limited to Investors and
                  Fundraisers/Sellers.
                </li>
                <li>
                  <strong>Services:</strong> The services provided by Fundrev
                  through the Platform including investment facilitation,
                  fundraising support, financial advisory, and related services.
                </li>
                <li>
                  <strong>Content:</strong> All text, graphics, images,
                  software, and other materials made available on the Platform.
                </li>
                <li>
                  <strong>Agreement:</strong> Refers collectively to these
                  Terms, the Privacy Policy, and any other agreements or terms
                  applicable to specific services.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Eligibility</h2>
              <p>
                To use the Platform, you must be at least 18 years old and have
                the legal capacity to enter into binding contracts. By accessing
                the Platform, you represent and warrant that you meet these
                eligibility requirements.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Account Registration
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Provide Accurate Information:</strong> You must
                  provide true, accurate, current, and complete information
                  during the registration process.
                </li>
                <li>
                  <strong>Maintain Security:</strong> You are responsible for
                  maintaining the confidentiality of your account credentials
                  and for all activities that occur under your account.
                </li>
                <li>
                  <strong>Notification of Breach:</strong> You agree to notify
                  Fundrev immediately of any unauthorized access to or use of
                  your account.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Use of the Platform
              </h2>
              <p>
                You agree to use the Platform only for lawful purposes and in
                accordance with these Terms:
              </p>
              <h3 className="text-lg font-medium mt-3">
                Prohibited Activities
              </h3>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Illegal Activities:</strong> Engaging in any illegal
                  activity or behavior that violates applicable laws or
                  regulations.
                </li>
                <li>
                  <strong>Misuse of Services:</strong> Attempting to gain
                  unauthorized access to the Platform, its related systems, or
                  networks, or interfering with the operation of the Platform.
                </li>
                <li>
                  <strong>False Information:</strong> Providing false,
                  misleading, or inaccurate information when using the Platform.
                </li>
                <li>
                  <strong>Harmful Conduct:</strong> Engaging in any conduct that
                  could harm the reputation, interests, or rights of Fundrev or
                  other Users.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">User Content</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Responsibility:</strong> You are solely responsible
                  for any content you upload, post, or transmit through the
                  Platform.
                </li>
                <li>
                  <strong>License:</strong> By submitting content to the
                  Platform, you grant Fundrev a worldwide, non-exclusive,
                  royalty-free, transferable, and sub-licensable license to use,
                  reproduce, distribute, and display your content.
                </li>
                <li>
                  <strong>Prohibited Content:</strong> You agree not to upload,
                  post, or transmit any content that is unlawful, harmful,
                  defamatory, obscene, or otherwise objectionable.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Intellectual Property
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Ownership:</strong> All intellectual property rights
                  in the Platform, including but not limited to trademarks,
                  copyrights, and proprietary information, are owned by or
                  licensed to Fundrev.
                </li>
                <li>
                  <strong>User License:</strong> Fundrev grants you a limited,
                  non-exclusive, non-transferable, and revocable license to
                  access and use the Platform in accordance with these Terms.
                </li>
                <li>
                  <strong>Restrictions:</strong> You may not copy, modify,
                  distribute, sell, or lease any part of the Platform or the
                  content on it without Fundrev's prior written consent.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Third-Party Links</h2>
              <p>
                The Platform may contain links to third-party websites or
                services that are not owned or controlled by Fundrev. Fundrev is
                not responsible for the content, privacy policies, or practices
                of any third-party websites or services. You acknowledge and
                agree that Fundrev is not liable for any damages or losses
                caused by your use of or reliance on any third-party content,
                products, or services.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Disclaimer of Warranties
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>As-Is Basis:</strong> The Platform and all content and
                  services provided through it are offered on an "as-is" and
                  "as-available" basis without any warranties of any kind,
                  either express or implied.
                </li>
                <li>
                  <strong>No Warranty:</strong> Fundrev makes no representations
                  or warranties regarding the accuracy, completeness, security,
                  or reliability of the Platform.
                </li>
                <li>
                  <strong>Limitation:</strong> Fundrev disclaims all warranties,
                  including but not limited to implied warranties of
                  merchantability, fitness for a particular purpose, and
                  non-infringement.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Limitation of Liability
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>No Liability:</strong> To the fullest extent permitted
                  by law, Fundrev will not be liable for any indirect,
                  incidental, special, consequential, or punitive damages,
                  including but not limited to loss of profits, data, or
                  goodwill.
                </li>
                <li>
                  <strong>Cap on Damages:</strong> Fundrev’s total liability to
                  you for any claims arising out of or relating to these Terms
                  or your use of the Platform is limited to the amount paid by
                  you, if any, to Fundrev in the twelve (12) months preceding
                  the claim.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Indemnification</h2>
              <p>
                You agree to indemnify, defend, and hold harmless Fundrev, its
                affiliates, and their respective directors, officers, employees,
                and agents from and against any and all claims, liabilities,
                damages, losses, and expenses (including reasonable attorneys'
                fees) arising out of or in any way connected with your use of
                the Platform, your violation of these Terms, or your violation
                of any rights of another.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Termination</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Fundrev’s Right:</strong> Fundrev may terminate or
                  suspend your access to the Platform at any time, with or
                  without cause, and with or without notice.
                </li>
                <li>
                  <strong>Your Obligations:</strong> Upon termination, you must
                  cease all use of the Platform and destroy any copies of
                  content you have obtained through it.
                </li>
                <li>
                  <strong>Survival:</strong> The provisions of these Terms that
                  by their nature shall survive termination including but not
                  limited to provisions on intellectual property, disclaimers,
                  indemnity, and limitations of liability.
                </li>
              </ol>
            </li>
            <li>
              <h2 className="text-xl font-semibold mb-3">
                Governing Law and Dispute Resolution
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Governing Law:</strong> These Terms shall be governed
                  by and construed in accordance with the laws of India without
                  regard to its conflict of law principles.
                </li>
                <li>
                  <strong>Arbitration:</strong> Any disputes arising out of or
                  relating to these Terms or the use of the Platform shall be
                  referred to a sole Arbitrator appointed mutually by both the
                  Parties. The Arbitration shall be conducted in accordance with
                  the provisions of the Arbitration and Conciliation Act 1996,
                  and the decision of the Sole Arbitrator shall be final and
                  binding on the Parties. The Arbitration proceedings shall be
                  conducted in the English language at Bangalore, India, and the
                  judgment on the award rendered by the Sole Arbitrator may be
                  entered in any court having jurisdiction thereof.
                </li>
                <li>
                  <strong>Jurisdiction:</strong> Subject to the arbitration
                  clause, the courts in Bangalore, India shall have exclusive
                  jurisdiction over any disputes arising out of these Terms.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Changes to the Terms
              </h2>
              <p>
                Fundrev reserves the right to modify these Terms at any time.
                Any changes will be effective immediately upon posting on the
                Platform. Your continued use of the Platform following the
                posting of revised Terms constitutes your acceptance of the
                changes. It is your responsibility to review these Terms
                periodically for updates.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Miscellaneous</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Entire Agreement:</strong> These Terms, together with
                  the Privacy Policy and any other legal notices published by
                  Fundrev on the Platform, constitute the entire agreement
                  between you and Fundrev.
                </li>
                <li>
                  <strong>Severability:</strong> If any provision of these Terms
                  is found to be invalid or unenforceable, the remaining
                  provisions will continue in full force and effect.
                </li>
                <li>
                  <strong>Waiver:</strong> No waiver of any term of these Terms
                  will be deemed a further or continuing waiver of such term or
                  any other term, and Fundrev's failure to assert any right or
                  provision under these Terms will not constitute a waiver of
                  such right or provision.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Contact Information
              </h2>
              <p>
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <ul className="list-disc pl-6">
                <li>Email: compliance@fundrev.ai</li>
                <li>
                  Address: WEWORK PRESTIGE CUBE NO 26 LASKAR HOSUR ROAD Adugodi
                  Bangalore South Bangalore- 560030 Karnataka
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      <div className="landing-page ">
        <Footer />
      </div>
    </>
  );
};

export default TermsOfUse;
