import * as React from "react";

const investorLogos = [
  {
    id: 1,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/piVentures.png",
    alt: "Pi ventures",
  },
  {
    id: 2,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/rockstud.png",
    alt: "Rockstud Capital",
  },
  {
    id: 3,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/a91.png",
    alt: "A 91",
  },
  {
    id: 4,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/adityaBirla.png",
    alt: "Aditya Birla",
  },
  {
    id: 5,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/fireSide.png",
    alt: "Fireside ventures",
  },
  {
    id: 6,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/trifecta.png",
    alt: "Trifecta Capital",
  },
  {
    id: 7,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/bv.png",
    alt: "BV ventures",
  },
  {
    id: 8,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/Transition.png",
    alt: "Transition",
  },
  {
    id: 9,
    src: "https://fundrevstorage2.blob.core.windows.net/website-images/bw/tinmen.png",
    alt: "Tinmen",
  },
];

function InvestorLogo({ src, alt }) {
  return (
    <img
      loading="lazy"
      src={src}
      alt={alt}
      className="object-contain shrink-0 aspect-[2.75] w-[110px] md:w-[143px] 2xl:w-[180px]"
    />
  );
}

export function TopInvestors({ page }) {
  return (
    <div
      style={{
        marginTop: page === "openListing" && "0px",
        marginBottom: page === "openListing" && "0px",
      }}
      className="flex flex-col max-w-[972px] 2xl:max-w-[1215px] m-auto my-24 2xl:my-32"
    >
      <h2 className="font-satoshi  self-center text-xl 2xl:text-[25px] font-bold text-center text-[var(--Primary-Text-Color)]">
        {page === "openListing"
          ? "Join leading investment firms in making data-driven investments!"
          : "Top investors registered on fundrev"}
      </h2>
      <div className="flex flex-wrap items-start justify-center w-full gap-8 mt-16 bg-white md:gap-16 max-md:mt-10 max-md:max-w-full">
        {investorLogos.map((logo) => (
          <InvestorLogo key={logo.id} src={logo.src} alt={logo.alt} />
        ))}
      </div>
    </div>
  );
}
