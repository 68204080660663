import {
  Box,
  Modal,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cross from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getCollectiveCalendarFreeSlots,
  scheduleMicrosoftEvent,
} from "../../../../endpoints/investor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { ToastContainer } from "react-toastify";
import Toast from "../../../Common/Toast";
import { StyledFormControl } from "../../../Common/functions";
import CustomInput from "../../../Common/components/custom/customInput";
import { ArrowLeftIcon, CalendarIcon, GlobeIcon } from "@radix-ui/react-icons";
import greenTick from "../../../../Assets/Images/icons/greenTick.svg";
import { Person2Outlined } from "@mui/icons-material";
import { useLoader } from "../../../Common/LoaderProvider";
import { dumpInvestorInteraction } from "../../../../endpoints/common";

dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleMeeting = ({ deal, modal, setModal, stage }) => {
  const loader = useLoader();
  const [selectedDate, setSelectedDate] = useState(null);
  const [freeSlots, setFreeSlots] = useState([]);
  const token = localStorage.getItem(`token`);
  const [data, setData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [step, setStep] = useState(1);
  const [meetingAgenda, setMeetingAgenda] = useState("");
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      borderRadius: "4px",
      height: "42px",
      border: "1px solid var(--brand-color)",
      color: "var(--brand-color)",
      width: "100px",
      '&[aria-pressed="true"]': {
        color: "white",
        background: "var(--brand-color)",
        borderColor: "var(--brand-color)",
        border: "1px solid var(--brand-color)",
        "&:hover": {
          backgroundColor: "var(--brand-color)",
        },
      },
    },
  }));
  const dumpInteraction = async () => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.dealId,
          type: "click",
          page: "companyOverview",
          stageOfDeal: stage,
          element: "scheduleMeeting",
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const handleInput = (e) => {
    setMeetingAgenda(e.target.value);
  };
  const handleSchedule = () => {
    if (step === 3) {
      setStep(1);
      setModal(false);
      setMeetingAgenda(null);
      setSelectedDate(null);
      setFreeSlots([]);
    }
    if (step === 1) {
      if (selectedSlot) setStep(2);
      else Toast(`Please select a slot.`, `info`, `ScheduleMeeting`);
    }
    if (step === 2) {
      if (selectedSlot) {
        handleSubmit();
      } else {
        Toast(`Please select a slot.`, `info`, `ScheduleMeeting`);
      }
    }
  };

  const handleSubmit = async () => {
    loader.start(`Scheduling...`);
    dumpInteraction();
    const response = await scheduleMicrosoftEvent(
      deal.dealId || null,
      meetingAgenda,
      selectedSlot,
      token
    );
    loader.stop();
    if (response) {
      setStep(3);
    } else {
      Toast(
        `Failed to schedule meeting. Please try again.`,
        `error`,
        `ScheduleMeeting`
      );
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };
  const handleSlotClick = (e) => {
    setSelectedSlot(e.target.value);
  };
  useEffect(() => {
    if (selectedDate && data?.length > 0) {
      // Get the current time and add the IST offset (5 hours 30 minutes)
      const currentTime = dayjs().tz(`Asia/Kolkata`);

      // Filter the slots based on the selected date and time
      const filteredSlots = data.filter((slot) => {
        // Parse the start and end times as dayjs objects, assuming they are in IST
        const slotStart = dayjs(slot.start).tz(`Asia/Kolkata`);
        // Check if the slot's date matches the selectedDate
        const isSameDate = slotStart.isSame(selectedDate, "day");

        // Ensure the slot's start time is greater than the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });
      // Set the filtered slots to the state
      setFreeSlots(filteredSlots);
    }
  }, [data, selectedDate]);

  const fetchFreeSlots = async () => {
    if (modal) {
      loader.start(`Fetching Slots`);
      const response = await getCollectiveCalendarFreeSlots(
        deal?.organizationId,
        token
      );
      loader.stop();
      setData(response);
    }
  };
  useEffect(() => {
    fetchFreeSlots();
  }, [modal]);

  const handleClose = () => {
    setModal(false);
  };

  const shouldDisableDate = (date) => {
    if (data?.length > 0) {
      // Get the current time with IST timezone
      const currentTime = dayjs().tz("Asia/Kolkata");

      // Filter slots based on selected date and time
      const availableSlots = data.filter((slot) => {
        // Parse the slot's start time as a dayjs object in IST
        const slotStart = dayjs(slot.start).tz("Asia/Kolkata");
        // Check if the slot's date matches the selected date
        const isSameDate = slotStart.isSame(date, "day");
        // Ensure the slot's start time is after the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });

      // Disable the date if there are no available slots
      return availableSlots.length === 0;
    }
  };
  return (
    <>
      <ToastContainer containerId={`ScheduleMeeting`} position="top-center" />
      <Modal open={modal} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            alignItems: "center",
            height: "fit-content",
            width: "600px",
            backgroundColor: "white",
            borderRadius: "20px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <p className="font-[Roboto] text-xl">Schedule Meeting</p>

              {
                <img
                  src={cross}
                  alt="cross"
                  onClick={handleClose}
                  className="cursor-pointer"
                />
              }
            </Box>
            {step === 1 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <Box>
                  Please select a date to schedule
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      disablePast
                      showDaysOutsideCurrentMonth
                      views={["day"]}
                      value={selectedDate}
                      onChange={handleDateChange}
                      shouldDisableDate={shouldDisableDate}
                      minDate={dayjs().tz(`Asia/Kolkata`).add(24, "hours")}
                      maxDate={dayjs()
                        .tz(`Asia/Kolkata`)
                        .add(9, "day")
                        .add(24, "hours")}
                      sx={{
                        height: 310,
                        "& .Mui-selected": {
                          backgroundColor: "var(--brand-color)", // Set selected date background
                          color: "white", // Optionally set the text color for selected date
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "var(--brand-color)", // Maintain the color on hover
                        },
                        "& .Mui-selected:focus": {
                          backgroundColor: "var(--brand-color)", // Maintain the color on focus
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    minWidth: "232px",
                  }}
                >
                  <p className="mt-3 mb-2 w-full flex justify-center">
                    Available Slots
                  </p>
                  <Box
                    sx={{
                      overflowY: "auto",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      maxHeight: "250px",
                    }}
                  >
                    <StyledToggleButtonGroup
                      exclusive
                      aria-label="Period"
                      value={selectedSlot}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: freeSlots?.length === 0 && "center",
                        flexWrap: "wrap",
                      }}
                      onChange={handleSlotClick}
                    >
                      {freeSlots?.length > 0
                        ? freeSlots.map((freeSlot, index) => (
                            <ToggleButton key={index} value={freeSlot.start}>
                              {dayjs(freeSlot.start).format("HH:mm")}
                            </ToggleButton>
                          ))
                        : freeSlots?.length === 0 && (
                            <div>
                              {selectedDate ? (
                                <div>No slots available.</div>
                              ) : (
                                <div>Please select a date</div>
                              )}
                            </div>
                          )}
                    </StyledToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
            )}
            {step === 3 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "20px",
                  }}
                >
                  <img src={greenTick} alt="greenTick" className="mr-2" />
                  Meeting Scheduled
                </Box>
                <div className="mt-4 text-sm">
                  A calendar invitation has been sent to your email address.
                </div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "16px",
                    width: "400px",
                    border: "1px solid #DADADA",
                    borderRadius: "4px",
                  }}
                >
                  <div className="mb-4 font-medium">Meeting Info</div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Person2Outlined
                      sx={{
                        height: "20px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Fundrev, {deal?.companyName}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <CalendarIcon className="mr-1 ml-0.5" width={`20px`} />{" "}
                    {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`HH:mma`)} -{" "}
                    {dayjs(selectedSlot)
                      .tz(`Asia/Kolkata`)
                      .add(30, `minutes`)
                      .format(`HH:mma`)}
                    , {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`dddd`)},
                    {dayjs(selectedSlot)
                      .tz(`Asia/Kolkata`)
                      .format(` MMMM, D, YYYY `)}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <GlobeIcon width={`20px`} className="mr-1 ml-0.5" /> Indian
                    Standard Time
                  </Box>
                </Box>
              </Box>
            )}
            {step === 2 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <StyledFormControl fullWidth>
                  <Typography
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      color: "var(--Primary-Text-Color)",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Meeting Agenda
                  </Typography>
                  <CustomInput
                    placeholder="Please write down your meeting agenda."
                    name="meetingAgenda"
                    value={meetingAgenda}
                    onChange={handleInput}
                    multiline={true} // Enable multiline
                    minRows={5}
                  />
                </StyledFormControl>
              </Box>
            )}
            <div className="flex items-center justify-center">
              {step < 4 && (
                <div
                  className={`w-full flex justify-center ${
                    step >= 2 && `mt-8`
                  }`}
                >
                  {step === 2 && (
                    <div
                      className="flex justify-center cursor-pointer items-center mr-4"
                      onClick={() => setStep(step - 1)}
                    >
                      <ArrowLeftIcon className="mr-1" />
                      Back
                    </div>
                  )}
                  <CustomBlackBtn
                    text={
                      step === 1 ? `Next` : step === 2 ? `Schedule` : `Close`
                    }
                    onClick={handleSchedule}
                  />
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ScheduleMeeting;
