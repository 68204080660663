import { motion } from "framer-motion";
import { cn } from "../../../lib/utils";

export function WordFadeIn({
  words,
  wholeSentence = false,
  delay = 0.15,
  variants = {
    hidden: { opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: { delay: (i + 1) * delay },
    }),
  },
  className,
}) {
  const _words = wholeSentence
    ? ["Capital Stack for Private Markets from", "Idea", "to", "IPO"]
    : words.split(" ");

  return (
    <motion.h1
      variants={variants}
      initial="hidden"
      animate="visible"
      className={cn(
        "font-display tracking-[-0.02em] text-black drop-shadow-sm dark:text-black ",
        className
      )}
    >
      {_words &&
        _words.length > 0 &&
        _words?.map((word, i) => (
          <motion.span key={word} variants={variants} custom={i}>
            {word}{" "}
          </motion.span>
        ))}
    </motion.h1>
  );
}
