import { useRef, useState } from "react";
import Front from "./sections/front";
import { TopInvestors } from "./sections/topInvestors";
import { RoleBasedSection } from "./sections/roleBasedSection";
import { SecureDeals } from "./sections/secureDeals";
import FAQ from "./sections/faqs";
import Footer from "./Footer";
import frontSectionBg from "../../Assets/Images/LandingPage/frontSectionBg.svg";
import ElevateExperience from "./sections/elevateExperience";
import { Testimonials } from "./sections/testimonials";

import "./landingPage.css";
import Header from "./sections/header";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const Navigate = useNavigate();
  const investorsRef = useRef();
  const foundersRef = useRef();
  const faqsRef = useRef();
  const [activeSections, setActiveSections] = useState({
    investors: "Invest", // Default active section for investors
    founders: "Raise", // Default active section for founders
  });
  const handleSectionChange = (role, newSection) => {
    setActiveSections((prevState) => ({
      ...prevState,
      [role]: newSection,
    }));
  };
  // const handleScroll = (role) => {
  //   if (role === "investors") {
  //     investorsRef.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (role === "founders") {
  //     foundersRef.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (role === "faqs") {
  //     faqsRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const handleScroll = (role) => {
    const elements = {
      investors: investorsRef,
      founders: foundersRef,
      faqs: faqsRef,
    };
    const element = elements[role];
    if (element) {
      element.current.scrollIntoView({ behavior: "smooth" });
    } else {
      Navigate(`/`);
      setTimeout(() => {
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${frontSectionBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      className="flex flex-col justify-center overflow-hidden bg-white landing-page max-md:max-w-full"
    >
      <Helmet>
        <title>Fundrev | Home</title>
      </Helmet>
      <div className="w-full 2xl:max-w-[1420px] max-w-[1310px] max-md:max-w-full m-auto">
        <Header
          foundersRef={foundersRef}
          investorsRef={investorsRef}
          handleScroll={handleScroll}
        />
        <Front />
        <TopInvestors />
        <div className="mt-24 lg:mt-40 2xl:mt-60 flex flex-col gap-[100px] lg:gap-32  2xl:gap-40">
          <RoleBasedSection
            foundersRef={foundersRef}
            activeSection={activeSections.founders}
            onSectionChange={(newSection) =>
              handleSectionChange("founders", newSection)
            }
          />
          <RoleBasedSection
            role="investor"
            investorsRef={investorsRef}
            activeSection={activeSections.investors}
            onSectionChange={(newSection) =>
              handleSectionChange("investors", newSection)
            }
          />
        </div>
      </div>
      <SecureDeals />
      <div className="w-full max-w-[1310px] max-md:max-w-full m-auto">
        <Testimonials />
      </div>
      <ElevateExperience />
      <FAQ faqsRef={faqsRef} />
      <Footer
        foundersRef={foundersRef}
        investorsRef={investorsRef}
        // onSectionChange={(role, newSection) => {
        //   handleSectionChange(role, newSection);
        //   setTimeout(() => {
        //     handleScroll(role);
        //   }, [100]);
        // }}
        handleScroll={handleScroll}
      />
    </div>
  );
};

export default LandingPage;
