import React from "react";
import { useFormikContext, Form } from "formik";
import {
  StyledFormControl,
  StyledFormLabel,
} from "../../../../Common/functions";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import CustomInput from "../../../../Common/components/custom/customInput";
import CustomDateInput from "../../../../Common/components/custom/customDateInput";

const DealPurpose = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  const purposeOptions = [
    { value: "Co-Invest", label: "Co-Invest" },
    { value: "Lead Round", label: "Lead Round" },
    { value: "Sole Investor", label: "Sole Investor" },
  ];
  const preferredInvestorsOptions = [
    { value: "Co-Invest", label: "Co-Invest" },
    { value: "Lead Round", label: "Lead Round" },
    { value: "Sole Investor", label: "Sole Investor" },
  ];

  return (
    <div className="DealPurpose fundrev-card onboardingForm-card">
      <p className="heading">How will you utilize the funds?</p>
      <p className="subheading">Tell us about your purpose of fundraising</p>
      <Form>
        <StyledFormControl fullWidth>
          <StyledFormLabel>
            Write down your purpose of fundraising
          </StyledFormLabel>
          <CustomInput
            placeholder="Enter Purpose of Fundraising"
            name="purposeOfFundraising"
            type="text"
            value={values.purposeOfFundraising}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              touched.purposeOfFundraising && errors.purposeOfFundraising
            )}
            helperText={
              touched.purposeOfFundraising && errors.purposeOfFundraising
            }
            multiline
            minRows={10}
          />
        </StyledFormControl>
      </Form>
    </div>
  );
};

export default DealPurpose;
