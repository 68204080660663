import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import { dumpInvestorInteraction } from "../../../../../endpoints/common";

const BusinessAnalytics = ({ data, deal, stage }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "businessAnalytics",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <>
      <Box
        sx={{
          marginBottom: "100px",
        }}
      >
        <SmallCardGrid data={data.fiveCards} title={`Business Analytics`} />
        {data.plots.financialPlot.isVisible && (
          <FinancialPlot data={data?.plots?.financialPlot} />
        )}
        <Grid container spacing={1} marginBottom={3} sx={{ width: "101%" }}>
          <Grid item xs={12} md={6} lg={6}>
            {data.tables.topCustomerTable.isVisible && (
              <Box
                key={0}
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "100%",
                  borderRadius: "4px",
                  padding: "16px",
                  fontFamily: "Karla, sans-serif",
                  height: "336px",
                }}
              >
                <TopCustomerTable data={data?.tables?.topCustomerTable} />
              </Box>
            )}
          </Grid>
          {data?.plots?.customerPlots &&
            data?.plots?.customerPlots.map(
              (plotData, index) =>
                plotData &&
                plotData.isVisible && (
                  <Grid item xs={12} md={6} lg={6}>
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        borderRadius: "4px",
                        padding: "16px",
                        fontFamily: "Karla, sans-serif",
                        height: "336px",
                      }}
                      key={index + 1}
                    >
                      <PlotTemplate data={plotData} />
                    </Box>
                  </Grid>
                )
            )}
        </Grid>
      </Box>
    </>
  );
};

export default BusinessAnalytics;
