import React, { useEffect, useState } from "react";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import {
  createSecondaryDeal,
  getSecondaryDeals,
  toggleSecondaryDealOpenListing,
} from "../../../endpoints/secondary";
import { ToastContainer } from "react-toastify";
import Toast from "../../Common/Toast";
import { useNavigate } from "react-router-dom";

const SecondaryDeals = () => {
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const createDeal = async () => {
    const response = await createSecondaryDeal(token);
    if (response?.dealId) {
      Toast(
        `Secondary Deal created successfully!`,
        `success`,
        `secondaryDeals`
      );
      setDeals((prev) => [...prev, response]);
    } else {
      Toast(`Failed to create Secondary Deal!`, `error`, `secondaryDeals`);
    }
  };
  useEffect(() => {}, [deals]);
  const getDeals = async () => {
    const response = await getSecondaryDeals(token);
    if (response?.length > 0) {
      setDeals(response);
    } else {
      Toast(`No Secondary Deals found!`, `info`, `secondaryDeals`);
    }
  };
  async function toggleStatus(deal) {
    try {
      await toggleSecondaryDealOpenListing(deal?.dealId, token);

      getDeals();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "adminPanel");
    }
  }
  useEffect(() => {
    getDeals();
  }, []);

  return (
    <>
      <ToastContainer containerId={`secondaryDeals`} position="top-center" />
      <div className="w-full flex justify-end">
        <CustomBlackBtn
          text={`+ Create Deal`}
          variant="sm"
          border="sm"
          onClick={createDeal}
        />
      </div>
      <div className="mt-4">
        <table>
          <thead>
            <th>Deal ID</th>
            <th>Company Name</th>
            <th>Deal Details</th>
            <th>Open Listing</th>
          </thead>
          <tbody>
            {deals?.map((deal, index) => (
              <tr>
                <td>{deal?.dealId}</td>
                <td>{deal?.companyName}</td>
                <td>
                  <CustomBlackBtn
                    text={`View`}
                    variant="sm"
                    border="sm"
                    onClick={() =>
                      navigate("/admin/secondary/dealEdit", {
                        state: {
                          dealId: deal?.dealId,
                        },
                      })
                    }
                  />
                </td>
                <td>
                  <button
                    className={`toggleStatusbtn ${
                      deal?.openListing ? "Approved-btn" : "Pending-btn"
                    }`}
                    onClick={() => toggleStatus(deal)}
                  >
                    {deal?.openListing ? "Visible" : "Not Visible"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SecondaryDeals;
