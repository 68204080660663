import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import DashboardGrid3 from "./Components/DashboardGrid3";
import DashboardGrid2 from "./Components/DashboardGrid2";
import { useLoader } from "../../Common/LoaderProvider";
import { getDealSummaryPageData } from "../../../endpoints/deal";
import DashboardGrid1 from "./Components/DashboardGrid1";

const DetailedDashboard = ({ deal, states }) => {
  const loader = useLoader();
  const [data, setData] = useState(null);

  const getData = async () => {
    const response = await getDealSummaryPageData(deal?.dealId);
    loader.stop();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    loader.start();
    if (deal) {
      getData();
    }
  }, [deal]);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: "8px" }}>
        <Grid container spacing={2}>
          <Grid xs={4}>
            <Box
              sx={{
                height: "725px",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                backgroundColor: "#FFFFFF",
                padding: "8px",
              }}
            >
              <DashboardGrid1 data={data} deal={deal} states={states} />
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box
              sx={{
                height: "725px",
              }}
            >
              <DashboardGrid2 data={data} deal={deal} />
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box
              sx={{
                height: "725px",
              }}
            >
              <DashboardGrid3 />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DetailedDashboard;
