import React, { useState, useEffect } from "react";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import NoNamePreview from "../../../Common/components/NoNamePreview";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Input } from "@mui/joy";
import { Helmet } from "react-helmet";
import { getStartupDetails } from "../../../../endpoints/admin";
const DownloadPdf = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [deal, setDeal] = useState();
  const { dealId, startupId } = location.state || {};

  const getDealDetails = async () => {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      setDeal((prev) => {
        return {
          ...prev,
          ...response.data,
        };
      });
    } catch (error) {}
  };
  const getStartupData = async () => {
    try {
      const response = await getStartupDetails(startupId, token);
      const data = response?.data || false;
      if (data) {
        setDeal((prev) => {
          return {
            ...prev,
            ...data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDealDetails();
  }, [dealId, token]);
  useEffect(() => {
    getStartupData();
  }, [startupId, token]);

  useEffect(() => {
    // Select all elements on the page
    const elements = document.querySelectorAll("*");

    // Store the original styles to restore them on cleanup
    const originalStyles = new Map();

    elements.forEach((element) => {
      const computedStyle = window.getComputedStyle(element);
      const boxShadow = computedStyle.boxShadow;

      // If the element has a box-shadow, replace it with a border
      if (boxShadow && boxShadow !== "none") {
        originalStyles.set(element, {
          boxShadow: element.style.boxShadow,
          border: element.style.border,
        });

        element.style.boxShadow = "none";
        element.style.border = "1px solid #ccc"; // Add your desired border style here
      }
    });

    // Cleanup on component unmount
    return () => {
      elements.forEach((element) => {
        if (originalStyles.has(element)) {
          const originalStyle = originalStyles.get(element);
          element.style.boxShadow = originalStyle.boxShadow;
          element.style.border = originalStyle.border;
        }
      });
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Fundrev - {dealId}</title>
      </Helmet>
      <img
        src={Logo}
        alt={"Fundrev"}
        style={{ width: "150px", margin: "10px 0px 20px 20px" }}
      />
      <div className="flex flex-col">
        <NoNamePreview deal={deal} admin={true} />
      </div>
    </div>
  );
};

export default DownloadPdf;
