import React, { useEffect, useState, useRef } from "react";
import { postStageData } from "../../../../endpoints/deal";
import PassDealModal from "../../../Common/modals/passDealModal";
import { useLoader } from "../../../Common/LoaderProvider";

import CustomToolTip from "../.././../Common/components/custom/customToolTip";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import {
  BorderLinearProgress,
  BorderLinearProgressBlue,
} from "../../../Common/functions";
import { Typography } from "@mui/joy";

import qna from "../../../../Assets/Images/icons/qna.svg";
import requestedAccessDataroom from "../../../../Assets/Images/icons/requestAccessDataroom.svg";
import deniedAccessDataroom from "../../../../Assets/Images/icons/deniedAccessDataroom.svg";
import grantedAccessDataroom from "../../../../Assets/Images/icons/grantedAccessDataroom.svg";
import completedMeeting from "../../../../Assets/Images/icons/completedMeeting.svg";
import scheduledMeeting from "../../../../Assets/Images/icons/scheduledMeeting.svg";
import notScheduledMeeting from "../../../../Assets/Images/icons/notScheduledMeeting.svg";
import greenStar from "../../../../Assets/Images/icons/greenStar.svg";
import electricity from "../../../../Assets/Images/icons/electricity.svg";

// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import defaultCompanyLogo from "../../../../Assets/Images/InvestorPage/Dashboard/defaultCompanyLogo.png";
import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

import "../../../../CSS/FounderPage/Dashboard/ongoingInvestor.css";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import moment from "moment";

const OngoingInvestorCard = ({
  investor,
  details,
  fundingRequired,
  passedStage,
  dealId,
  fetchInvestorsByDealId,
  latestActivity,
}) => {
  const calculateBadges = (idealFocusOptions) => {
    try {
      const details = JSON.parse(idealFocusOptions);
      return details;
    } catch (error) {
      console.error("Error parsing ideal focus options", error);
      return [];
    }
  };
  // Calculate values based on the props provided
  const companyName = details.companyName;
  const investorLogo = details.investorLogo;

  const unansweredQuestionsCount = investor.countUnansweredQnAs;
  const meetingStatus = investor.meetingStatus;
  const dataRoomStatus = investor.dataRoomStatus;
  const investorIntentPercentile = investor.investorIntentPercentile;
  const lastActivityTime = moment(investor.lastActivityTime).format(
    "YYYY/MM/DD"
  );
  const investmentType =
    passedStage === "ioiSubmitted" || passedStage === "ioiAccepted"
      ? investor.IOI_Bid_Details.investmentType
      : passedStage === "loiSubmitted" || passedStage === "loiAccepted"
      ? investor.LOI_Bid_Details.investmentType
      : "";
  const bidAmount =
    passedStage === "ioiSubmitted" || passedStage === "ioiAccepted"
      ? investor.IOI_Bid_Details.investmentAmount
      : passedStage === "loiSubmitted" || passedStage === "loiAccepted"
      ? investor.LOI_Bid_Details.investmentAmount
      : "";

  const percentRoundSize =
    passedStage === "ioiSubmitted" || passedStage === "ioiAccepted"
      ? (
          (investor.IOI_Bid_Details.investmentAmount * 100) /
          fundingRequired
        ).toFixed(0)
      : passedStage === "loiSubmitted" || passedStage === "loiAccepted"
      ? (
          (investor.LOI_Bid_Details.investmentAmount * 100) /
          fundingRequired
        ).toFixed(0)
      : "";
  const BidDueDate =
    passedStage === "ioiSubmitted" || passedStage === "ioiAccepted"
      ? moment(investor.IOI_Bid_Details.transactionCompletionDate).format(
          "YYYY-MM-DD"
        )
      : passedStage === "loiSubmitted" || passedStage === "loiAccepted"
      ? moment(investor.LOI_Bid_Details.transactionCompletionDate).format(
          "YYYY-MM-DD"
        )
      : "";
  // Determine the latest activity date based on the stage
  const requestedDate =
    passedStage === "requestSent" ? latestActivity : undefined;
  const acceptedDate = [
    "requestAccepted",
    "ioiAccepted",
    "loiAccepted",
  ].includes(passedStage)
    ? latestActivity
    : undefined;
  const submittedDate = ["ioiSubmitted", "lioSubmitted"].includes(passedStage)
    ? latestActivity
    : undefined;
  const closingDate = passedStage === "dealClosed" ? latestActivity : undefined;

  // Calculate AUM and ticket size
  const ticketSize = details.ticketSizevalue
    ? JSON.parse(details.ticketSizevalue)
    : undefined;
  // Other values
  const badges = details.industryVerticalOptions[0]
    ? calculateBadges(details.industryVerticalOptions[0])
    : undefined;
  const progress =
    details.fundingRequired > 0
      ? (details.fundingRaised / details.fundingRequired) * 100
      : 0;
  const investorType = details.investorType;
  const isInterested = investor.IsInterested;
  const isRejected = investor.IsRejected;
  const token = localStorage.getItem("token");
  const containerRef = useRef(null);
  const Navigate = useNavigate();
  const loader = useLoader();
  const [dealPassed, setDealPassed] = useState(false);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [expand, setExpand] = useState(false);
  const [acceptanceModal, setAcceptanceModal] = useState(false);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [stage, setStage] = useState(passedStage);
  const [action, setAction] = useState("");
  const [formData, setFormData] = useState({
    dealId: dealId,
    investorID: details.investorID,
  });

  function timeAgo(dateString) {
    // Convert input date to a Date object
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = currentDate - inputDate;

    // Calculate differences in seconds, minutes, hours, days, weeks, and months
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    // Use conditions to return appropriate string
    if (diffInDays === 0) {
      return "Today";
    } else if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else if (diffInWeeks === 1) {
      return "1 week ago";
    } else if (diffInWeeks > 1 && diffInWeeks < 4) {
      return `${diffInWeeks} weeks ago`;
    } else if (diffInMonths === 1) {
      return "1 month ago";
    } else {
      return `${diffInMonths} months ago`;
    }
  }
  function daysRemaining(futureDateString) {
    // Convert input date to a Date object
    const futureDate = new Date(futureDateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = futureDate - currentDate;

    // If the date has already passed, return "Date has passed"
    if (diffInMs <= 0) {
      return "Date passed";
    }

    // Calculate differences in days, weeks, and months
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    // Return appropriate string based on the difference
    if (diffInMonths >= 1) {
      if (diffInMonths === 1) {
        return "1 month left";
      } else {
        return `${diffInMonths} months left`;
      }
    } else if (diffInWeeks >= 1) {
      if (diffInWeeks === 1) {
        return "1 week left";
      } else {
        return `${diffInWeeks} weeks left`;
      }
    } else {
      if (diffInDays === 1) {
        return "1 day left";
      } else {
        return `${diffInDays} days left`;
      }
    }
  }

  const getIntent = (intent) => {
    switch (intent) {
      case 100:
        return (
          <p className="investor-card-tag text-[var(--dark-green-color)] bg-[#DCEDEA]">
            Highest <img src={electricity} alt="high" className="w-[6px]" />
          </p>
        );
      case intent > 75:
        return (
          <p className="investor-card-tag text-[var(--dark-green-color)] bg-[#DCEDEA]">
            High
          </p>
        );
      case intent > 50:
        return (
          <p className="investor-card-tag text-[var(--blue-color)] bg-[var(--light-blue-color)]">
            Medium
          </p>
        );
      default:
        return <p className="investor-card-tag bg-[var(--pink-color)]">Low</p>;
    }
    //
  };
  const handleAccept = async () => {
    let stageAccept;
    if (stage === "requestSent") {
      stageAccept = "requestAccepted";
    } else if (stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
    } else if (stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
    }
    const updatedFormData = {
      ...formData,
      IsInterested: true,
      Stage: stageAccept,
    };
    loader.start("Accepting...");
    const response = await postStageData(updatedFormData, token);
    loader.stop();
    if (response.data !== null) {
      setStage(stageAccept);
      fetchInvestorsByDealId();
    }
  };

  // const handleDecline = async () => {
  //   const updatedFormData = {
  //     dealId: dealId,
  //     investorID: details.investorID,
  //     IsRejected: true,
  //     Stage: stage,
  //   };
  //   loader.start("Declining...");
  //   const response = await postStageData(updatedFormData, token);
  //   loader.stop();
  //   fetchInvestorsByDealId();
  //   // Navigate("/company/ongoingDeals");
  // };
  // useEffect(() => {}, [acceptanceModal]);
  // useEffect(() => {
  //   fetchInvestorsByDealId();
  // }, [openReasonModal]);
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      badges.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = badges.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [badges]);
  useEffect(() => {
    if (dealPassed) {
      fetchInvestorsByDealId();
    }
  }, [dealPassed]);
  useEffect(() => {
    if (!isInterested || isRejected) {
      setAction("View details");
    } else if (stage === "requestSent") {
      setAction("Accept Request");
    } else if (stage === "requestAccepted") {
      setAction("View details");
    } else if (stage === "ioiSubmitted") {
      setAction("Accept Term Sheet");
    } else if (stage === "ioiAccepted") {
      setAction("View Term Sheet");
    } else if (stage === "loiSubmitted") {
      setAction("Accept Final Bid");
    } else if (stage === "loiAccepted") {
      setAction("View Final Bid");
    } else if (stage === "spaNegotiation") {
      setAction("Add SPA");
    } else if (stage === "dealClosed") {
      setAction("Deal Closed");
    } else {
      setAction("View details");
    }
  }, [stage]);
  const handleInvestorCardClick = () => {
    Navigate("/company/investorDetails", {
      state: {
        //Here investor require details of investor hence sending details
        investor: details,
        //Here passed object investor is actually dealInterest which is sending as dealInterest.
        isDealLive: !investor.IsRejected && investor.IsInterested,
        watchlistAccepted: investor.watchlistAccepted || false,
        latestStage: investor.latestStage, // Stage before watchlist in case passedStage in watchlist
        dealId: dealId,
        passedStage: stage,
        investorID: details.investorID,
        // dealInterest: dealInterest,
      },
    });
  };
  return (
    <>
      <div className="onGoingDealCard">
        <Modal
          open={acceptanceModal}
          onClose={() => setAcceptanceModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="modal-box reason-box"
            style={{ width: "600px", height: "fit-content" }}
          >
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{
                cursor: "pointer",
                position: "fixed",
                right: "20px",
                top: "20px",
              }}
              onClick={() => setAcceptanceModal(false)}
              alt="close"
            ></img>
            <div className="text-box">
              <div className="heading">
                <p className="heading">Are you sure?</p>
              </div>
              <div className="subheading">
                <p>
                  You want to proceed with accepting the{" "}
                  {stage === "requestSent" && `request`}
                  {stage === "ioiSubmitted" && `Term Sheet`}{" "}
                  {stage === "loiSubmitted" && `Due Diligence`} ?
                </p>
              </div>
            </div>

            <div className="btn-box">
              <button
                className="reason-submit cancel"
                style={{ height: "40px" }}
                onClick={() => setAcceptanceModal(false)}
              >
                Cancel
              </button>
              <button className="reason-submit" onClick={handleAccept}>
                Confirm
              </button>
            </div>
          </Box>
        </Modal>
        <PassDealModal
          openReasonModal={openReasonModal}
          setOpenReasonModal={setOpenReasonModal}
          stage={stage}
          isCompany={true}
          dealId={dealId}
          investorID={details.investorID}
          setDealPassed={setDealPassed}
        />
        <div className="bg-[#fff] w-[94%] p-[8px] rounded-[8px] flex flex-col gap-[8px] card-content">
          <div className="card-section-1 rounded-[8px]">
            <div className="Card-div-3">
              <div className="flex gap-[12px]">
                <img
                  src={investorLogo ? investorLogo : defaultCompanyLogo}
                  className="w-[40px] h-[40px] rounded-[10px]"
                  alt="company Logo"
                />
                <div className="flex flex-col max-w-[80%]">
                  <p>{companyName}</p>
                  <div className="flex justify-between">
                    <p className="text-[12px] text-[var(--Secondary-Text-Color)]">
                      {investorType}
                    </p>
                  </div>
                </div>
              </div>
              {stage === "requestSent" ||
                (stage === "requestAccepted" && (
                  <p className="text-[12px]">
                    {getIntent(investorIntentPercentile)}
                  </p>
                ))}
              {!["requestSent", "requestAccepted", "dealClosed"]?.includes(
                stage
              ) &&
                (investmentType === "Lead Round" ? (
                  <p className="investor-card-tag text-[var(--dark-green-color)] bg-[var(--light-green-color)]">
                    {`Lead`}
                  </p>
                ) : (
                  <p className="investor-card-tag text-[var(--blue-color)] bg-[var(--light-blue-color)]">
                    {investmentType}
                  </p>
                ))}
              {/* {stage === "requestAccepted" ||
              stage === "ioiAccepted" ||
              stage === "loiAccepted" ||
              stage === "dealClosed" ? (
                <img src={Accepted} alt="accepted" />
              ) : (
                <img src={Pending} alt="pending"></img>
              )} */}
            </div>
          </div>
          <div className="investor-card-section-2">
            {stage === "requestSent" && (
              <>
                <p className="text-[14px] ">
                  <span className="text-[var(--Primary-Text-Color)] font-[500]">
                    Avg. Ticket Size :
                  </span>{" "}
                  <span className="text-[var(--Secondary-Text-Color)]">
                    {ticketSize[0]}Cr - {ticketSize[1]}Cr
                  </span>
                </p>
                <p className="text-[14px] justify-between">
                  <span className="text-[var(--Primary-Text-Color)] font-[500]">
                    Request Sent:
                  </span>{" "}
                  <span className="text-[var(--Secondary-Text-Color)]">
                    {requestedDate}&nbsp;&nbsp;
                    <span className="text-[12px] px-[8px] py-[1px] rounded-[24px] text-[#000] bg-[#FFE7E7]">
                      {timeAgo(requestedDate)}
                    </span>
                  </span>
                </p>
              </>
            )}
            {stage === "requestAccepted" && (
              <>
                <p className="text-[14px] ">
                  <span className="text-[var(--Primary-Text-Color)] font-[500]">
                    Avg. Ticket Size :
                  </span>{" "}
                  <span className="text-[var(--Secondary-Text-Color)]">
                    {ticketSize[0]}Cr - {ticketSize[1]}Cr
                  </span>
                </p>
                <p className="text-[14px] flex justify-between">
                  <div>
                    <span className="text-[var(--Primary-Text-Color)] font-[500]">
                      Last Interaction:
                    </span>{" "}
                    <span className="text-[var(--Secondary-Text-Color)]">
                      {lastActivityTime}&nbsp;&nbsp;
                    </span>
                  </div>
                  <span className="investor-card-date-tag bg-[var(--pink-color)]">
                    {timeAgo(investor.lastActivityTime)}
                  </span>
                </p>
              </>
            )}
            {(stage === "ioiSubmitted" ||
              stage === "ioiAccepted" ||
              stage === "loiSubmitted" ||
              stage === "loiAccepted") && (
              <>
                <p className="text-[14px] flex flex-row justify-between items-center">
                  <div>
                    <span className="text-[var(--Secondary-Text-Color)] font-[500]">
                      Bid Amount :
                    </span>{" "}
                    <span className="text-[var(--Secondary-Text-Color)] font-[500]">
                      {bidAmount}Cr
                    </span>
                  </div>

                  <div className="bid-progress-bar w-[40%] flex justify-center">
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <BorderLinearProgressBlue
                        variant="determinate"
                        value={percentRoundSize}
                        sx={{
                          height: 15,
                          width: "100%",
                          borderRadius: "10px",
                          overflow: "hidden",
                          "& .MuiLinearProgress-bar": {
                            borderRadius: "0px",
                            backgroundColor: "#00A6FB",
                          },
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left:
                            Math.round(percentRoundSize) >= 40
                              ? "50%"
                              : `${Math.round(percentRoundSize) + 25}%`, // Center if progress > 60, else at the end
                          transform: "translateX(-50%)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="card-raised-text"
                          sx={{ whiteSpace: "nowrap" }}
                          style={{
                            color: "#1a1a1a",
                          }}
                        >
                          {`${Math.round(percentRoundSize)}% raised`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </p>
                <p className="text-[14px] flex justify-between">
                  <div>
                    <span className="text-[var(--Secondary-Text-Color)] font-[500]">
                      Bid due date:
                    </span>{" "}
                    <span className="text-[var(--Secondary-Text-Color)] font-[500]">
                      {BidDueDate}
                      &nbsp;&nbsp;
                    </span>
                  </div>

                  <span className="text-[12px] px-[8px] py-[1px] rounded-[24px] text-[#000] bg-[#FFE7E7]">
                    {stage === "ioiSubmitted" || stage === "ioiAccepted"
                      ? daysRemaining(
                          investor.IOI_Bid_Details?.transactionCompletionDate
                        )
                      : daysRemaining(
                          investor.LOI_Bid_Details?.transactionCompletionDate
                        )}
                    {}
                  </span>
                </p>
              </>
            )}
          </div>
          <div className="flex justify-between items-center px-[4px]">
            {
              <CustomBlackBtn
                filled={false}
                text={action}
                border="sm"
                variant="sm"
                textWeight="lg"
                onClick={handleInvestorCardClick}
              />
            }
            {!investor.IsRejected && investor.IsInterested && (
              <p className="text-[14px] flex flex-row gap-[8px]">
                {unansweredQuestionsCount > 0 && (
                  <CustomToolTip
                    title={`${unansweredQuestionsCount} Questions Pending`}
                    position="bottom"
                    theme="black"
                  >
                    <div className="relative">
                      <img src={qna} alt="qna" className="w-[24px] mt-[3px]" />
                      <p className="absolute top-[-5px] right-[-4px] bg-red-500 text-[#fff] w-[14px] h-[14px] text-[12px] rounded-[50%] flex justify-center items-center">
                        {unansweredQuestionsCount}
                      </p>
                    </div>
                  </CustomToolTip>
                )}
                {unansweredQuestionsCount === 0 && (
                  <CustomToolTip
                    title={`No questions pending`}
                    position="bottom"
                    theme="black"
                  >
                    <div className="relative">
                      <img src={qna} alt="qna" className="w-[24px] mt-[3px]" />
                    </div>
                  </CustomToolTip>
                )}
                {meetingStatus === "not scheduled" && (
                  <CustomToolTip
                    title="No Meeting Scheduled"
                    position="bottom"
                    theme="black"
                  >
                    <div>
                      <img
                        src={notScheduledMeeting}
                        alt="notScheduledMeeting"
                        className="w-[24px]"
                      />
                    </div>
                  </CustomToolTip>
                )}
                {meetingStatus === "scheduled" && (
                  <CustomToolTip
                    title="Meeting Scheduled"
                    position="bottom"
                    theme="black"
                  >
                    <div>
                      <img
                        src={scheduledMeeting}
                        alt="notScheduledMeeting"
                        className="w-[24px]"
                      />
                    </div>
                  </CustomToolTip>
                )}
                {meetingStatus === "done" && (
                  <CustomToolTip
                    title="Meeting Completed"
                    position="bottom"
                    theme="black"
                  >
                    <div>
                      <img
                        src={completedMeeting}
                        alt="completedMeeting"
                        className="w-[24px]"
                      />
                    </div>
                  </CustomToolTip>
                )}
                {/* {dataRoomStatus === "pending" && (
                <CustomToolTip
                  title="Requested Dataroom Access"
                  position="bottom"
                  theme="black"
                >
                  <div>
                    <img
                      src={requestedAccessDataroom}
                      alt="dataroom access requested"
                      className="w-[24px]"
                    />
                  </div>
                </CustomToolTip>
              )}
              {dataRoomStatus === "denied" && (
                <CustomToolTip
                  title="Dataroom Access Denied"
                  position="bottom"
                  theme="black"
                >
                  <div>
                    <img
                      src={deniedAccessDataroom}
                      alt="dataroom access denied"
                      className="w-[24px]"
                    />
                  </div>
                </CustomToolTip>
              )}
              {dataRoomStatus === "granted" && (
                <CustomToolTip
                  title="Dataroom Access Granted"
                  position="bottom"
                  theme="black"
                >
                  <div>
                    <img
                      src={grantedAccessDataroom}
                      alt="dataroom access denied"
                      className="w-[24px]"
                    />
                  </div>
                </CustomToolTip>
              )} */}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingInvestorCard;
