import React, { useEffect, useState, useMemo, useRef } from "react";
import { cols, parseHTMLString } from "../functions";
import ProfileCard from "./profileCard";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import CompanyOverviewLeftCard from "../../Investor/Dashboard/Components/CompanyOverviewLeftCard";

import { companyState, saveState } from "../../Founders/Dashboard/companyState";
import { useRecoilState, useRecoilValue } from "recoil";
import CommentBox from "../../Founders/Dashboard/MyProfile/commentBox";
import { getDealTeamDetails } from "../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { fetchMembers } from "../../../endpoints/startup";
import FundrevAnalystDetails from "../../Common/components/fundrevAnalystDetails";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { useLoader } from "../LoaderProvider";
import { Box, styled, Typography } from "@mui/material";
import { dumpInvestorInteraction } from "../../../endpoints/common";
import dayjs from "dayjs";

const CompanyOverview = ({ startup, deal, reqToEdit, stage }) => {
  const [activeSection, setActiveSection] = useState(1);
  const [tableData, setTableData] = useState([]);
  const columns = useMemo(() => cols, []);
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [analyst, setAnalyst] = useState({});
  const { numMessages, startupMemberId } = useRecoilValue(companyState);
  const [dealId, setDealId] = useState(null);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const overviewRef = useRef(null);
  const keyhighlightsRef = useRef(null);
  const purposeRef = useRef(null);
  const financialsRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);
  const roundsRef = useRef(null);
  const elevatorPitchRef = useRef(null);
  const analystID = "FN123456";
  const loader = useLoader();
  const maxLength = 135;

  const [hasPlayed, setHasPlayed] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [videoError, setVideoError] = useState(false);

  // const handleRetry = () => {
  //   setVideoError(false);
  //   if (videoRef.current) {
  //     videoRef.current.load();
  //     videoRef.current.play();
  //   }
  // };

  const handleVideoPlay = () => {
    if (!hasPlayed) {
      dumpInteraction("click", "video");
      setHasPlayed(true);
    }
  };

  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState);
  };
  const dumpInteraction = async (type, element) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.dealId,
          type: type || "view",
          page: "companyOverview",
          stageOfDeal: stage,
          element: element || "page",
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const handleActiveSection = (num) => {
    setActiveSection(num);

    const sectionRefs = {
      1: overviewRef,
      2: keyhighlightsRef,
      3: purposeRef,
      4: teamRef,
      5: financialsRef,
      6: roundsRef,
      7: contactRef,
      8: elevatorPitchRef,
    };

    const container = document.getElementById("scrollContainer");

    if (sectionRefs[num]?.current) {
      const sectionTop =
        sectionRefs[num].current.offsetTop - container.offsetTop;
      container.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  const StyledHeading = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "21px",
    textAlign: "justified",
  }));
  const StyledContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "justified",
    maxWidth: "259px",
  }));
  const StyledTableHeading = styled(Typography)(({ theme }) => ({
    color: "#696F79",
    fontSize: "14px",
  }));
  const tableCellStyle = {
    padding: "0px 15px",
    lineHeight: "48px",
    fontWeight: 500,
    color: "#1A1A1A",
  };
  const parseArray = (arr) => {
    try {
      return JSON.parse(arr);
    } catch (error) {
      return [];
    }
  };
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    return () => {
      if (video) {
        // Pause the video
        video.pause();

        // Reset playback to the beginning
        video.currentTime = 0;

        // Exit Picture-in-Picture if the video is in PiP mode
        if (document.pictureInPictureElement === video) {
          document.exitPictureInPicture().catch((error) => {
            console.error("Error exiting Picture-in-Picture:", error);
          });
        }
      }
    };
  }, []);
  useEffect(() => {
    setTableData(deal?.previousRounds);
  }, [deal]);
  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: analystID,
      });
      setAnalyst(response.data.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);
  const getTeamDetails = async () => {
    try {
      if (!startup?.startupId) return;
      const response = await getDealTeamDetails(startup.startupId, token);
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(deal.organizationId, token);
      loader.stop();
      if (response && response.data) {
        const membersData = response.data.data;
        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to fetch members:", error);
    }
  };
  useEffect(() => {
    fetchMembersData();
  }, [deal]);
  useEffect(() => {
    getTeamDetails();
  }, [startup]);
  return (
    <>
      <CommentBox
        dealId={deal?.dealId}
        token={token}
        memberId={startupMemberId}
      />
      <section className="NoNamePreview">
        <div className="NoNamePreview-left">
          <CompanyOverviewLeftCard deal={deal} />
          <div className="noName-navigation">
            <div
              className={`noName-nav-btn ${
                activeSection === 1 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(1)}
            >
              <p>Company Overview</p>
            </div>
            {deal?.videoPitch && (
              <div
                className={`noName-nav-btn ${
                  activeSection === 8 && "selected-nav-btn"
                }`}
                onClick={() => handleActiveSection(8)}
              >
                <p>Elevator Pitch</p>
              </div>
            )}
            <div
              className={`noName-nav-btn ${
                activeSection === 2 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(2)}
            >
              <p>Key Highlights</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 3 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(3)}
            >
              <p>Purpose of Fundraising</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 4 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(4)}
            >
              <p>Founding team</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 5 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(5)}
            >
              <p>Financials, Key Metrics & Other Information</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 6 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(6)}
            >
              <p>Previous Rounds</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 7 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(7)}
            >
              <p>Contact Details</p>
            </div>
          </div>
        </div>
        <div
          className="NoNamePreview-right"
          id="scrollContainer"
          style={{ paddingBottom: "120px" }}
        >
          <div className="noNameDetails-block fundrev-card" ref={overviewRef}>
            <p className="fundrev-card-heading">Company Overview</p>
            <p className="setInnerHTML details">
              {parseHTMLString(deal.description || "")}
            </p>
            <Box
              sx={{
                padding: "12px 24px",
                backgroundColor: "#FAFAFA",
                borderRadius: "16px",
                minHeight: "93px",
                alignContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <Box>
                  <StyledHeading>Legal Entity Name</StyledHeading>
                  <StyledContent>
                    {deal ? deal.legalEntityName : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Headquarters</StyledHeading>
                  <StyledContent>
                    {deal ? deal.headquarters : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Founded Year</StyledHeading>
                  <StyledContent>{deal ? deal.foundedYear : "-"}</StyledContent>
                </Box>
                <Box>
                  <StyledHeading>No. of Employees</StyledHeading>
                  <StyledContent>
                    {deal ? deal.numEmployees : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Website</StyledHeading>
                  <StyledContent>{deal ? deal.website : "-"}</StyledContent>
                </Box>
              </Box>
            </Box>
          </div>
          {deal?.videoPitch && deal?.videoPitch !== "" && (
            <div
              className="noNameDetails-block fundrev-card"
              ref={elevatorPitchRef}
            >
              <p className="fundrev-card-heading">Elevator Pitch</p>
              <Box
                sx={{
                  height: "400px",
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  justifyContent: "flex-start",
                  borderRadius: "4px",
                  paddingTop: 0,
                  position: "relative",
                }}
              >
                {/* {isLoading && (
                  <div className="absolute w-full h-[350px] mx-2 flex justify-center items-center bg-transparent top-0 left-0 rounded">
                    <CircularProgress
                      size={`70px`}
                      thickness={2}
                      sx={{
                        color: "#1A1A1A",
                        zIndex: 2,
                      }}
                    />
                  </div>
                )} */}

                <video
                  ref={videoRef}
                  controls
                  onPlay={handleVideoPlay}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  controlsList="nodownload"
                  id="video-pitch-preview"
                  preload="auto"
                  playsinline
                >
                  <source
                    src={`
      ${
        process.env.REACT_APP_BACKEND_URL
      }/common/stream-video?fileURL=${encodeURIComponent(deal?.videoPitch)}`}
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </div>
          )}
          <div
            className="noNameDetails-block fundrev-card key-highlights-section"
            ref={keyhighlightsRef}
          >
            <p className="fundrev-card-heading">Key Highlights</p>
            <p className="setInnerHTML details">
              {parseHTMLString(deal.keyHighlights || "")}
            </p>
          </div>
          <div className="noNameDetails-block fundrev-card" ref={purposeRef}>
            <p className="fundrev-card-heading">Purpose of Fundraising</p>
            <p className="setInnerHTML details">
              {parseHTMLString(deal.purposeOfFundraising || "")}
            </p>
          </div>
          <Box
            ref={teamRef}
            className="FoundingTeam-box"
            sx={{
              backgroundColor: "white",
              padding: "16px 24px",
              borderRadius: "8px",
              boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
              scrollMarginTop: "100px",
            }}
          >
            <div
              className="FoundingTeam"
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <p
                className="investorDetails-subheading"
                style={{
                  color: "#696F79",
                  fontSize: "20px",
                  fontWeight: "300",
                  marginTop: "0px",
                }}
              >
                Founding Team
              </p>
              <div className="profile-boxes">
                {deal && deal.foundingMember
                  ? (() => {
                      try {
                        const teamDetails = deal?.foundingMember;
                        return Array.isArray(teamDetails)
                          ? teamDetails.map((member, index) => (
                              <ProfileCard
                                member={member}
                                type="company"
                                key={member?.name || index}
                              />
                            ))
                          : "";
                      } catch (error) {
                        console.error(
                          "Error parsing FoundingTeamDetails:",
                          error
                        );
                        return "";
                      }
                    })()
                  : ""}
              </div>
            </div>
          </Box>
          <div ref={financialsRef}>
            <Finanicals deal={deal} page="noNamePreview" />
          </div>
          <Box
            ref={roundsRef}
            className="CompanyReview-FundingDetails"
            sx={{
              backgroundColor: "white",
              marginTop: "10px",
              padding: "16px 24px",
              borderRadius: "8px",
              boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
              border: "none !important",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Previous Rounds
            </p>
            <Box>
              <p
                style={{
                  color: "#1A1A1A",
                  fontWeight: "16px",
                  fontWeight: "600",
                }}
              >
                Key Investors
              </p>
              <p
                style={{
                  color: "#696F79",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {deal ? deal?.keyInvestors : ""}
              </p>
            </Box>
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "0px", boxShadow: "none" }}
            >
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow sx={{ height: "48px" }}>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Date</StyledTableHeading>
                    </TableCell>{" "}
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Round</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Raised(₹Cr)</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>% Stake</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Valuation(₹Cr)</StyledTableHeading>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.length > 0 ? (
                    tableData?.map((row, index) => (
                      <TableRow key={index} border>
                        <TableCell sx={tableCellStyle}>
                          {dayjs(row.date).format(`MMM DD, YYYY`)}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>{row?.round}</TableCell>
                        <TableCell sx={tableCellStyle}>{row?.raised}</TableCell>
                        <TableCell sx={tableCellStyle}>{row?.stake}</TableCell>
                        <TableCell sx={tableCellStyle}>
                          {row?.valuation}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell sx={tableCellStyle}>-</TableCell>
                      <TableCell sx={tableCellStyle}>-</TableCell>
                      <TableCell sx={tableCellStyle}>-</TableCell>
                      <TableCell sx={tableCellStyle}>-</TableCell>
                      <TableCell sx={tableCellStyle}>-</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <div className="noNameDetails-block fundrev-card" ref={contactRef}>
            <p className="fundrev-card-heading">Contact Details</p>
            <FundrevAnalystDetails analystID={"FN123456"} />
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyOverview;
