import React, { useState, useRef, useEffect } from "react";
import CardTags from "./molecules/cardTags";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import {
  addDealToWishlist,
  parseHTMLString,
  removeDealFromWishlist,
} from "../functions";
import FundrevAnalystDetails from "../../Common/components/fundrevAnalystDetails";
import CustomBlackBtn from "./custom/customBlackBtn";

import { investorState } from "../../Investor/Dashboard/investorState";

import { useLocation, useNavigate, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import "../../../CSS/FounderPage/Dashboard/noNamePreview.css";
import ProgressBar from "./molecules/progressBar";
import { dumpInvestorInteraction } from "../../../endpoints/common";
import { Helmet } from "react-helmet";
const NoNamePreview = ({
  deal,
  stage,
  isStar,
  setisStar,
  dealId,
  cardTags,
  admin,
  userInput,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 135;
  const overviewRef = useRef(null);
  const keyhighlightsRef = useRef(null);
  const purposeRef = useRef(null);
  const financialsRef = useRef(null);
  const contactRef = useRef(null);
  const location = useLocation();
  const Navigate = useNavigate();
  const { passedStage, isPassed } = location.state || {};
  const { investorId, name } = useRecoilValue(investorState);
  const [activeSection, setActiveSection] = useState(1);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "noNameDetails",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);

  const handleAddToWishlist = async (startupId, investorId) => {
    setisStar(true);
    await addDealToWishlist(startupId, investorId);
  };

  const handleRemoveFromWishlist = async (startupId, investorId) => {
    setisStar(false);
    await removeDealFromWishlist(startupId, investorId);
  };
  const handleActiveSection = (num) => {
    setActiveSection(num);

    const sectionRefs = {
      1: overviewRef,
      2: keyhighlightsRef,
      3: purposeRef,
      4: financialsRef,
      5: contactRef,
    };

    const container = document.getElementById("scrollContainer");

    if (sectionRefs[num]?.current) {
      const sectionTop =
        sectionRefs[num].current.offsetTop - container.offsetTop;
      container.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="NoNamePreview">
      <Helmet>
        <title>Company | Profile Preview</title>
      </Helmet>
      <div className="NoNamePreview-left">
        <div className="NoName-heading-block">
          <p className="noName-heading">{deal ? deal.noNameTitle : ""}</p>
          <p className="noName-subheading">
            {" "}
            <span>
              {deal &&
                deal?.noNameSubHeading &&
                deal.noNameSubHeading.length > maxLength && (
                  <>
                    {isExpanded
                      ? deal.noNameSubHeading
                      : deal.noNameSubHeading.substr(
                          0,
                          deal.noNameSubHeading.lastIndexOf(" ", maxLength)
                        )}
                    {isExpanded ? (
                      <span
                        onClick={toggleExpand}
                        style={{ color: "#00A6FB", cursor: "pointer" }}
                      >
                        {" show less"}
                      </span>
                    ) : (
                      <span
                        onClick={toggleExpand}
                        style={{ color: "#00A6FB", cursor: "pointer" }}
                      >
                        {"... more"}
                      </span>
                    )}
                  </>
                )}
              {deal &&
                deal?.noNameSubHeading &&
                deal.noNameSubHeading.length <= maxLength && (
                  <span>{deal.noNameSubHeading}</span>
                )}
            </span>
          </p>
          <CardTags deal={deal} page={"noNameDetailsPage"} />
          <div style={{ width: "100%" }}>
            <ProgressBar
              totalFundingRaised={deal?.fundingRaised}
              fundingRequired={deal?.fundingRequired}
              roundType={deal?.roundType}
            />
          </div>
        </div>
        <div className="noName-navigation">
          <div
            className={`noName-nav-btn ${
              activeSection === 1 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(1)}
          >
            <p>Company Overview</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 2 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(2)}
          >
            <p>Key Highlights</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 3 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(3)}
          >
            <p>Purpose of Fundraising</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 4 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(4)}
          >
            <p>Financials, Key Metrics & Other Information</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 5 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(5)}
          >
            <p>Contact Details</p>
          </div>
        </div>
      </div>
      <div className="NoNamePreview-right" id="scrollContainer">
        <div className="noNameDetails-block fundrev-card" ref={overviewRef}>
          <p className="fundrev-card-heading">Company Overview</p>
          <p className="setInnerHTML details">
            {parseHTMLString(deal?.noNameDescription || "")}
          </p>
        </div>
        <div
          className="noNameDetails-block key-highlights-section fundrev-card"
          ref={keyhighlightsRef}
        >
          <p className="fundrev-card-heading">Key Highlights</p>
          <p className="setInnerHTML details">
            {parseHTMLString(deal?.keyHighlights || "")}
          </p>
        </div>
        <div className="noNameDetails-block fundrev-card" ref={purposeRef}>
          <p className="fundrev-card-heading">Purpose of Fundraising</p>
          <p className="setInnerHTML details">
            {parseHTMLString(deal?.noNamePurposeOfFundraising || "")}
          </p>
        </div>
        <div ref={financialsRef}>
          <Finanicals deal={deal} page="noNamePreview" />
        </div>
        <div className="fundrev-card noNameDetails-block" ref={contactRef}>
          <p className="fundrev-card-heading">Contact Details</p>
          <FundrevAnalystDetails analystID={"FN123456"} />
        </div>
        {admin && (
          <div className="w-full flex justify-center bg-[#fff] p-4">
            <Link
              to={`https://fundrev.ai/investor/deals?dealId=${deal?.dealId}`}
            >
              <CustomBlackBtn text="Join Fundrev To Explore" />
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default NoNamePreview;
