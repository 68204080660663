import React from "react";

import { Link } from "react-router-dom";
import logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import instagram from "../../Assets/Images/LandingPage/instagram.svg";
import twitter from "../../Assets/Images/LandingPage/twitter.svg";
import linkedin from "../../Assets/Images/LandingPage/linkedin.svg";
import { ImLinkedin2 } from "react-icons/im";
import { FaInstagram } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import frontSectionBg from "../../Assets/Images/LandingPage/frontSectionBg.svg";

const Footer = ({ handleScroll }) => {
  const Navigate = useNavigate();
  const RedirectLink = (phase, page) => {
    const element = document.getElementById(phase);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      Navigate(`/${page}`);
      setTimeout(() => {
        const updatedElement = document.getElementById(phase);
        if (updatedElement) {
          updatedElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  };
  return (
    <section
      className="Footer mt-10"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundImage: "linear-gradient(rgba(17, 79, 238, 0.1), white)",
      }}
    >
      <div className="frame-92">
        <div className="frame-93">
          <div className="frame-94 social-links-div">
            <div className="logo-social-links">
              <img
                className="element-FUNDREV-LOGO-3"
                alt="Element FUNDREV LOGO"
                src={logo}
              />
              <div className="social-links mobile-social-links">
                <a href="https://www.instagram.com/fundrev.ai/" target="_blank">
                  <img className="img-6" alt="Vector" src={instagram} />
                </a>
                <a
                  href="https://in.linkedin.com/company/fundrev-ai"
                  target="_blank"
                >
                  <img className="img-6" alt="Vector" src={linkedin} />
                </a>
              </div>
            </div>
            <img
              className="landing-page-footer-fundrev-logo laptop-stats"
              alt="Element FUNDREV LOGO"
              src={logo}
            />
            <p className="text-wrapper-14">
              Fueling the synergy between visionary founders and institutional
              investors, our platform is the nexus of innovation and capital.
            </p>
            <div className="flex flex-col gap-5 mt-10">
              <p className="text-[var(--Primary-Text-Color)] text-lg 2xl:text-res-lg font-medium">
                Backed by
              </p>
              <div className="social-links  gap-10">
                <img
                  src={
                    "https://fundrevstorage2.blob.core.windows.net/website-images/microsoft.png"
                  }
                  alt="microsoft"
                  className="grayscale brightness-50 contrast-200 object-contain w-[120px] 2xl:[w-150px]"
                />
                <img
                  src={
                    "https://fundrevstorage2.blob.core.windows.net/website-images/zoho.png"
                  }
                  alt="microsoft"
                  className="grayscale brightness-50 contrast-200 object-contain w-[120px] 2xl:[w-150px]"
                />
                <img
                  src={
                    "https://fundrevstorage2.blob.core.windows.net/website-images/razorpay.png"
                  }
                  alt="microsoft"
                  className="grayscale brightness-50 contrast-200 object-contain w-[120px] 2xl:[w-150px]"
                />
              </div>
            </div>
          </div>
          <div className="frame-95">
            <div className="frame-96">
              <div className="text-wrapper-15">Our Solutions</div>
              <div className="frame-97">
                <div
                  className="text-wrapper-16"
                  onClick={() => handleScroll("founders")}
                >
                  For Companies
                </div>
                <div
                  className="text-wrapper-16"
                  onClick={() => handleScroll("investors")}
                >
                  For Investors
                </div>
                <div
                  className="text-wrapper-16"
                  onClick={() => handleScroll("faqs")}
                >
                  FAQ
                </div>
              </div>
            </div>

            <div className="frame-96">
              <div className="text-wrapper-15">Contact</div>
              <div className="frame-97">
                <div className="flex items-center gap-3">
                  <PhoneIcon fontSize="10px" />
                  <a
                    href="tel:+918100199003"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="text-wrapper-16">+91 81001 99003</div>
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <MailIcon fontSize="10px" />
                  <a
                    href="mailto:hello@fundrev.ai"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="text-wrapper-16">hello@fundrev.ai</div>
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <LocationOnIcon fontSize="10px" />
                  <p className="text-wrapper-16">Bangalore, India</p>
                </div>
              </div>
              <div className="flex flex-wrap gap-[10px] laptop-design">
                <a
                  href="https://in.linkedin.com/company/fundrev-ai"
                  target="_blank"
                >
                  <div className="bg-white hover:bg-[#f0f0f0] rounded-[5px] p-[5px] 2xl:p-[7px] text-xs 2xl:text-res-xs flex gap-[10px] items-center">
                    <ImLinkedin2 /> Linkedin
                  </div>
                </a>
                <a href="https://www.instagram.com/fundrev.ai/" target="_blank">
                  <div className="bg-white hover:bg-[#f0f0f0] rounded-[5px] p-[5px] 2xl:p-[7px] text-xs 2xl:text-res-xs flex gap-[10px] items-center">
                    <FaInstagram /> Instagram
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-disclosure">
          <p>
            We strive to comply with all applicable laws in connection with the
            services offered through this platform. Please note that Fundrev is
            not a stock exchange recognized by the Securities and Exchange Board
            of India (SEBI) under the Securities Contract (Regulation) Act,
            1956, and does not serve as a fundraising platform. Fundrev, through
            its platform, only facilitates interactions between investors and
            target companies in a private and restricted setting. No securities
            are offered by any company on this platform.
          </p>
          <br />
          <p>
            We encourage you to read the terms of use carefully before utilizing
            this platform or any of the services provided.
          </p>
        </div>
        <div className="terms-conditions">
          <div className="frame-98">
            <div>
              <p className="text-wrapper-19 copyright">
                © 2024 Fundrev Technologies Private Limited. All Right Reserved.
              </p>
            </div>
            <div className="frame-99">
              <Link to="/terms-of-use">
                <div className="text-wrapper-16">Terms &amp; Conditions</div>
              </Link>
              <Link to="/privacy-policy">
                <div className="text-wrapper-16">Privacy Policy</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
