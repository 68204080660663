import React from "react";
import { useFormikContext, Form } from "formik";
import {
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
} from "../../../../Common/functions";
import CustomInput from "../../../../Common/components/custom/customInput";

const DealInterest = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  return (
    <div className="DealInterest fundrev-card onboardingForm-card">
      <p className="heading">Are you in contact with any of the investors?</p>
      <p className="subheading">
        Enter details if previous investors if you have any soft commitments.
      </p>
      <Form>
        <div className="companyOnboarding-form-section">
          {/* <StyledFormControl fullWidth>
            <StyledFormLabel>Current Stage</StyledFormLabel>
            <CustomSelect
              label="Select Current Stage"
              multiple={true}
              name="roundType"
              value={values.roundType}
              onChange={handleChange}
              onBlur={handleBlur} // Ensure onBlur is called for validation
              options={roundTypeOptions}
              placeholder="Select Current Stage"
              error={Boolean(touched.roundType && errors.roundType)}
              helperText={touched.roundType && errors.roundType}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Preferred Investors</StyledFormLabel>
            <CustomSelect
              label="Select Preferred Investors"
              name="preferredInvestors"
              value={values.preferredInvestors}
              onChange={handleChange}
              onBlur={handleBlur} // Ensure onBlur is called for validation
              options={preferredInvestorsOptions}
              placeholder="Select Preferred Investors"
              error={Boolean(
                touched.preferredInvestors && errors.preferredInvestors
              )}
              helperText={
                touched.preferredInvestors && errors.preferredInvestors
              }
            />
          </StyledFormControl> */}
          <StyledFormControl fullWidth>
            <StyledFormLabel>Term Sheets Received (Rs Cr.)</StyledFormLabel>
            {values.dealType === "Equity + Debt" ? (
              <div className="flex gap-[8px]">
                <CustomInput
                  placeholder="Equity raised"
                  name="fundingRaised"
                  type="number"
                  value={values.fundingRaised}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.fundingRaised && errors.fundingRaised)}
                  helperText={touched.fundingRaised && errors.fundingRaised}
                />

                <CustomInput
                  placeholder="Debt raised"
                  name="debtRaised"
                  type="number"
                  value={values.debtRaised}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.debtRaised && errors.debtRaised)}
                  helperText={touched.debtRaised && errors.debtRaised}
                />
              </div>
            ) : (
              <CustomInput
                placeholder="Enter amount raised"
                name="fundingRaised"
                type="number"
                value={values.fundingRaised}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(touched.fundingRaised && errors.fundingRaised)}
                helperText={touched.fundingRaised && errors.fundingRaised}
              />
            )}
          </StyledFormControl>
          <div
            style={{
              visibility:
                values.fundingRaised > 0 || values.debtRaised > 0
                  ? "visible"
                  : "hidden",
            }}
          >
            <StyledFormControl fullWidth>
              <StyledFormLabel>Lead/Co-Investors</StyledFormLabel>
              <CustomInput
                placeholder="Enter Lead/Co-Investors"
                name="coInvestors"
                type="text"
                value={values.coInvestors}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.coInvestors && errors.coInvestors)}
                helperText={touched.coInvestors && errors.coInvestors}
              />
            </StyledFormControl>
          </div>

          <StyledFormControl component="fieldset">
            <StyledFormLabel component="legend">
              Do you have soft commitments?
            </StyledFormLabel>
            <div className="flex gap-[24px] mt-[16px]">
              <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                <input
                  type="checkbox"
                  name="softCommitments"
                  checked={values.softCommitments === true}
                  onChange={() => setFieldValue("softCommitments", true)}
                  onBlur={handleBlur}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "4px",
                  }}
                />
                Yes
              </label>
              <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                <input
                  type="checkbox"
                  name="softCommitments"
                  checked={values.softCommitments === false}
                  onChange={() => setFieldValue("softCommitments", false)}
                  onBlur={handleBlur}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "4px",
                  }}
                />
                No
              </label>
            </div>

            {touched.softCommitments && errors.softCommitments && (
              <div style={{ color: "red" }}>{errors.softCommitments}</div>
            )}
          </StyledFormControl>

          {/* <StyledFormControl fullWidth>
                <StyledFormLabelOptional>Lead Investor</StyledFormLabelOptional>
                <CustomInput
                  placeholder="Enter lead Investor"
                  name="leadInvestor"
                  type="text"
                  value={values.leadInvestor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.leadInvestor && errors.leadInvestor)}
                  helperText={touched.leadInvestor && errors.leadInvestor}
                />
              </StyledFormControl> */}
        </div>
        <div className="mt-[40px]">
          {values.softCommitments === true && (
            <StyledFormControl fullWidth>
              <StyledFormLabelOptional>
                List Current Investor Contacts
              </StyledFormLabelOptional>
              <CustomInput
                placeholder={`List the investors you're currently in talks with. You can invite them later to view analytics. Note: These investors won't be contacted by us, so only include strong connections to avoid missing bids.`}
                placeholderColor="#EA8C8C"
                name="contactedInvestorNames"
                type="text"
                value={values.contactedInvestorNames}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(
                  touched.contactedInvestorNames &&
                    errors.contactedInvestorNames
                )}
                helperText={
                  touched.contactedInvestorNames &&
                  errors.contactedInvestorNames
                }
                multiline
                minRows={4}
              />
            </StyledFormControl>
          )}
        </div>
      </Form>
    </div>
  );
};

export default DealInterest;
