import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const faqs = [
  {
    question: "How does Fundrev help with fundraising?",
    answer:
      "Fundrev streamlines fundraising by connecting companies with active investors whose investment thesis aligns with their profiles. It offers tools to effectively showcase businesses, along with investor diligence-ready analytics and a secure data room, ensuring a seamless and efficient fundraising process.",
  },
  {
    question: "Can Fundrev support mergers, acquisitions, and exits?",
    answer:
      "Yes, Fundrev provides tailored support for exploring and executing M&A opportunities, whether you're looking to merge, acquire, or sell your business. It also simplifies exits through tools for secondary sales, acquisitions, and other liquidity options, ensuring a smooth and strategic process.",
  },
  {
    question: "How much does it cost to use Fundrev.ai?",
    answer:
      "Fundrev operates on a success-based pricing model for companies, with zero upfront fees and no retainers. You only pay a fee after successfully closing a deal through the platform. For investors, Fundrev is completely free to browse and view listings, making it an accessible and risk-free way to explore high-potential opportunities.",
  },
  {
    question: "What tools are available for investor management and reporting?",
    answer:
      "Fundrev simplifies investor management with tools for centralized communication, detailed reporting, and secure data sharing. Keep all interactions in one place, provide transparent updates with comprehensive reports, and use secure data rooms to share sensitive documents confidently. These features streamline investor relations and foster trust.",
  },
  {
    question: "What features does Fundrev offer for portfolio management?",
    answer:
      "Fundrev provides a seamless portfolio management experience by allowing investors to track their investments, analyze performance metrics, and manage their portfolios efficiently—all within a single, intuitive platform.",
  },
  {
    question: "Can I register as an Angel Investor on Fundrev?",
    answer:
      "At present, angel investors can only access specific deals by invitation when the lead investor opts to onboard them for a particular opportunity, without the ability to filter new investment opportunities independently.",
  },
];

export default function FAQ({ faqsRef }) {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="container flex-col mx-auto my-[120px] md:mt-[160px] md:mb-[177px]">
      <h2
        ref={faqsRef}
        className="w-[70%] md:w-full m-auto text-[28px] md:text-h2 2xl:text-res-h2 font-medium text-center text-[var(--Primary-Text-Color)] letter mb-[40px] md:mb-[72px] scroll-m-24"
      >
        Frequently Asked Questions
      </h2>
      <div className="w-[90%] md:w-full max-w-[800px] mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="rounded-lg border border-[#D8D8D8] hover:bg-gray-100 transition-colors"
          >
            <button
              onClick={() => toggleQuestion(index)}
              className="flex items-center justify-between w-full p-4 text-left"
              aria-expanded={expandedIndex === index}
              aria-controls={`faq-${index}`}
            >
              <span className="text-[var(--Primary-Text-Color)] text-sm 2xl:text-res-sm font-medium">
                {faq.question}
              </span>
              <motion.div
                initial={false}
                animate={{ rotate: expandedIndex === index ? 180 : 0 }}
                transition={{ duration: 0.2 }}
                className="flex-shrink-0 ml-4"
              >
                {expandedIndex === index ? (
                  <RemoveIcon className="w-5 h-5" />
                ) : (
                  <AddIcon className="w-5 h-5" />
                )}
              </motion.div>
            </button>
            <AnimatePresence initial={false}>
              {expandedIndex === index && (
                <motion.div
                  id={`faq-${index}`}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="p-4 pt-0 text-[#575757] text-sm 2xl:text-res-sm leading-6">
                    {faq.answer}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
}
