import React, { useState, useEffect } from "react";
import { getAllDealInteractions } from "../../../../endpoints/deal";
import DocumentFile from "../../../Common/components/documentFile";
import QnaSection from "./qnaSection";

import { dealInterestByStage } from "../../../../endpoints/deal";
import { useLoader } from "../../../Common/LoaderProvider";
const Activity = ({ dealId, investorID, isDealLive }) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [NDADocument, setNDADocument] = useState([]);
  const [IoiBid, setIoiBid] = useState([]);
  const [LoiAgreement, setLoiAgreement] = useState([]);
  const [SPA, setSPA] = useState([]);
  const [latestStage, setLatestStage] = useState();
  const stages = [
    { dateKey: "initialStage", index: 0 },
    { dateKey: "requestSent", index: 1 },
    { dateKey: "requestAccepted", index: 2 },
    { dateKey: "ioiSubmitted", index: 3 },
    { dateKey: "ioiAccepted", index: 4 },
    { dateKey: "loiSubmitted", index: 5 },
    { dateKey: "loiAccepted", index: 6 },
    { dateKey: "spaNegotiation", index: 7 },
    { dateKey: "dealClosed", index: 8 },
  ];
  useEffect(() => {
    const fetchAllInteractions = async () => {
      try {
        const response = await getAllDealInteractions(
          dealId,
          investorID,
          token
        );
        setLatestStage(response.data[response.data.length - 1].Stage);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
    };
    fetchAllInteractions();
  }, [dealId, investorID]);
  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      const response = await dealInterestByStage(
        dealId,
        investorID,
        stage,
        token
      );
      loader.stop();
      if (response) {
        if (stage === "requestAccepted") {
          const filteredData = response.data.filter((item) => item.NDADocument);
          const NDADocuments = filteredData.map((item) => item.NDADocument);
          setNDADocument(NDADocuments[0]);
        } else if (stage === "ioiSubmitted") {
          const IOIBids = response.data.map((item) => item.IOI_Bid_Details);
          setIoiBid(IOIBids[0].IOILetter);
        } else if (stage === "loiSubmitted") {
          const LOIAgreements = response.data.map(
            (item) => item.LOI_Bid_Details
          );
          setLoiAgreement(LOIAgreements[0].LOILetter);
        } else if (stage === "spaNegotiation") {
          const filteredData = response.data.filter((item) => item.SPA);
          const SPAs = filteredData.map((item) => item.SPA);
          setSPA(SPAs[0]);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };

  const files = [
    { file: NDADocument, name: "NDADocument" },
    { file: IoiBid, name: "IoiBid" },
    { file: LoiAgreement, name: "LoiAgreement" },
    { file: SPA, name: "SPA" },
  ];

  const getDealDocuments = async () => {
    if (dealId && investorID) {
      const index = stages.findIndex((stage) => stage.dateKey === latestStage);

      if (index >= 3) {
        getDealInterestByStage(dealId, investorID, "ioiSubmitted");
      }
      if (index >= 4) {
        getDealInterestByStage(dealId, investorID, "loiSubmitted");
      }
      if (index >= 5) {
        getDealInterestByStage(dealId, investorID, "spaNegotiation");
      }
    }
  };
  useEffect(() => {
    loader.start("Getting deal documents...");
    getDealDocuments();
    loader.stop();
  }, [dealId, investorID, latestStage]);
  return (
    <>
      <div className="company-activity-div">
        <div className="activity-deal-documents fundrev-card">
          <p className="fundrev-card-heading">Deal Documents</p>
          <div className="all-file-uploads">
            {files && files.length > 0 ? (
              files.every(({ file }) => file.length === 0) ? (
                <p>Looks like there are no documents here.</p>
              ) : (
                files.map(
                  ({ file, name }) =>
                    file.length > 0 && (
                      <DocumentFile
                        key={name}
                        file={file}
                        fileName={name}
                        loader={loader}
                        containerId="investorDetails"
                      />
                    )
                )
              )
            ) : (
              <p>Looks like there are no documents here.</p>
            )}
          </div>
        </div>
        <div className="fundrev-card">
          <QnaSection
            dealId={dealId}
            investorID={investorID}
            isDealLive={isDealLive}
          />
        </div>
      </div>
    </>
  );
};

export default Activity;
