import * as React from "react";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";
import NoDeals from "../../../../Assets/Images/InvestorPage/Dashboard/noDeals.gif";
function NoRecommendedDeals() {
  const Navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center p-6 text-base bg-white rounded-3xl shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 max-w-[850px]  mx-auto mt-[28px]">
      <div className="flex flex-col items-center max-md:max-w-full">
        <div className="flex flex-col items-center text-center max-md:max-w-full">
          <div className="font-medium text-zinc-900 max-md:max-w-full">
            Heads up! Our recommendation engine requires a complete profile to
            begin.
          </div>
          <div className="mt-2 leading-7 text-gray-500 max-md:max-w-full">
            Update your investment preferences so we can deliver tailored
            startup opportunities that fit your interests.
          </div>
        </div>
        <img
          loading="lazy"
          srcSet={NoDeals}
          className="object-contain mt-6 w-60 max-w-full aspect-square"
        />
        <div
          onClick={() => {
            Navigate("/investor/account", {
              state: {
                activeButton: 2,
              },
            });
          }}
        >
          <CustomBlackBtn type="button" text="Complete Profile" />
        </div>
        <div className="flex flex-wrap gap-1 justify-center items-start mt-6 leading-7 max-md:max-w-full">
          <div className="flex gap-1 justify-center items-start">
            <div className="text-gray-500">Or, you can</div>
            <div
              className="font-semibold text-blue-500 cursor-pointer"
              onClick={() => Navigate("/investor/newDeals/allListing")}
            >
              view all listings
            </div>
          </div>
          <div className="text-gray-500 max-md:max-w-full">
            to explore all available opportunities without personalized
            recommendations.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoRecommendedDeals;
