import React, { useState, useEffect, useRef } from "react";
// import { Illustration1 } from "../illustrations/illustration1";
// import { Illustration2 } from "../illustrations/illustration2";
// import Illustration3 from "../illustrations/illustration3";
import primaryDeals from "../../../Assets/Images/LandingPage/illustrations/primaryDeals.gif";
import portFolio from "../../../Assets/Images/LandingPage/illustrations/portFolio.gif";
import secondaryDeals from "../../../Assets/Images/LandingPage/illustrations/secondaryDeals.gif";
import IllustrationHeader from "../illustrations/illustrationHeader";

export default function FrontSectionCarousel() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const totalSlides = 3;
  const autoScrollInterval = 5000; // 5 seconds
  const autoSlideIntervalRef = useRef(null); // Store the auto-slide interval

  useEffect(() => {
    startAutoSlide(); // Start the auto-slide interval

    const progressInterval = setInterval(() => {
      setElapsedTime((prevTime) =>
        prevTime < autoScrollInterval ? prevTime + 100 : 0
      );
    }, 100); // Update progress every 100ms

    return () => {
      clearAutoSlideInterval(); // Cleanup auto-slide interval
      clearInterval(progressInterval); // Cleanup progress interval
    };
  }, []);

  const startAutoSlide = () => {
    autoSlideIntervalRef.current = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      setElapsedTime(0); // Reset elapsed time on slide change
    }, autoScrollInterval);
  };

  const clearAutoSlideInterval = () => {
    if (autoSlideIntervalRef.current) {
      clearInterval(autoSlideIntervalRef.current);
      autoSlideIntervalRef.current = null;
    }
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
    setElapsedTime(0); // Reset elapsed time on manual click
    clearAutoSlideInterval(); // Clear existing interval
    startAutoSlide(); // Restart auto-slide interval
  };

  const calculateProgress = (index) => {
    if (index === activeSlide) {
      return (elapsedTime / autoScrollInterval) * 100;
    }
    return 0; // No progress for inactive slides
  };

  return (
    <div className="md:mt-10 flex flex-col items-center mt-0">
      <div className="md:gap-14 md:mt-12 lg:mt-0 2xl:gap-6 flex justify-center gap-8 mt-0">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            className="flex flex-col items-center gap-2 cursor-pointer"
            key={index}
            onClick={() => handleDotClick(index)}
          >
            <IllustrationHeader index={index + 1} />
            <div
              className="2xl:w-20 relative w-16 h-1 overflow-hidden bg-gray-200 rounded-full"
              aria-label={`Go to slide ${index + 1}`}
            >
              <div
                className="absolute left-0 top-0 h-full bg-[var(--brand-color)] transition-all"
                style={{ width: `${calculateProgress(index)}%` }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="relative w-full mt-8 overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${activeSlide * 100}%)` }}
        >
          {/* First div */}
          <div className="w-[100%] flex-shrink-0 flex justify-center">
            <img
              src={primaryDeals}
              alt="Primary Deals"
              className="object-contain"
            />
          </div>

          {/* Second div */}
          <div className="w-[100%] flex-shrink-0 flex justify-center">
            <img
              src={portFolio}
              alt="Portfolio monitoring"
              className="object-contain"
            />
          </div>

          {/* Third div */}
          <div className="w-[100%] flex-shrink-0 flex justify-center">
            <img
              src={secondaryDeals}
              alt="Secondary Deals"
              className="object-contain"
            />
          </div>
        </div>
      </div>

      {/* Line Navigation */}
    </div>
  );
}
