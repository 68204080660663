import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const TeamMemberCard = ({ member, foundingTeam, role }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 2,
        borderRadius: 2,
        position: "relative",
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Avatar
            src={foundingTeam ? member.foundingTeamProfile : member.memberImg}
            alt={`${member.name} Profile Picture`}
            sx={{ width: 72, height: 72, marginRight: 2 }}
          />
          <Box>
            <Typography variant="h6">{member.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {role === "company" && !foundingTeam && (
                <>Startup Member ID: {member.startupMemberID}</>
              )}
              {role === "investor" && (
                <>Investor Member ID: {member.investorMemberID}</>
              )}
            </Typography>
          </Box>
          {member.isAdmin && (
            <Chip
              label="Admin"
              color="primary"
              size="small"
              sx={{ position: "absolute", top: 8, right: 8 }}
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" marginBottom={2}>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <MailIcon fontSize="small" sx={{ marginRight: 1 }} />
            <Link href={`mailto:${member.email}`} underline="hover">
              {member.email}
            </Link>
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <PhoneIcon fontSize="small" sx={{ marginRight: 1 }} />
            <Link href={`tel:${member.phoneNumber}`} underline="hover">
              {member.phoneNumber}
            </Link>
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <LinkedInIcon fontSize="small" sx={{ marginRight: 1 }} />
            <Link
              href={member.linkedIn || member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              LinkedIn Profile
            </Link>
          </Box>

          <Box marginBottom={1}>
            <Typography>Bio: {member.biography}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TeamMemberCard;
