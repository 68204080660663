import React from "react";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

import { Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../LoaderProvider";

const CompleteOnBoardingModal = ({
  openCompleteOnBoardingModal,
  setOpenCompleteOnBoardingModal,
  navigateToInvestorOnboarding,
}) => {
  const Navigate = useNavigate();
  const loader = useLoader();

  return (
    <>
      <Modal
        open={openCompleteOnBoardingModal}
        onClose={() => setOpenCompleteOnBoardingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "180px" }}
        >
          <div className="reason-box-first-div">
            <p className="CompleteOnBoardingModal-text">
              You have to complete the onboarding before sending the request to
              the platform.
            </p>
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{ cursor: "pointer" }}
              alt="close"
              onClick={() => setOpenCompleteOnBoardingModal(false)}
            ></img>
          </div>
          <div className="btn-box" style={{ display: "space-between" }}>
            <button className="reason-submit cancel" style={{ height: "40px" }}>
              Cancel
            </button>
            <button
              className="reason-submit"
              style={{ width: "fit-content", padding: "10px" }}
              onClick={() => navigateToInvestorOnboarding()}
            >
              Complete Onboarding
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CompleteOnBoardingModal;
