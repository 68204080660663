import {
  Box,
  Button,
  Modal,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
} from "@mui/material";
import { ClockIcon } from "@radix-ui/react-icons";
import cross from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MdFreeCancellation } from "react-icons/md";
import { cancelScheduledMeeting } from "../../../endpoints/startup";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import { ToastContainer } from "react-toastify";
import Toast from "../../Common/Toast";
import { StyledFormControl } from "../../Common/functions";
import CustomInput from "../../Common/components/custom/customInput";
import { useLoader } from "../../Common/LoaderProvider";
import emptyState from "../../../Assets/Images/icons/emptyStateFolder.svg";

const MeetingTable = ({ data }) => {
  const loader = useLoader();
  const [timeline, SetTimeline] = useState(1);
  const [filteredMeetings, SetFilteredMeetings] = useState(null);
  const [reason, setReason] = useState(null);
  const [cancelMeetingModal, setCancelMeetingModal] = useState(false);
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      SetTimeline(newAlignment);
    }
  };
  const handleCancelMeeting = async () => {
    loader.start(`Cancelling the meeting...`);
    const response = await cancelScheduledMeeting({
      ...cancelMeetingModal,
      reason,
    });
    loader.stop();
    if (response) {
      Toast(`Meeting cancelled successfully!`, `info`, `CancelMeeting`);
      setCancelMeetingModal(false);
    } else {
      Toast(`Something went wrong!`, `error`, `CancelMeeting`);
    }
  };
  const handleFilteredMeetings = () => {
    const currentTime = dayjs().tz("Asia/Kolkata");

    const filteredMeetings =
      data?.meetingsTable?.length > 0 &&
      data.meetingsTable.filter((meeting) => {
        const meetingStart = dayjs(meeting.timings.start).tz("Asia/Kolkata");

        if (timeline === 1) {
          // Upcoming meetings
          return meetingStart.isAfter(currentTime);
        } else if (timeline === 2) {
          // Past meetings
          return meetingStart.isBefore(currentTime);
        }
        return false;
      });

    SetFilteredMeetings(filteredMeetings);
  };
  const handleInput = (e) => {
    setReason(e.target.value);
  };
  useEffect(() => {
    handleFilteredMeetings();
  }, [timeline, data?.meetingsTable?.length]);
  useEffect(() => {}, [filteredMeetings?.length]);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      borderRadius: "8px",
      marginTop: "4px",
      marginX: "4px",
      height: "32px",
      fontFamily: "Karla, sans-serif",
      fontSize: "14px",
      color: "var(--Primary-Text-Color)",
      fontWeight: 500,
      border: 0,
      '&[aria-pressed="true"]': {
        backgroundColor: "white",
        border: "1px solid #AEB3C1",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
  }));
  return (
    <>
      <ToastContainer containerId={`CancelMeeting`} position="top-center" />
      {cancelMeetingModal && (
        <Modal
          open={cancelMeetingModal}
          onClose={() => setCancelMeetingModal(false)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              alignItems: "center",
              height: "fit-content",
              width: "600px",
              backgroundColor: "white",
              borderRadius: "20px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                padding: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <p className="font-[Roboto] text-xl">
                  Cancel Scheduled Meeting{" "}
                </p>

                {
                  <img
                    src={cross}
                    alt="cross"
                    onClick={() => setCancelMeetingModal(false)}
                    className="cursor-pointer"
                  />
                }
              </Box>
              <Box>
                <StyledFormControl fullWidth>
                  <Typography
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      color: "var(--Primary-Text-Color)",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Reason
                  </Typography>
                  <CustomInput
                    placeholder="Please state the reason for cancelling the scheduled meeting."
                    name="meetingAgenda"
                    value={reason}
                    onChange={handleInput}
                    multiline={true} // Enable multiline
                    minRows={3}
                  />
                </StyledFormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                  marginTop: "16px",
                  width: "100%",
                }}
              >
                <CustomBlackBtn
                  text={`Cancel Meeting`}
                  onClick={handleCancelMeeting}
                ></CustomBlackBtn>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <Box
        sx={{
          height: "396px",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <div className="text-base text-[#696F79]">Investor Meeting</div>
          <Box
            sx={{
              backgroundColor: "#D2DCFF",
              borderRadius: "8px",
              height: "40px",
              paddingX: "8px",
            }}
          >
            <StyledToggleButtonGroup
              exclusive
              aria-label="Period"
              value={timeline}
              onChange={handleChange}
            >
              <ToggleButton value={1}>Upcoming</ToggleButton>
              <ToggleButton value={2}>Past</ToggleButton>
            </StyledToggleButtonGroup>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            height: "240px",
          }}
        >
          {filteredMeetings?.length > 0 ? (
            filteredMeetings?.map((meeting) => (
              <>
                <Box
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid #696F79",
                    height: "64px",
                    paddingX: "16px",
                    paddingY: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",

                      borderRight: "1px solid #696F79",
                      width: "60px",
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Karla, sans-serif",
                        fontSize: "12px",
                        color: "var(--Primary-Text-Color)",
                      }}
                    >
                      {dayjs(meeting.timings.start)
                        .tz(`Asia/Kolkata`)
                        .format(`ddd`)}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Karla, sans-serif",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "var(--Primary-Text-Color)",
                      }}
                    >
                      {dayjs(meeting.timings.start)
                        .tz(`Asia/Kolkata`)
                        .format(`D MMM`)}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Karla, sans-serif",
                          fontSize: "12px",
                          color: "var(--Secondary-Text-Color)",
                          gap: "4px",
                        }}
                      >
                        <ClockIcon />
                        {dayjs(meeting.timings.start)
                          .tz(`Asia/Kolkata`)
                          .format(`hh:mma`)}{" "}
                        -{" "}
                        {dayjs(meeting.timings.end)
                          .tz(`Asia/Kolkata`)
                          .format(`hh:mma`)}
                      </Box>
                      {timeline === 1 && (
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => setCancelMeetingModal(meeting)}
                        >
                          <MdFreeCancellation size={`20px`} color="#D32F2F" />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Karla, sans-serif",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "var(--Primary-Text-Color)",
                        textWrap: "stable",
                      }}
                    >
                      <Box>Meeting with&nbsp;</Box>
                      <Box
                        sx={{
                          wordBreak: "break-word", // Ensure that long words are broken to wrap
                          overflow: "hidden", // Hide any overflow content to prevent growing
                          textOverflow: "ellipsis", // Add ellipsis if the text overflows
                          whiteSpace: "normal", // Allow wrapping of the text
                          maxWidth: "100%", // Make sure the box does not exceed container width
                        }}
                      >
                        {meeting?.firmName}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ))
          ) : (
            <Box
              sx={{
                textAlign: "center",
                padding: "16px", // Adds padding for spacing
                fontSize: "16px", // Ensures readable text size
                color: "var(--Secondary-Text-Color)", // Uses a secondary text color for a softer appearance
                marginTop: "80px",
              }}
            >
              <div className="flex flex-col items-center">
                <img src={emptyState} alt="emptyState" />
                <p className="opacity-30 text-[var(--Primary-Text-Color)] mt-2">
                  {timeline === 1 && "No upcoming investor meeting"}
                  {timeline === 2 && "No past meeting"}
                </p>
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MeetingTable;
