import React from "react";
import { useFormikContext, Form } from "formik";
import {
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  dealTypeOptions,
  preferredInvestorsOptions,
  debtTypeOptions,
  roundTypeOptions,
} from "../../../../Common/functions";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import CustomInput from "../../../../Common/components/custom/customInput";
import CustomDateInput from "../../../../Common/components/custom/customDateInput";

const DealDetails = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  return (
    <div className="dealDetails fundrev-card onboardingForm-card">
      <p className="heading">Provide Deal Details</p>
      <p className="subheading">
        Enter details relating to the amount of funding required
      </p>
      <Form>
        <div className="companyOnboarding-form-section">
          {/* Deal Type Selection */}
          <StyledFormControl fullWidth>
            <StyledFormLabel>Deal Type</StyledFormLabel>
            <CustomSelect
              label="Select deal Type"
              name="dealType"
              value={values.dealType}
              onChange={handleChange}
              onBlur={handleBlur}
              options={dealTypeOptions}
              placeholder="Select Deal Type"
              error={Boolean(touched.dealType && errors.dealType)}
              helperText={touched.dealType && errors.dealType}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Target Close Date?</StyledFormLabel>
            <CustomDateInput
              label="Select date"
              value={values.timeline}
              onChange={(newValue) => setFieldValue("timeline", newValue)}
              onBlur={handleBlur}
              error={Boolean(touched.timeline && errors.timeline)}
              helperText={touched.timeline && errors.timeline}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Funding Required (Rs Cr.)</StyledFormLabel>

            {values.dealType === "Equity + Debt" ? (
              <div className="flex gap-[8px]">
                <CustomInput
                  placeholder="Equity"
                  name="fundingRequired"
                  type="number"
                  value={values.fundingRequired}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.fundingRequired && errors.fundingRequired
                  )}
                  helperText={touched.fundingRequired && errors.fundingRequired}
                />
                <CustomInput
                  placeholder="Debt"
                  name="debtFundingRequired"
                  type="number"
                  value={values.debtFundingRequired}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.debtFundingRequired && errors.debtFundingRequired
                  )}
                  helperText={
                    touched.debtFundingRequired && errors.debtFundingRequired
                  }
                />
              </div>
            ) : (
              <CustomInput
                placeholder="Enter Funding Required"
                name="fundingRequired"
                type="number"
                value={values.fundingRequired}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(
                  touched.fundingRequired && errors.fundingRequired
                )}
                helperText={touched.fundingRequired && errors.fundingRequired}
              />
            )}
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Current Round</StyledFormLabel>
            <CustomSelect
              label="Select Current Round"
              name="roundType"
              value={values.roundType}
              onChange={handleChange}
              onBlur={handleBlur}
              options={roundTypeOptions}
              placeholder="Select Current Round"
              error={Boolean(touched.roundType && errors.roundType)}
              helperText={touched.roundType && errors.roundType}
            />
          </StyledFormControl>

          {/* Show fields for Debt options */}
          {(values.dealType === "Debt" ||
            values.dealType === "Equity + Debt") && (
            <>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Select Debt Type</StyledFormLabel>
                <CustomSelect
                  label="Select debt Type"
                  name="debtType"
                  value={values.debtType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={debtTypeOptions}
                  placeholder="Select Debt Type"
                  error={Boolean(touched.debtType && errors.debtType)}
                  helperText={touched.debtType && errors.debtType}
                />
              </StyledFormControl>

              {/* Term field for RBF (if selected) */}
              {(values.debtType === "Revenue Based Financing" ||
                values.debtType === "Conventional Debt") && (
                <StyledFormControl fullWidth>
                  <StyledFormLabelOptional>Term</StyledFormLabelOptional>
                  <CustomInput
                    placeholder="Enter value in months"
                    name="term"
                    type="number"
                    value={values.term}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.term && errors.term)}
                    helperText={touched.term && errors.term}
                  />
                </StyledFormControl>
              )}
            </>
          )}
          <StyledFormControl fullWidth>
            <StyledFormLabel>Preferred Investors</StyledFormLabel>
            <CustomSelect
              label="Select Preferred Investors"
              name="preferredInvestors"
              value={values.preferredInvestors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={preferredInvestorsOptions}
              placeholder="Select Preferred Investors"
              error={Boolean(
                touched.preferredInvestors && errors.preferredInvestors
              )}
              helperText={
                touched.preferredInvestors && errors.preferredInvestors
              }
              multiple={true}
            />
          </StyledFormControl>
        </div>
      </Form>

      {/* <StyledFormControl fullWidth>
            <StyledFormLabel>Funding Required (Rs Cr.)</StyledFormLabel>

            {values.dealType === "Equity + Debt" ? (
              <div className="flex gap-[8px]">
                <CustomInput
                  placeholder="Equity"
                  name="fundingRequired"
                  type="number"
                  value={values.fundingRequired}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.fundingRequired && errors.fundingRequired
                  )}
                  helperText={touched.fundingRequired && errors.fundingRequired}
                />
                <CustomInput
                  placeholder="Debt"
                  name="debtFundingRequired"
                  type="number"
                  value={values.debtFundingRequired}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.debtFundingRequired && errors.debtFundingRequired
                  )}
                  helperText={
                    touched.debtFundingRequired && errors.debtFundingRequired
                  }
                />
              </div>
            ) : (
              <CustomInput
                placeholder="Enter Funding Required"
                name="fundingRequired"
                type="number"
                value={values.fundingRequired}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(
                  touched.fundingRequired && errors.fundingRequired
                )}
                helperText={touched.fundingRequired && errors.fundingRequired}
              />
            )}
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Amount already raised (Rs Cr.)</StyledFormLabel>
            {values.dealType === "Equity + Debt" ? (
              <div className="flex gap-[8px]">
                <CustomInput
                  placeholder="Equity raised"
                  name="Equity"
                  type="number"
                  value={values.fundingRaised}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(touched.fundingRaised && errors.fundingRaised)}
                  helperText={touched.fundingRaised && errors.fundingRaised}
                />

                <CustomInput
                  placeholder="Debt raised"
                  name="debtRaised"
                  type="number"
                  value={values.debtRaised}
                  onChange={handleChange} // Use custom handleChange here
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.debtRaised && errors.debtRaised
                  )}
                  helperText={
                    touched.debtRaised && errors.debtRaised
                  }
                />
              </div>
            ) : (
              <CustomInput
                placeholder="Enter amount raised"
                name="fundingRaised"
                type="number"
                value={values.fundingRaised}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(touched.fundingRaised && errors.fundingRaised)}
                helperText={touched.fundingRaised && errors.fundingRaised}
              />
            )}
          </StyledFormControl> */}
      {/* <StyledFormControl component="fieldset">
            <StyledFormLabel component="legend">
              Do you have soft commitments?
            </StyledFormLabel>
            <div className="flex gap-[24px] mt-[16px]">
              <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                <input
                  type="checkbox"
                  name="softCommitments"
                  checked={values.softCommitments === "yes"}
                  onChange={() => setFieldValue("softCommitments", "yes")}
                  onBlur={handleBlur}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "4px",
                  }}
                />
                Yes
              </label>
              <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                <input
                  type="checkbox"
                  name="softCommitments"
                  checked={values.softCommitments === "no"}
                  onChange={() => setFieldValue("softCommitments", "no")}
                  onBlur={handleBlur}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "4px",
                  }}
                />
                No
              </label>
            </div>

            {touched.softCommitments && errors.softCommitments && (
              <div style={{ color: "red" }}>{errors.softCommitments}</div>
            )}
          </StyledFormControl> */}
      {/* <StyledFormControl></StyledFormControl> */}
      {/* <div className="mt-[40px]">
          {values.softCommitments === "yes" && (
            <StyledFormControl fullWidth>
              <StyledFormLabel>List Current Investor Contacts</StyledFormLabel>
              <CustomInput
                placeholder={`List the names of investors you're actively engaged with. You'll have the option to invite them later to view your analytics. Note: Investors listed here will be excluded from our outreach, so only include names if you have a strong connection; otherwise, you might miss out on bids from them.`}
                placeholderColor="#EA8C8C"
                name="contactedInvestorNames"
                type="text"
                value={values.contactedInvestorNames}
                onChange={handleChange} // Use custom handleChange here
                onBlur={handleBlur}
                error={Boolean(
                  touched.contactedInvestorNames &&
                    errors.contactedInvestorNames
                )}
                helperText={
                  touched.contactedInvestorNames &&
                  errors.contactedInvestorNames
                }
                multiline
                minRows={4}
              />
            </StyledFormControl>
          )}
        </div> */}
    </div>
  );
};

export default DealDetails;
