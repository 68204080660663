import React, { useEffect, useRef, useState } from "react";

import FinancialStatementAnalytics from "./financialStatementAnalytics";
import AnalyticsOverview from "./analyticsOverview";
import { Box, Container, Typography } from "@mui/material";
import {
  getCustomerTapeData,
  getFinancialStatementData,
} from "../../../../../endpoints/fundrevAnalyst";
import { useLoader } from "../../../../Common/LoaderProvider";
import BusinessAnalytics from "./businessAnalytics";
import { getAnalyticsData } from "../../../../../endpoints/common";
import HistoricalFinancials from "./historicalFinancials";

import StayTuned from "../../../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";
import CardTags from "../../../../Common/components/molecules/cardTags";
import ProgressBar from "../../../../Common/components/molecules/progressBar";
import { Helmet } from "react-helmet";
import CompanyOverviewLeftCard from "../../Components/CompanyOverviewLeftCard";
//TODO: For Quaterly data need check last month end on quater or not
const Analytics = ({ deal, analytics, setAnalytics, stage }) => {
  const token = localStorage.getItem("token");
  const companyName = deal ? deal.companyName : "";
  const [financialData, setFinancialData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 135;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    getFinancialData();
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      const response = await getAnalyticsData(deal.dealId);
      setAnalyticsData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };

  const getFinancialData = async () => {
    try {
      const response = await getFinancialStatementData({
        dealId: deal.dealId || null,
        token,
      });
      setFinancialData(response.data.data);
    } catch (err) {
    } finally {
      loader.stop();
      setLoading(false);
    }
  };
  if (loading) {
    loader.start("Fetching data...");
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <p
              style={{
                font: "Karla, sans-serif",
                fontSize: "20px",
                marginBottom: "40px",
              }}
            >
              No analytics available at the moment. Data is being processed.
              Please try again soon.
            </p>
            <img src={StayTuned} style={{ width: "500px" }} />
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${companyName} - Analytics`}</title>
      </Helmet>
      <section className="NoNamePreview">
        <div className="NoNamePreview-left">
          <CompanyOverviewLeftCard deal={deal} />
          <div className="noName-navigation">
            <div
              className={`noName-nav-btn ${
                analytics === "summary" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("summary")}
            >
              <p>Summary</p>
            </div>
            <div
              className={`noName-nav-btn ${
                analytics === "businessAnalytics" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("businessAnalytics")}
            >
              <p>Business Analytics</p>
            </div>
            <div
              className={`noName-nav-btn ${
                analytics === "salesAnalytics" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("salesAnalytics")}
            >
              <p>Sales Analytics</p>
            </div>
            <div
              className={`noName-nav-btn ${
                analytics === "historicalFinancials" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("historicalFinancials")}
            >
              <p>Historical Financials</p>
            </div>
            <div
              className={`noName-nav-btn ${
                analytics === "financialForecast" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("financialForecast")}
            >
              <p>Financial Forecast</p>
            </div>
            <div
              className={`noName-nav-btn ${
                analytics === "fundingDetails" && "selected-nav-btn"
              }`}
              onClick={() => setAnalytics("fundingDetails")}
            >
              <p>Funding Details</p>
            </div>
          </div>
        </div>
        <div
          className="NoNamePreview-right"
          style={{ paddingBottom: "120px", maxWidth: "71%", width: "auto" }}
        >
          {analytics === "summary" && analyticsData?.summary && (
            <AnalyticsOverview
              deal={deal}
              data={analyticsData.summary}
              setAnalytics={setAnalytics}
              stage={stage}
            />
          )}
          {analytics === "businessAnalytics" &&
            analyticsData?.businessAnalytics && (
              <BusinessAnalytics
                data={analyticsData.businessAnalytics}
                deal={deal}
                stage={stage}
              />
            )}
          {analytics === "financialStatement" && (
            <FinancialStatementAnalytics data={financialData} />
          )}
          {analytics === "historicalFinancials" &&
            analyticsData?.historicalFinancials && (
              <HistoricalFinancials
                data={analyticsData.historicalFinancials}
                deal={deal}
                stage={stage}
              />
            )}
        </div>
      </section>
    </>
  );
};

export default Analytics;
