import Toast from "../../../Common/Toast";

//Function is being used editCompanyDetails.jsx(Form where company edit the profile) and dealDetailsForm(Admin edits the company details)
export const isProfileComplete = (companyDetails, foundingMembers) => {
  // Check if all required fields in companyDetails are filled
  const companyFieldsFilled =
    companyDetails.companyName &&
    companyDetails.legalEntityName &&
    companyDetails.headquarters &&
    companyDetails.foundedYear &&
    companyDetails.numEmployees &&
    companyDetails.website &&
    companyDetails.industryVerticals?.length > 0 &&
    companyDetails.cin &&
    companyDetails.taxNumber &&
    companyDetails.endMarket?.length > 0 &&
    companyDetails.erpPlatform &&
    // companyDetails.keyInvestors &&
    // companyDetails.techstack &&
    companyDetails.companyLogo;

  if (!companyFieldsFilled) {
    Toast(`Please fill all company details.`, `info`, `editCompanyProfile`);
    return false; // If any company details are not filled, profile cannot be complete
  }

  // Check if at least one founding member has complete details
  const atLeastOneMemberFilled =
    foundingMembers?.length > 0 &&
    foundingMembers?.every((member) => {
      return (
        member.name &&
        member.title &&
        member.email &&
        // member.linkedin &&
        member.phoneNumber &&
        member.biography &&
        member.foundingTeamProfile &&
        member.foundingTeamProfile !== ""
      );
    });

  if (!companyFieldsFilled) {
    Toast(`Please fill all company details.`, `info`, `editCompanyProfile`);
  } else if (!atLeastOneMemberFilled) {
    Toast(
      `Please fill all founding member details.`,
      `info`,
      `editCompanyProfile`
    );
  }

  return companyFieldsFilled && atLeastOneMemberFilled;
};
