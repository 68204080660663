import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs"; // Import dayjs for date handling

// Styled component for the TextField
const CustomStyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    borderWidth: "0.5px",
    height: "48px", // Set the desired fixed height here
    display: "flex",
    alignItems: "center",
    "& fieldset": {
      borderColor: "#8692A6", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#3C82F6", // Color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3C82F6", // Color when focused
    },
    "& input": {
      padding: "0px 14px", // Adjust padding to reduce the height (set top/bottom padding to zero)
      fontFamily: "'Karla', sans-serif",
      boxSizing: "border-box",
      height: "100%", // Set the height to fill the container completely
    },
    "& input::placeholder": {
      fontFamily: "'Karla', sans-serif",
      fontSize: "14px",
      color: "#696F79",
      opacity: 1,
      fontWeight: 300,
    },
  },
  "& .MuiInputAdornment-root": {
    marginRight: "8px",
  },
  "& .MuiFormHelperText-root": {
    fontFamily: "Karla, sans-serif",
  },
});

const CustomDateInput = ({
  label,
  value,
  onChange,
  error,
  helperText,
  startDecorator,
  handleKeyDown,
  minDate,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value ? dayjs(value) : null} // Convert value to a Day.js object
        onChange={onChange}
        minDate={minDate}
        renderInput={(params) => (
          <CustomStyledTextField
            {...params}
            fullWidth
            className="custom-date-input"
            variant="outlined"
            label={label}
            error={error}
            placeholder="mmm/dd/yyyy"
            helperText={helperText}
            onKeyDown={handleKeyDown ? handleKeyDown : undefined}
            InputProps={{
              ...params.InputProps,
              placeholder: "mm/dd/yy",
              startAdornment: startDecorator ? (
                <InputAdornment position="start">
                  {startDecorator}
                </InputAdornment>
              ) : null,
            }}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDateInput;
