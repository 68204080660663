import React, { useEffect, useState } from "react";
import {
  assetClassOptions,
  endMarketFocusOptions,
  financialCriteriaOptions,
  industryVerticalsOptions,
  investmentStageOptions,
  investorTypeOptions,
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
} from "../../../Common/functions";
import { getProfileCompletionPercentage } from "../../../../endpoints/investor";

import Toast from "../../../Common/Toast";
import { investorState } from "../investorState";
import {
  fetchInvestorDetails,
  PostDetails,
} from "../../../../endpoints/investor";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import { ToastContainer } from "react-toastify";
import { Box, Button } from "@mui/material";
import { useLoader } from "../../../Common/LoaderProvider";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomSelect from "../../../Common/components/custom/customSelect";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

const Profile = ({ investorID }) => {
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(investorState);
  const [firmData, setFirmData] = useState({});
  const [investorLogoPreview, setInvestorLogoPreview] = useState(uploadImg);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [initialValues, setInitialValues] = useState({
    investorLogo: investorLogoPreview,
    companyName: firmData?.companyName || "",
    headquarters: firmData?.headquarters || "",
    foundedYear: firmData?.foundedYear || "",
    investorType: firmData?.investorType || "",
    investorWebsite: firmData?.investorWebsite || "",
    investmentThesis: firmData?.investmentThesis || "",
    ticketSizevalue: firmData?.ticketSizevalue || [],
    totalExposure: firmData?.totalExposure || [],
    stakeAcquired: firmData?.stakeAcquired || [],
    investmentStage: firmData?.investmentStage || [],
    assetClass: firmData?.assetClass || [],
    coInvestment: firmData?.coInvestment || false,
    otherCriteria: firmData?.otherCriteria || "",
    financialCriteria: firmData?.financialCriteria || [],
    industryVerticalOptions: firmData?.industryVerticalOptions || [],
    endMarketFocus: firmData?.endMarketFocus || [],
    investmentDetails: firmData?.investmentDetails || [
      {
        id: randomId(),
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
        dryPowder: "",
      },
    ],
    onBoardingComplete: firmData?.onBoardingComplete || false,
    investorID: firmData?.investorID || "",
    keyInvestments: firmData?.keyInvestments || "",
    profileCompleted: firmData?.profileCompleted || false,
  });

  const validationSchema = Yup.object().shape({
    investorLogo: Yup.mixed().required(`Investor Logo is required`),
    companyName: Yup.string().required(`Firm Name is required`),
    investorType: Yup.string().required(`Investor Type is required`),
    investorWebsite: Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
        "Please enter a valid URL"
      )
      .required("Company Website is required"),
    investmentThesis: Yup.string().required(`Investment Thesis is required`),
    ticketSizevalue: Yup.array()
      .of(Yup.number().moreThan(0, "Values must be greater than zero"))
      .min(2, "Initial Check Size is required")
      .test("min-max-check", "Max must be greater than Min", (value) => {
        if (!Array.isArray(value) || value.length !== 2) {
          return false;
        }
        const [min, max] = value;
        return max >= min;
      }),
    totalExposure: Yup.array()
      .of(Yup.number().moreThan(0, "Values must be greater than zero"))
      .min(2, "Total Exposure is required")
      .test("min-max-check", "Max must be greater than Min", (value) => {
        if (!Array.isArray(value) || value.length !== 2) {
          return false;
        }
        const [min, max] = value;
        return max >= min;
      }),
    stakeAcquired: Yup.array()
      .of(
        Yup.number()
          .min(0, "Values must be at least 0")
          .max(100, "Values must be at most 100")
      )
      .min(2, "Stake Acquired is required")
      .test("min-max-check", "Max must be greater than Min", (value) => {
        if (!Array.isArray(value) || value.length !== 2) {
          return false;
        }
        const [min, max] = value;
        return max >= min;
      }),
    investmentStage: Yup.array().min(1, `Investment Stage is required`),
    assetClass: Yup.array().min(1, `Asset Class is required`),
    coInvestment: Yup.bool().required(`Co-Investment is required`),
    industryVerticalOptions: Yup.array().min(
      1,
      "Industry Verticals is required"
    ),
    endMarketFocus: Yup.array().min(1, "End Market Focus is required"),
  });
  const [schema, setSchema] = useState(validationSchema);
  useEffect(() => {
    setInitialValues({
      investorLogo: investorLogoPreview,
      companyName: firmData?.companyName || "",
      headquarters: firmData?.headquarters || "",
      foundedYear: firmData?.foundedYear || "",
      investorType: firmData?.investorType || "",
      investorWebsite: firmData?.investorWebsite || "",
      investmentThesis: firmData?.investmentThesis || "",
      ticketSizevalue: firmData?.ticketSizevalue || [],
      totalExposure: firmData?.totalExposure || [],
      stakeAcquired: firmData?.stakeAcquired || [],
      investmentStage: firmData?.investmentStage || [],
      assetClass: firmData?.assetClass || [],
      coInvestment: firmData?.coInvestment || false,
      otherCriteria: firmData?.otherCriteria || "",
      financialCriteria: firmData?.financialCriteria || [],
      industryVerticalOptions: firmData?.industryVerticalOptions || [],
      endMarketFocus: firmData?.endMarketFocus || [],
      investmentDetails: firmData?.investmentDetails || [
        {
          id: randomId(),
          fund: "",
          vintage: "",
          investments: "",
          aum: "",
          dryPowder: "",
        },
      ],
      onBoardingComplete: firmData?.onBoardingComplete || false,
      investorID: firmData?.investorID || "",
      keyInvestments: firmData?.keyInvestments || "",
      profileCompleted: firmData?.profileCompleted || false,
    });
  }, [firmData]);

  const fetchDetails = async () => {
    if (!token) return;
    let response;
    try {
      loader.start("Fetching investor details...");
      response = await fetchInvestorDetails(token, investorID);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const responseData = response.data;
      const { data } = responseData;
      const {
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        numEmployees,
        investorType,
        investorWebsite,
        investmentThesis,
        ticketSizevalue,
        totalExposure,
        stakeAcquired,
        investmentStage,
        assetClass,
        coInvestment,
        otherCriteria,
        financialCriteria,
        industryVerticalOptions,
        idealFocusOptions,
        moderatelySuitableOptions,
        negativeListOptions,
        endMarketFocus,
        ndaKey,
        investmentDetails,
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      } = data;
      const parsedDetails = investmentDetails.map((detail) =>
        JSON.parse(detail)
      );
      if (investorLogo) setInvestorLogoPreview(investorLogo);
      setFirmData({
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        investorType,
        investorWebsite,
        investmentThesis,
        ticketSizevalue: ticketSizevalue.map((detail) => JSON.parse(detail))[0],
        totalExposure: totalExposure.map((detail) => JSON.parse(detail))[0],
        stakeAcquired: stakeAcquired.map((detail) => JSON.parse(detail))[0],
        investmentStage: investmentStage.map((detail) => JSON.parse(detail))[0],
        assetClass: assetClass.map((detail) => JSON.parse(detail))[0],
        coInvestment: coInvestment || false,
        otherCriteria,
        financialCriteria: financialCriteria.map((detail) =>
          JSON.parse(detail)
        )[0],
        industryVerticalOptions: industryVerticalOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        endMarketFocus:
          endMarketFocus.length > 0
            ? endMarketFocus.map((detail) => JSON.parse(detail))[0]
            : [],
        investmentDetails: parsedDetails
          ? parsedDetails[0]
          : [
              {
                id: randomId(),
                fund: "",
                vintage: "",
                investments: "",
                aum: "",
                dryPowder: "",
              },
            ],
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      });
      setRows(
        parsedDetails?.length > 0
          ? parsedDetails[0]?.map((row) => ({ id: randomId(), ...row }))
          : [
              {
                id: randomId(),
                fund: "",
                vintage: "",
                investments: "",
                aum: "",
                dryPowder: "",
              },
            ]
      );
    } else {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token, investorID]);

  // const checkField = (data) => {
  //   let allFieldsFilled = true;
  //   let unfilledFields = [];

  //   const addUnfilledField = (fieldName) => {
  //     unfilledFields.push(fieldName);
  //     allFieldsFilled = false;
  //   };

  //   if (!data.companyName || data.companyName.trim() === "") {
  //     addUnfilledField("Company Name");
  //   }

  //   if (!data.investorType || data.investorType.trim() === "") {
  //     addUnfilledField("Investor Type");
  //   }

  //   if (!data.investorWebsite || data.investorWebsite.trim() === "") {
  //     addUnfilledField("Investor Website");
  //   }

  //   if (!data.investmentThesis || data.investmentThesis.trim() === "") {
  //     addUnfilledField("Investment Thesis");
  //   }
  //   if (
  //     !Array.isArray(data.investmentStage) ||
  //     data.investmentStage.length === 0 ||
  //     !data.investmentStage.every((v) => v && v.trim() !== "")
  //   ) {
  //     addUnfilledField("Stage of Investment");
  //   }

  //   if (
  //     !Array.isArray(data.assetClass) ||
  //     data.assetClass.length === 0 ||
  //     !data.assetClass.every((v) => v && v.trim() !== "")
  //   ) {
  //     addUnfilledField("Asset Class");
  //   }
  //   if (
  //     !Array.isArray(data.ticketSizevalue) ||
  //     data.ticketSizevalue.length === 0 ||
  //     !data.ticketSizevalue.every((v) => v > 0)
  //   ) {
  //     addUnfilledField("Initial Check Size");
  //   }
  //   if (
  //     !Array.isArray(data.totalExposure) ||
  //     data.totalExposure.length === 0 ||
  //     !data.totalExposure.every((v) => v > 0)
  //   ) {
  //     addUnfilledField("Total Exposure");
  //   }

  //   if (
  //     !Array.isArray(data.stakeAcquired) ||
  //     data.stakeAcquired.length === 0 ||
  //     !data.stakeAcquired.every((v) => v >= 0 && v <= 100)
  //   ) {
  //     addUnfilledField("Stake Acquired");
  //   }

  //   if (
  //     !Array.isArray(data.industryVerticalOptions) ||
  //     data.industryVerticalOptions.length === 0 ||
  //     !data.industryVerticalOptions.every((v) => v && v.trim() !== "")
  //   ) {
  //     addUnfilledField("Industry Verticals");
  //   }

  //   if (
  //     !Array.isArray(data.endMarketFocus) ||
  //     data.endMarketFocus.length === 0 ||
  //     !data.endMarketFocus.every((v) => v && v.trim() !== "")
  //   ) {
  //     addUnfilledField("End Market Focus");
  //   }
  //   return allFieldsFilled;
  // };

  // const checkMinMax = (data) => {
  //   let minMax = true;
  //   let minMax2 = true;
  //   if (
  //     !Array.isArray(data.ticketSizevalue) ||
  //     !data.ticketSizevalue.length ||
  //     !Array.isArray(data.totalExposure) ||
  //     !data.totalExposure.length
  //   ) {
  //     return true;
  //   }
  //   if (Number(data?.ticketSizevalue[0]) > Number(data?.ticketSizevalue[1])) {
  //     Toast(
  //       `Please check min and max values of Initial Check size field in Investment Focus.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax = false;
  //   }
  //   if (Number(data?.ticketSizevalue[0]) === 0) {
  //     Toast(
  //       `The minimum check size value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax = false;
  //   }
  //   if (Number(data?.ticketSizevalue[1]) === 0) {
  //     Toast(
  //       `The maximum check size value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax = false;
  //   }
  //   if (Number(data?.totalExposure[0]) === 0) {
  //     Toast(
  //       `The minimum total exposure value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   if (Number(data?.totalExposure[1]) === 0) {
  //     Toast(
  //       `The maximum total exposure value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   if (Number(data.totalExposure[0]) > Number(data.totalExposure[1])) {
  //     Toast(
  //       `Please check min and max values of Total Exposure field in Investment Focus.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   if (Number(data?.stakeAcquired[0]) === 0) {
  //     Toast(
  //       `The minimum stake acquired value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   if (Number(data?.stakeAcquired[1]) === 0) {
  //     Toast(
  //       `The maximum stake acquired value cannot be zero.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   if (Number(data.stakeAcquired[0]) > Number(data.stakeAcquired[1])) {
  //     Toast(
  //       `Please check min and max values of stake acquired field in Investment Focus.
  //       `,
  //       "info",
  //       "investorMembers"
  //     );
  //     minMax2 = false;
  //   }
  //   return minMax && minMax2;
  // };

  const handleFormSubmit = async (values) => {
    const formDataInput = new FormData();
    const newState = {
      ...state,
      profileCompleted: true,
    };
    setState(newState);
    values.investmentDetails = rows;
    values.profileCompleted = true;
    const data = values;
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        } else if (data[key] === "") {
          formDataInput.append(key, data[key]);
        }
      }
    }
    if (!data.investorID) {
      formDataInput.append("investorID", values.investorID);
    }
    try {
      loader.start("Updating Investor Details...");
      const response = await PostDetails(formDataInput, token);
      loader.stop();
      if (response.data.data !== null) {
        Toast("Details saved successfully", "success", "investorMembers");
        if (!values.profileCompleted) {
          Toast(
            "Your profile is incomplete. Please complete your profile for deal interactions",
            "info",
            "investorMembers"
          );
        }
      }
      profileCompletionPercentage();
    } catch (error) {
      loader.stop();
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentComplete: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          fund: "",
          vintage: "",
          investments: "",
          aum: "",
          dryPowder: "",
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <div className="w-full flex justify-between">
          <p className="ml-2 mt-2">Enter your past investment details</p>
          <Button
            sx={{
              color: "#1A1A1A",
              textTransform: "capitalize",
            }}
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add record
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "fund",
      headerName: "Fund",
      width: 160,
      headerClassName: "border-t border-r",
      editable: true,
      sortable: false,
    },
    {
      field: "vintage",
      headerClassName: "border-t border-r",
      headerName: "Vintage",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
    },
    {
      field: "investments",
      headerName: "Investments",
      headerClassName: "border-t border-r",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
    },
    {
      field: "aum",
      headerName: "AUM (Rs Cr.)",
      headerClassName: "border-t border-r",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
    },
    {
      field: "dryPowder",
      headerClassName: "border-t border-r",
      headerName: "Dry Powder (Rs Cr.)",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
    },
    {
      field: "actions",
      type: "actions",
      headerClassName: "border-t",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  useEffect(() => {
    if (role === "admin") {
      setSchema(null);
    }
  }, [role]);
  return (
    <>
      <Helmet>
        <title>Investor | Profile</title>
      </Helmet>
      <ToastContainer containerId={`investorMembers`} position="top-center" />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div className="editCompanyDetails fundrev-card">
              <div className="fundrev-card-heading mb-[16px] flex justify-between items-center">
                <p>Firm Details </p>
                <div className="text-sm bg-[#e5f5fe] p-2 border border-[#00a6fb] rounded-md text-[#00a6fb] pointer-events-none">
                  Investor ID: {values?.investorID}
                </div>
              </div>
              <div className="editCompanyDetails-form">
                <div className="editCompanyDetails-form-upload-btn-wrapper">
                  <button
                    type="button"
                    className="upload-img-btn"
                    onClick={() =>
                      document.getElementById("investorLogo").click()
                    }
                  >
                    <img
                      src={investorLogoPreview}
                      className="InvestorLogo"
                      alt="UploadImage"
                      style={{ cursor: "pointer" }}
                    />
                  </button>

                  <input
                    id="investorLogo"
                    type="file"
                    name="investorLogo"
                    accept="image/png, image/jpeg" // Accept only JPEG and PNG
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setFieldValue("investorLogo", file); // Set file in Formik state
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setInvestorLogoPreview(reader.result); // Set preview image URL
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    style={{ display: "none" }}
                  />
                  {errors.investorLogo && (
                    <div className="error text-[#d32f2f] text-xs ">
                      {errors.investorLogo}
                    </div>
                  )}
                </div>
                <div className="company-details-input-fields">
                  {/* Company Name */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Investment Firm Name</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Company Name"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </StyledFormControl>
                  {/* Headquarters */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Headquarters
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter Headquarters"
                      name="headquarters"
                      value={values.headquarters}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.headquarters && errors.headquarters
                      )}
                      helperText={touched.headquarters && errors.headquarters}
                    />
                  </StyledFormControl>
                  {/* Founded Year */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Founded Year
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter Founded Year"
                      name="foundedYear"
                      type="number"
                      value={values.foundedYear}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.foundedYear && errors.foundedYear)}
                      helperText={touched.foundedYear && errors.foundedYear}
                    />
                  </StyledFormControl>
                  {/* No. of Employees */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Investor Type</StyledFormLabel>
                    <CustomSelect
                      label="Investor Type"
                      name="investorType"
                      multiple={false}
                      value={values.investorType}
                      onChange={handleChange}
                      options={investorTypeOptions}
                      placeholder="Select Investor Type"
                      error={Boolean(
                        touched.investorType && errors.investorType
                      )}
                      helperText={touched.investorType && errors.investorType}
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Website</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Website"
                      name="investorWebsite"
                      value={values.investorWebsite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.investorWebsite && errors.investorWebsite
                      )}
                      helperText={
                        touched.investorWebsite && errors.investorWebsite
                      }
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth></StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Key Investments
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter Key Investments"
                      name={`keyInvestments`}
                      value={values.keyInvestments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.keyInvestments && errors.keyInvestments
                      )}
                      helperText={
                        touched.keyInvestments && errors.keyInvestments
                      }
                      multiline
                      minRows={4}
                    />
                  </StyledFormControl>
                  <StyledFormControl
                    fullWidth
                    sx={{
                      gridColumn: "2/-1",
                    }}
                  >
                    <StyledFormLabel>Investment Thesis</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Investment Thesis"
                      name={`investmentThesis`}
                      value={values.investmentThesis}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.investmentThesis && errors.investmentThesis
                      )}
                      helperText={
                        touched.investmentThesis && errors.investmentThesis
                      }
                      multiline
                      minRows={4}
                    />
                  </StyledFormControl>
                </div>
              </div>
            </div>
            <div className="editCompanyDetails fundrev-card">
              <p className="fundrev-card-heading mb-[16px]">
                Industry & Investment Focus
              </p>
              <div
                className="editCompanyDetails-form"
                style={{
                  gridTemplateColumns: "none",
                }}
              >
                <div className="company-details-input-fields">
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>
                      Initial Check Size (Rs Cr.)
                    </StyledFormLabel>
                    <div className="flex gap-2">
                      <CustomInput
                        placeholder={`Min Check Size`}
                        type="number"
                        value={values?.ticketSizevalue[0]}
                        onChange={(e) =>
                          setFieldValue(`ticketSizevalue`, [
                            e.target.value,
                            values?.ticketSizevalue[1],
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.ticketSizevalue && errors.ticketSizevalue
                        )}
                      />
                      <CustomInput
                        placeholder={`Max Check Size`}
                        type="number"
                        value={values?.ticketSizevalue[1]}
                        onChange={(e) =>
                          setFieldValue(`ticketSizevalue`, [
                            values?.ticketSizevalue[0],
                            e.target.value,
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.ticketSizevalue && errors.ticketSizevalue
                        )}
                      />
                    </div>
                    {touched.ticketSizevalue && errors.ticketSizevalue && (
                      <div className="error text-[#d32f2f] text-xs ml-4">
                        {errors.ticketSizevalue}
                      </div>
                    )}
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>
                      Total Portfolio Exposure (Rs Cr.)
                    </StyledFormLabel>
                    <div className="flex gap-2">
                      <CustomInput
                        placeholder={`Min Exposure`}
                        type="number"
                        value={values.totalExposure[0]}
                        onChange={(e) =>
                          setFieldValue(`totalExposure`, [
                            e.target.value,
                            values?.totalExposure[1],
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.totalExposure && errors.totalExposure
                        )}
                      />
                      <CustomInput
                        placeholder={`Max Exposure`}
                        type="number"
                        value={values.totalExposure[1]}
                        onChange={(e) =>
                          setFieldValue(`totalExposure`, [
                            values?.totalExposure[0],
                            e.target.value,
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.totalExposure && errors.totalExposure
                        )}
                      />
                    </div>
                    {touched.totalExposure && errors.totalExposure && (
                      <div className="error text-[#d32f2f] text-xs ml-4">
                        {errors.totalExposure}
                      </div>
                    )}
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Stake Acquired (%)</StyledFormLabel>
                    <div className="flex gap-2">
                      <CustomInput
                        placeholder={`Min Stake`}
                        type="number"
                        value={values?.stakeAcquired[0]}
                        onChange={(e) =>
                          setFieldValue(`stakeAcquired`, [
                            e.target.value,
                            values?.stakeAcquired[1],
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.stakeAcquired && errors.stakeAcquired
                        )}
                      />
                      <CustomInput
                        placeholder={`Max Stake`}
                        type="number"
                        value={values?.stakeAcquired[1]}
                        onChange={(e) =>
                          setFieldValue(`stakeAcquired`, [
                            values?.stakeAcquired[0],
                            e.target.value,
                          ])
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.stakeAcquired && errors.stakeAcquired
                        )}
                      />
                    </div>
                    {touched.stakeAcquired && errors.stakeAcquired && (
                      <div className="error text-[#d32f2f] text-xs ml-4">
                        {errors.stakeAcquired}
                      </div>
                    )}
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Industry Verticals</StyledFormLabel>
                    <CustomSelect
                      label="Industry Verticals"
                      name="industryVerticalOptions"
                      multiple={true}
                      value={values.industryVerticalOptions}
                      onChange={handleChange}
                      options={industryVerticalsOptions}
                      placeholder="Select Industry Verticals"
                      error={Boolean(
                        touched.industryVerticalOptions &&
                          errors.industryVerticalOptions
                      )}
                      helperText={
                        touched.industryVerticalOptions &&
                        errors.industryVerticalOptions
                      }
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>End Market Focus</StyledFormLabel>
                    <CustomSelect
                      label="End Market Focus"
                      name="endMarketFocus"
                      multiple={true}
                      value={values.endMarketFocus}
                      onChange={handleChange}
                      options={endMarketFocusOptions}
                      placeholder="Select End Market Focus"
                      error={Boolean(
                        touched.endMarketFocus && errors.endMarketFocus
                      )}
                      helperText={
                        touched.endMarketFocus && errors.endMarketFocus
                      }
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Asset Class</StyledFormLabel>
                    <CustomSelect
                      label="Asset Class"
                      name="assetClass"
                      multiple={true}
                      value={values.assetClass}
                      onChange={handleChange}
                      options={assetClassOptions}
                      placeholder="Select Asset Class"
                      error={Boolean(touched.assetClass && errors.assetClass)}
                      helperText={touched.assetClass && errors.assetClass}
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Stage of Investment</StyledFormLabel>
                    <CustomSelect
                      label="Stage of Investment"
                      name="investmentStage"
                      multiple={true}
                      value={values.investmentStage}
                      onChange={handleChange}
                      options={investmentStageOptions}
                      placeholder="Select Stage of Investment"
                      error={Boolean(
                        touched.investmentStage && errors.investmentStage
                      )}
                      helperText={
                        touched.investmentStage && errors.investmentStage
                      }
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Financial Criteria
                    </StyledFormLabelOptional>
                    <CustomSelect
                      placeholder="Select Financial Criteria"
                      name={`financialCriteria`}
                      multiple={true}
                      value={values.financialCriteria}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={financialCriteriaOptions}
                      error={Boolean(
                        touched.financialCriteria && errors.financialCriteria
                      )}
                      helperText={
                        touched.financialCriteria && errors.financialCriteria
                      }
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Co-Investments?</StyledFormLabel>
                    <div className="flex gap-2">
                      <div
                        className={`rounded-md py-2.5 w-16 text-center border border-[#8692a6] cursor-pointer ${
                          values?.coInvestment &&
                          "bg-[#dff0f8] text-[#00a6fb] border-[#00a6fb]"
                        }`}
                        onClick={() => setFieldValue(`coInvestment`, true)}
                      >
                        Yes
                      </div>
                      <div
                        className={`rounded-md py-2.5 w-16 text-center border border-[#8692a6] cursor-pointer ${
                          !values?.coInvestment &&
                          "bg-[#dff0f8] text-[#00a6fb] border-[#00a6fb]"
                        }`}
                        onClick={() => setFieldValue(`coInvestment`, false)}
                      >
                        No
                      </div>
                    </div>
                  </StyledFormControl>
                  <StyledFormControl
                    fullWidth
                    sx={{
                      gridColumn: "1/-2",
                    }}
                  >
                    <StyledFormLabelOptional>
                      Other Criteria
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter Other Criteria"
                      name={`otherCriteria`}
                      value={values.otherCriteria}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.otherCriteria && errors.otherCriteria
                      )}
                      helperText={touched.otherCriteria && errors.otherCriteria}
                      multiline
                      minRows={4}
                    />
                  </StyledFormControl>
                </div>
              </div>
            </div>
            <div className="showInterestButtons-div">
              <div className="btns-insideContent">
                <CustomBlackBtn type="submit" text="Save" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="editCompanyDetails fundrev-card mb-20">
        <p className="fundrev-card-heading mb-[16px]">
          Past Investment Details
        </p>
        <div
          className="editCompanyDetails-form"
          style={{
            gridTemplateColumns: "none",
          }}
        >
          <div>
            <Box
              sx={{
                height: `min-content`,
                width: `max-content`, // Fixed width
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                disableColumnMenu={true}
                disableColumnResize={true}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                hideFooter
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
