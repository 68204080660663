import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Steps from "./steps";
import CustomSnackBar from "../../../Common/components/custom/customSnackBar";

import DealDetails from "./Steps/dealDetails";
import DealInterest from "./Steps/dealInterest";
import ExpectedValuation from "./Steps/expectedValuation";
import DealPurpose from "./Steps/dealPurpose";
import PitchCreation from "./Steps/pitchCreation";
import AnalyticsPreparation from "./Steps/analyticsPreperation";
import CalenderSetup from "./Steps/calenderSteup";
import DataroomPreparation from "./Steps/dataroomPreperation";
import TermsAndConditions from "./Steps/TnC";
import ApplicationUnderReview from "./Steps/applicationUnderReview";

import DealDetailsSuggested from "./suggested/dealDetailsSuggested";
import DealInterestSuggested from "./suggested/dealInterestSuggested";
import ExpectedValuationSuggested from "./suggested/expectedValuationSuggested";
import DealPurposeSuggested from "./suggested/dealPurposeSuggested";
import PitchCreationSuggested from "./suggested/pitchCreationSuggested";
import AnalyticsPreparationSuggested from "./suggested/analyticsPreparationSuggested";
import CalenderSetupSuggested from "./suggested/calenderSetupSuggested";
import DataroomPreparationSuggested from "./suggested/dataroomPreparationSuggested";
import TermsAndConditionsSuggested from "./suggested/TermsAndConditionsSuggested";
import ApplicationUnderReviewSuggested from "./suggested/applicatiounUnderReviewSuggested";

import {
  fetchStartupDetails,
  PostDetails,
  processDealTnC,
} from "../../../../endpoints/startup";
import {
  updateDeal,
  getDealIdbyStartupId,
  getDealDetailsByDealId,
} from "../../../../endpoints/deal";
import { useLoader } from "../../../Common/LoaderProvider";
import { getUnfilledStepIndices } from "./stepCompletion";
import Toast from "../../../Common/Toast";

import * as Yup from "yup";
import { Formik } from "formik";
import "../../../../CSS/FounderPage/Dashboard/companyOnboarding.css";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const CompanyOnboarding = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [isReviewedByCompany, setIsReviewedByCompany] = useState(
    location.state?.isReviewedByCompany || false
  );
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const google = searchParams.get("google");
  const microsoft = searchParams.get("microsoft");
  const [tncChecked, setTncChecked] = useState(false);
  const loader = useLoader();
  //For file upload
  const [isUploading, setIsUploading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [dealId, setDealId] = useState("");
  const [startupId, setStartupId] = useState("");
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [onBoardingComplete, setOnBoardingComplete] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [availableTimeStart, setAvailableTimeStart] = useState([]);
  const [availableTimeEnd, setAvailableTimeEnd] = useState([]);
  //dealType required separately to shift user to last step if the user has completed onboarding
  const [dealType, setDealType] = useState("");

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Standard message (some browsers may not display the custom message)
  //     const message =
  //       "Are you sure you want to leave? Your changes may not be saved.";
  //     event.returnValue = message; // Standard for most browsers
  //     return message; // Required for some older browsers
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  //*Also add same fields in completeDealDetails.jsx file, stepCompletion.js
  const [initialValues, setInitialValues] = useState({
    //deal details first stage
    dealType: "",
    timeline: null,
    fundingRequired: "",
    debtFundingRequired: "", // Only visible when the user has chosen equity + debt option
    roundType: "",
    debtType: "",
    term: "",
    preferredInvestors: [],

    //Expected Valuation second stage
    equityType: "",
    pricedEquityType: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    nonPricedEquityType: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    //Deal Interest third stage
    softCommitments: false,
    fundingRaised: 0,
    debtRaised: "", // Only visible when the user has chosen equity + debt option
    // leadInvestor: "",
    coInvestors: "",
    contactedInvestorNames: "",

    //purposeOfFundraising of fundraising fourth step
    purposeOfFundraising: "",

    //Pitch Creation fifth stage
    pitchDeck: [],
    videoPitch: "",

    //Meeting Calender setup
    availableDays: ["M1", "T2", "W3", "T4", "F5"],
    availableTimeStart: dayjs().set("hour", 10).set("minute", 0),
    availableTimeEnd: dayjs().set("hour", 18).set("minute", 0),
    googleAuthToken: false,
    microsoftAuthToken: false,

    //Analytics preparation seventh stage
    financialDocuments: [],
    invoiceDetails: [],
    bankStatements: [],

    //Dataroom preparation eighth stage
    corporateDocuments: [],
    teamDetails: [],
    productTechnology: [],
    marketClientInfo: [],
    legalComplianceDetails: [],
    financialPlanning: [],

    //Terms and conditions ninth step
    acceptTC: false,

    pitchDeckFiles: "",
    videoPitchFiles: "",
    financialDocumentsFiles: "",
    invoiceDetailsFiles: "",
    bankStatementsFiles: "",

    corporateDocumentsFiles: "",
    teamDetailsFiles: "",
    productTechnologyFiles: "",
    marketClientInfoFiles: "",
    legalComplianceDetailsFiles: "",
    financialPlanningFiles: "",

    deletedFileNames: "",
    isApplicationReviewed: "",
    profileCompleted: "",
  });
  const validationSchema = Yup.object({
    dealType: Yup.string().required("Deal Type is required"),
    contactedInvestorNames: Yup.string().required(
      "Contacted investor names required"
    ),
    softCommitments: Yup.boolean().required("Selection is required"),
    fundingRequired: Yup.number()
      .required("Funding required field is required")
      .positive("Funding required must be a positive number"),
    fundingRaised: Yup.number()
      .required("Amount already raised is required")
      .min(0, "Amount already raised must be 0 or a positive number")
      .max(
        Yup.ref("fundingRequired"),
        "Amount raised must be less than funding required"
      ),
  });

  const [schema, setSchema] = React.useState(validationSchema);
  // Function to clear query parameters
  const clearParams = () => {
    navigate("/company/onBoarding", { replace: true });
  };
  useEffect(() => {
    if (step) {
      if (dealType === "Equity" || dealType === "Equity + Debt") {
        setActiveStep(Number(step) + 1);
      } else {
        setActiveStep(Number(step));
      }
    }
    clearParams();
  }, [step]);
  //In select field if initial value is not specified the placeholder do not appear.
  const fetchDetails = async () => {
    if (!token) return;
    try {
      //Remove loader as it will be shown again and again after each form get submits.
      // loader.start();
      const response = await fetchStartupDetails(token);
      // loader.stop();
      if (response?.data !== null) {
        const data = response.data.data;
        setInitialValues((prev) => ({
          ...prev,

          //Meeting Calender setup
          availableDays:
            data?.availableDays?.length > 0
              ? data.availableDays
              : ["M1", "T2", "W3", "T4", "F5"],
          availableTimeStart: data.availableTimeStart
            ? dayjs(data.availableTimeStart)
            : dayjs().set("hour", 10).set("minute", 0),
          availableTimeEnd: data.availableTimeEnd
            ? dayjs(data.availableTimeEnd)
            : dayjs().set("hour", 18).set("minute", 0),
          googleAuthToken: data.googleAuthToken || google === "success",
          microsoftAuthToken:
            data.microsoftAuthToken || microsoft === "success",

          pitchDeck: data.pitchDeck || [],
          videoPitch: data.videoPitch || "",
          // Analytics Preparation - Sixth Stage
          financialDocuments: data.financialDocuments || [],
          invoiceDetails: data.invoiceDetails || [],
          bankStatements: data.bankStatements || [],

          // Data Room Preparation - Seventh Stage
          corporateDocuments: data.corporateDocuments || [],
          teamDetails: data.teamDetails || [],
          productTechnology: data.productTechnology || [],
          marketClientInfo: data.marketClientInfo || [],
          legalComplianceDetails: data.legalComplianceDetails || [],
          financialPlanning: data.financialPlanning || [],

          //Submit for review
          onBoardingComplete: data.onBoardingComplete || false,
          isApplicationReviewed: data.isApplicationReviewed || false,
        }));
        setAvailableDays(data.availableDays);
        setAvailableTimeStart(data.availableTimeStart);
        setAvailableTimeEnd(data.availableTimeEnd);
        setProfileCompleted(data.profileCompleted);
        setIsApproved(data.isApproved);
        setStartupId(data.startupId);
        setOnBoardingComplete(data.onBoardingComplete);
      }
    } catch (error) {
      loader.stop();
      console.error(error);
    }
  };
  const getDealId = async () => {
    if (!startupId) return;
    try {
      const response = await getDealIdbyStartupId(startupId, token);
      if (response) setDealId(response.data.dealId);
    } catch (error) {}
  };
  async function fetchDealDetails() {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      if (!response?.data) {
        Toast("failed to fetch details", "error", "companyOnBoarding");
        return;
      }
      const data = response?.data;
      setInitialValues((prev) => ({
        ...prev,
        // Deal details first stage
        dealType: data.dealType || "",
        timeline: data.timeline || null,
        fundingRequired: data.fundingRequired || "",
        debtFundingRequired: data.debtFundingRequired || "", // Visible only for equity + debt option
        roundType: data.roundType || "",
        debtType: data.debtType || "",
        term: data.term || "",
        preferredInvestors: data.preferredInvestors || [],

        // Expected Valuation second stage
        equityType: data.equityType || "",
        pricedEquityType: data.pricedEquityType || "",
        stakeOffered: data.stakeOffered || "",
        minPostMoneyValuation: data.minPostMoneyValuation || "",
        nonPricedEquityType: data.nonPricedEquityType || "",
        interestRate: data.interestRate || "",
        valuationCap: data.valuationCap || "",
        discountRate: data.discountRate || "",

        // Deal Interest third stage
        softCommitments: data.softCommitments || false,
        fundingRaised: data.fundingRaised || 0,
        debtRaised: data.debtRaised || "", // Visible only for equity + debt option
        // leadInvestor: data.leadInvestor || "",
        coInvestors: data.coInvestors || "",
        contactedInvestorNames: data.contactedInvestorNames || "",

        // purposeOfFundraising of fundraising fourth step
        purposeOfFundraising: data.purposeOfFundraising || "",
      }));
      setDealType(data.dealType);
    } catch (error) {}
  }
  useEffect(() => {
    fetchDetails();
  }, [token]);
  useEffect(() => {
    if (startupId) getDealId();
  }, [startupId]);
  useEffect(() => {
    if (dealId) fetchDealDetails();
  }, [dealId]);
  const fileNames = [
    "pitchDeckFiles",
    "videoPitchFiles",

    "financialDocumentsFiles",
    "invoiceDetailsFiles",
    "bankStatementsFiles",

    "corporateDocumentsFiles",
    "teamDetailsFiles",
    "productTechnologyFiles",
    "marketClientInfoFiles",
    "legalComplianceDetailsFiles",
    "financialPlanningFiles",
  ];

  const fileDictionary = {
    pitchDeckFiles: "pitchDeck",
    videoPitchFiles: "videoPitch",

    financialDocumentsFiles: "financialDocuments",
    invoiceDetailsFiles: "invoiceDetails",
    bankStatementsFiles: "bankStatements",

    corporateDocumentsFiles: "corporateDocuments",
    teamDetailsFiles: "teamDetails",
    productTechnologyFiles: "productTechnology",
    marketClientInfoFiles: "marketClientInfo",
    legalComplianceDetailsFiles: "legalComplianceDetails",
    financialPlanningFiles: "financialPlanning",
  };
  function editedAvailableTime(data) {
    // Compare availableDays arrays
    const areDaysDifferent =
      data.availableDays?.length !== availableDays?.length ||
      !data.availableDays?.every((day, index) => day === availableDays[index]);

    // Normalize and compare times
    const isTimeStartDifferent = !dayjs(data.availableTimeStart).isSame(
      dayjs(availableTimeStart)
    );
    const isTimeEndDifferent = !dayjs(data.availableTimeEnd).isSame(
      dayjs(availableTimeEnd)
    );
    // Return true if any of the conditions differ
    if (
      areDaysDifferent ||
      isTimeStartDifferent ||
      isTimeEndDifferent ||
      data.onBoardingComplete
    ) {
      return true;
    }
    return false;
  }

  const handleAcceptTNC = async (setFieldValue) => {
    if (tncChecked) {
      if (dealId && startupId && token) {
        loader.start();
        const response = await processDealTnC(startupId, dealId, token);
        loader.stop();
        if (response) setFieldValue("acceptTC", true);
        else
          Toast(
            `Something went wrong, please try again`,
            `error`,
            `companyOnboarding`
          );
      } else {
        Toast(
          "Failed to accept T&C and MSA, please try again",
          "error",
          "companyOnboarding"
        );
      }
    } else {
      Toast(
        "Please accept T&C and MSA before proceeding",
        "error",
        "companyOnboarding"
      );
    }
  };

  function containsFile(data, fileNames) {
    if (Object.keys(data.deletedFileNames).length > 0) {
      return true;
    }
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (fileNames.includes(key)) {
          const value = data[key];
          if (Array.isArray(value)) {
            // Check if any item in the array is a File
            if (value.some((item) => item instanceof File)) {
              return true;
            }
          } else if (value instanceof File) {
            // If the value itself is a File
            return true;
          }
        }
      }
    }
    return false; // No files found
  }
  const prepareFormData = (data, update) => {
    const formDataInput = new FormData();

    if (update) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key]) {
            if (fileNames.includes(key)) {
              data[key].forEach((item) => {
                if (item instanceof File) {
                  formDataInput.append(fileDictionary[key], item);
                }
              });
            }
          }
        }
      }
      formDataInput.append("availableDays", JSON.stringify(data.availableDays));
      formDataInput.append("availableTimeStart", data.availableTimeStart);
      formDataInput.append("availableTimeEnd", data.availableTimeEnd);
      formDataInput.append(
        "deletedFileNames",
        JSON.stringify(data.deletedFileNames)
      );
      formDataInput.append("acceptTC", data.acceptTC);
      formDataInput.append("onBoardingComplete", data.onBoardingComplete);
      if (!data.dealId) {
        formDataInput.append("dealId", dealId);
      }
      //Needed startupId for postDetails function
      if (!data.startupId) {
        formDataInput.append("startupId", startupId);
      }
      return formDataInput;
    } else {
      data.dealId = dealId;
      return data;
    }
  };
  const submitFormData = async (formData, update) => {
    try {
      if (update) {
        await PostDetails(formData, token);
      } else {
        await updateDeal(formData, token);
      }
    } catch (error) {
      loader.stop();
      // Handle error if needed
      console.error("Error submitting form data:", error);
    }
  };
  //To save details between steps without loader
  const postFormData = async (data) => {
    const documentsUpdate = containsFile(data, fileNames);
    const availableTimeUpdate = editedAvailableTime(data);
    const formDataInput = prepareFormData(
      data,
      documentsUpdate || availableTimeUpdate
    );
    await submitFormData(formDataInput, documentsUpdate || availableTimeUpdate);

    //We are needing to call this function because when if someone tries to update some fields while uploading the files then
    //we have manually edited that field, but as we are also calling fetchDetails function after file upload it will show the
    //previous value of manually updated the field. The field might be updated in the db but not here. Hence we need to call this
    //function to update the details.
    await fetchDetails();
    await fetchDealDetails();
  };
  useEffect(() => {
    if (onBoardingComplete) {
      if (dealType === "Equity + Debt" || dealType === "Equity") {
        setActiveStep(9);
      } else if (dealType === "Debt") {
        setActiveStep(8);
      } else {
        setActiveStep(8);
      }
    }
  }, [onBoardingComplete, dealType]);
  const handleStepChange = (values, direction) => {
    const arr = getUnfilledStepIndices(values);
    if (direction === "next") {
      if (
        (values.dealType === "Debt" && activeStep === 7) ||
        (values.dealType !== "Debt" && activeStep === 8)
      )
        if (arr.length > 0 && arr[0] !== 8) {
          if (values.dealType === "Debt" && activeStep > 1) {
            setActiveStep(arr[0]);
          } else {
            setActiveStep(arr[0] + 1);
          }
          Toast(
            "Please fill the required details",
            "error",
            "companyOnboarding"
          );
        } else {
          values.onBoardingComplete = true;
          postFormData(values);
          setActiveStep(activeStep + 1);
        }
      else {
        postFormData(values);
        if (activeStep) setActiveStep(activeStep + 1);
      }
    } else {
      postFormData(values);
      if (activeStep) setActiveStep(activeStep - 1);
    }
  };
  useEffect(() => {
    //It checks whether someone is trying to create a deal without completing profile or getting verified.
    //First condition to ensure that profileCompleted and isApproved has been updated through fetchDetails
    if (startupId) {
      if (profileCompleted === false || isApproved === false) {
        navigate("/company/dashboard");
      }
    }
  }, [profileCompleted, isApproved, startupId]);
  return (
    <div className="InvestorSide">
      <Helmet>
        <title>Fundrev | Company onboarding</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="companyOnboarding" />
      <Sidebar active="profile" />
      <Topbar title="Company Details" />
      <CustomSnackBar isUploading={isUploading} />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="companyOnboarding">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={postFormData}
              enableReinitialize={true} //Set this field when you are fetching data from an api to fill the form
            >
              {({
                handleChange: formikHandleChange,
                values,
                setFieldValue,
              }) => {
                const handleChange = (e) => {
                  formikHandleChange(e); // Let Formik handle the value change
                };
                const stepsComponents = [
                  <DealDetails />,

                  ...(values.dealType === "Equity" ||
                  values.dealType === "Equity + Debt"
                    ? [<ExpectedValuation />]
                    : []),
                  <DealInterest />,
                  <DealPurpose />,
                  <PitchCreation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  <CalenderSetup
                    startupId={startupId}
                    isUploading={isUploading}
                  />,
                  <AnalyticsPreparation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  <DataroomPreparation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  // <TermsAndConditions />,
                  <ApplicationUnderReview
                    startupId={startupId}
                    tncChecked={tncChecked}
                    setTncChecked={setTncChecked}
                    isReviewedByCompany={isReviewedByCompany}
                  />,
                ];
                const stepsComponentsSuggested = [
                  <DealDetailsSuggested />,
                  ...(values.dealType === "Equity" ||
                  values.dealType === "Equity + Debt"
                    ? [<ExpectedValuationSuggested />]
                    : []),
                  <DealInterestSuggested />,

                  <DealPurposeSuggested />,
                  <PitchCreationSuggested />,
                  <CalenderSetupSuggested />,
                  <AnalyticsPreparationSuggested />,
                  <DataroomPreparationSuggested />,
                  <></>,
                  // <TermsAndConditionsSuggested />,
                ];

                return (
                  <>
                    <Steps
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      values={values}
                      setSchema={setSchema}
                      validationSchema={validationSchema}
                    />
                    <div
                      className="companyOnboarding-form"
                      style={{
                        width:
                          dealType === "Equity + Debt" || dealType === "Equity"
                            ? activeStep === 9 && "80%"
                            : activeStep === 8 && "80%",
                      }}
                    >
                      {stepsComponents[activeStep - 1]}
                    </div>
                    {!values.onBoardingComplete && (
                      <div className="showInterestButtons-div">
                        <div className="btns-insideContent">
                          {activeStep > 1 && !values.onBoardingComplete && (
                            <div>
                              <CustomBlackBtn
                                mode="light"
                                filled={false}
                                text="Previous Step"
                                type="button"
                                onClick={() => handleStepChange(values, "prev")}
                              />
                            </div>
                          )}

                          <div>
                            <CustomBlackBtn
                              text={
                                values.dealType === "Debt"
                                  ? activeStep === 7
                                    ? "Submit"
                                    : "Save & Continue"
                                  : activeStep === 8
                                  ? "Submit"
                                  : "Save & Continue"
                              }
                              type="button"
                              onClick={() => handleStepChange(values, "next")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {values.onBoardingComplete &&
                      values.isApplicationReviewed &&
                      !values.acceptTC && (
                        <>
                          <div className="showInterestButtons-div">
                            <div className="btns-insideContent">
                              <CustomBlackBtn
                                text="Confirm"
                                type="button"
                                onClick={(e) => handleAcceptTNC(setFieldValue)}
                              />
                            </div>
                          </div>
                          {<TermsAndConditionsSuggested />}
                        </>
                      )}
                    {stepsComponentsSuggested[activeStep - 1]}
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOnboarding;
