import { useState, useEffect } from "react";
import AskQuestionModal from "../../Common/modals/askQuestionModal";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";

import { getqnAs } from "../../../endpoints/qnA";

import noQuestions from "../../../Assets/Images/FounderPage/Dashboard/noQuestions.svg";
import circularPhoneIconGrey from "../../../Assets/Images/InvestorPage/Dashboard/circularPhoneIconGrey.svg";
import circularMailIconGrey from "../../../Assets/Images/InvestorPage/Dashboard/circularMailIconGrey.svg";
import circularPhoneIconWhite from "../../../Assets/Images/InvestorPage/Dashboard/circularPhoneInconWhite.svg";
import circularMailIconWhite from "../../../Assets/Images/InvestorPage/Dashboard/circularMailIconWhite.svg";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

function DealAnalystDetails({ user, startupId, fundrevAnalystID }) {
  const [qnaModal, setqnaModal] = useState(false);
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const [qnAs, setqnAs] = useState([]);
  const dealAnalystDivStyle =
    user === "company"
      ? { width: "90%", margin: "auto", backgroundColor: "#2e4b5b" }
      : {};
  const needHelpStyle = user === "company" ? { color: "#fff" } : {};
  const reachOutStyle = user === "company" ? { color: "#cacaca" } : {};
  const fundrevAnalystNameStyle =
    user === "company"
      ? { color: "#5efca1", fontWeight: "500" }
      : { fontWeight: "500", color: "rgba(73,73,73,1)" };
  const fundrevAnalystStyle =
    user === "company"
      ? { color: "#5efca1", fontWeight: "500" }
      : { fontWeight: "500", color: "rgba(73,73,73,1)", fontSize: "8px" };
  const phoneNumberStyle = user === "company" ? { color: "#ced2d3" } : {};

  const addOrUpdateQnAs = (qnAData) => {
    const qnAMap = new Map(qnAs.map((qnA) => [qnA.qnAID, qnA]));

    qnAData.forEach((qnA) => {
      qnAMap.set(qnA.qnAID, qnA);
    });

    setqnAs(Array.from(qnAMap.values()));
  };
  const getqnAdata = async () => {
    if (fundrevAnalystID && startupId) {
      try {
        const response = await getqnAs({
          fundrevAnalyst: fundrevAnalystID,
          startupId: startupId,
        });
        const qnAData = response.data;
        addOrUpdateQnAs(qnAData);
        setNewQuestionAsked(false);
      } catch (err) {}
    } else {
      return;
    }
  };
  function handleQnAModalOpen() {
    getqnAdata();
    setqnaModal(true);
  }
  useEffect(() => {
    getqnAdata();
  }, []);
  useEffect(() => {
    if (newQuestionAsked) getqnAdata();
  }, [newQuestionAsked]);

  return (
    <>
      <AskQuestionModal
        askQuestionModal={askQuestionModal}
        setAskQuestionModal={setAskQuestionModal}
        setNewQuestionAsked={setNewQuestionAsked}
        fundrevAnalystID={fundrevAnalystID}
        startupId={startupId}
        type="analystQuestion"
      />
      <Modal
        className="ask-question-modal"
        style={{ padding: "20px 30px" }}
        open={qnaModal}
        onClose={() => setqnaModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box question-box dealAnalystInteraction"
          style={{ width: "700px", maxHeight: "500px" }}
        >
          <div
            className="reason-box-first-div"
            style={{
              alignItems: "center",
            }}
          >
            <p className="qna-section-text">View Q & As</p>
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setqnaModal(false)}
              alt="close"
            />
          </div>
          <div className="qna-center">
            {qnAs.length > 0 ? (
              qnAs.map((qnA) => {
                return (
                  <div className="QnA" style={{ marginBottom: "30px" }}>
                    <div className="QnA-question">
                      <span className="blue-text">Q.</span>&nbsp; {qnA.question}
                    </div>
                    {qnA.answer && (
                      <div className="QnA-answer">
                        <span className="blue-text">A.</span> {qnA.answer}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#696F79",
                    margin: "0px 0px 0px 30px",
                  }}
                >
                  Currently, there are no questions or answers posted
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <img src={noQuestions} alt="No questions" />
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <div className="dealAnalystDetails-div" style={dealAnalystDivStyle}>
        <div className="dealAnalystDetails-div-2" style={needHelpStyle}>
          Need help?
        </div>
        <div className="dealAnalystDetails-div-3" style={reachOutStyle}>
          Reach out to{" "}
          <span style={fundrevAnalystNameStyle}>Dhiraj Sharma </span>
          <span style={fundrevAnalystStyle}>(Fundrev Analyst) </span>
          <span style={reachOutStyle}>on</span>:
        </div>
        <div className="dealAnalystDetails-div-4">
          <img
            loading="lazy"
            src={
              user === "company"
                ? circularPhoneIconWhite
                : circularPhoneIconGrey
            }
            alt="phone"
            className="dealAnalystDetails-img"
          />
          <a href="tel:81000199003" className="dealAnalystDetails-div-5">
            <div style={phoneNumberStyle}>+91 810001 99003</div>
          </a>
        </div>
        <div className="dealAnalystDetails-div-6">
          <img
            loading="lazy"
            src={
              user === "company" ? circularMailIconWhite : circularMailIconGrey
            }
            alt="mail"
            className="dealAnalystDetails-img"
          />
          <a href="mailto:dhiraj@fundrev.ai">
            <div className="dealAnalystDetails-div-7">dhiraj@fundrev.ai</div>
          </a>
        </div>
        <button
          className="dealAnalystDetails-div-8"
          onClick={() => handleQnAModalOpen()}
          style={{
            backgroundColor: "transparent",
            color: "#00A6FB",
            border: "1px solid #00A6FB",
          }}
        >
          View Q & As
        </button>
        <button
          className="dealAnalystDetails-div-8"
          onClick={() => setAskQuestionModal(true)}
        >
          Ask A Question
        </button>
      </div>
      <style jsx>{`
        .dealAnalystDetails-div {
          border-radius: 10px;
          background-color: #e5f5fe;
          display: flex;
          max-width: 306px;
          flex-direction: column;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          padding: 18px 19px;
        }
        @media all and (max-width: 991px) {
          .dealAnalystDetails-div {
            display: none;
          }
        }
        .dealAnalystDetails-div-2 {
          color: #000;
          font: 700 14px/140% Karla, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .dealAnalystDetails-div-3 {
          color: #494949;
          font-family: Karla, sans-serif;
          margin-top: 7px;
        }
        .dealAnalystDetails-div-4 {
          display: flex;
          margin-top: 14px;
          gap: 10px;
          color: #494949;
        }
        .dealAnalystDetails-img {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 20px;
        }
        .dealAnalystDetails-div-5 {
          font-family: Karla, sans-serif;
          margin: auto 0;
          text-decoration-line: none;
          color: #494949;
        }
        .dealAnalystDetails-div-6 {
          display: flex;
          margin-top: 5px;
          gap: 10px;
          color: #00a6fb;
          white-space: nowrap;
        }
        .dealAnalystDetails-div-7 {
          font-family: Karla, sans-serif;
          text-decoration-line: underline;
          margin: auto 0;
          color: #00a6fb !important;
        }
        .dealAnalystDetails-div-8 {
          font-family: Karla, sans-serif;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background-color: #00a6fb;
          margin-top: 11px;
          color: #fff;
          font-weight: 700;
          text-align: center;
          padding: 9px 8px;
          border: 0px;
          cursor: pointer;
        }
        .companyDetails-left-section {
          display: flex;
          flex-direction: column;
          width: 27%;
          gap: 20px;
          margin-bottom: 20px;
        }
        .dealAnalystInteraction .QnA {
          border: 1px solid #e0dac9;
          border-radius: 10px;
          width: 100%;
          padding: 0px 10px 20px 20px;
        }
        .dealAnalystInteraction .QnA-answer {
          border-top: 1px dashed #e0dac9;
          padding-top: 10px;
        }
        .dealAnalystInteraction {
          padding: 20px 30px;
        }
        .dealAnalystInteraction .reason-box-first-div {
          margin-bottom: 20px;
        }
      `}</style>
    </>
  );
}

export default DealAnalystDetails;
