import React, { useEffect } from "react";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import CashFlowStatementTable from "./AnalyticsPlots/CashFlowStatementTable";
import CashFlowSmallTable from "./AnalyticsPlots/CashFlowSmallTable";
import BalanceSheetTable from "./AnalyticsPlots/BalanceSheetTable";
import BalanceSheetSummaryTable from "./AnalyticsPlots/BalanceSheetSummaryTable";
import { Box, Grid } from "@mui/material";
import { dumpInvestorInteraction } from "../../../../../endpoints/common";
import BankStatementPlot from "./AnalyticsPlots/bankStatementPlot";

const HistoricalFinancials = ({ data, deal, stage }) => {
  const token = localStorage.getItem("token");
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "historicalFinancials",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <>
      <AnalyticsPageTitle title={`Historical Financials`} />
      <Box>
        {!isEmpty(data?.plots?.bankStatement?.data) && (
          <BankStatementPlot data={data?.plots?.bankStatement} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <BalanceSheetTable data={data?.tables?.balanceSheet} />
        <CashFlowStatementTable data={data?.tables?.cashFlowStatement} />
        <Grid container spacing={1} marginBottom={3} sx={{ width: "101%" }}>
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                borderRadius: "4px",
                padding: "16px",
                fontFamily: "Karla, sans-serif",
                height: "336px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {!isEmpty(data?.tables?.balanceSheet?.data) && (
                <BalanceSheetSummaryTable data={data?.tables?.balanceSheet} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                borderRadius: "4px",
                padding: "16px",
                fontFamily: "Karla, sans-serif",
                height: "336px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {!isEmpty(data?.tables?.cashFlowStatement?.data) && (
                <CashFlowSmallTable data={data?.tables?.cashFlowStatement} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HistoricalFinancials;
