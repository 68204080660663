import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useLoader } from "../../../../Common/LoaderProvider";
import { getAnalyticsData } from "../../../../../endpoints/common";

import StayTuned from "../../../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";
import AnalyticsSummary from "./AnalyticsSummary";
import AnalyticsFinancialPerformance from "./AnalyticsFinancialPerformance";
import AnalyticsCustomerAnalytics from "./AnalyticsCustomerAnalytics";

const AnalyticsHomble = ({ deal, analytics, setAnalytics }) => {
  const token = localStorage.getItem("token");
  const companyName = deal ? deal.companyName : "";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(null);
  useEffect(() => {
    loader.start();
    getAnalytics();
  }, []);
  const getAnalytics = async () => {
    try {
      const response = await getAnalyticsData(deal.dealId);
      setAnalyticsData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };
  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <p
              style={{
                font: "Karla, sans-serif",
                fontSize: "20px",
                marginBottom: "40px",
              }}
            >
              No analytics available at the moment. Data is being processed.
              Please try again soon.
            </p>
            <img src={StayTuned} style={{ width: "500px" }} />
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <>
      {analytics === "summary" && analyticsData?.summary && (
        <AnalyticsSummary
          setAnalytics={setAnalytics}
          data={analyticsData.summary}
        />
      )}
      {analytics === "businessAnalytics" &&
        analyticsData?.businessAnalytics && (
          <AnalyticsFinancialPerformance
            data={analyticsData.businessAnalytics}
          />
        )}
      {analytics === "salesAnalytics" && analyticsData?.salesAnalytics && (
        <AnalyticsCustomerAnalytics data={analyticsData.salesAnalytics} />
      )}
    </>
  );
};

export default AnalyticsHomble;
