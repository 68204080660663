import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { industryVerticalsShort } from "../functions";
import { useNavigate, useLocation } from "react-router-dom"; // Import from react-router-dom
import CheckboxGroup from "./molecules/checkBoxGroup";
import { Slide } from "@mui/material";

const DealsFilterBox = ({
  deals,
  setFilteredDeals,
  activeBtn,
  filterBoxOpen,
  shouldUpdateUrl,
}) => {
  const [display, setDisplay] = useState("flex");
  const [endMarketFocus, setEndMarketFocus] = useState([]);
  const [industryVerticals, setIndustryVerticals] = useState([]);
  const [roundTypes, setRoundTypes] = useState([]);
  const [selectedEndMarketFocus, setSelectedEndMarketFocus] = useState([]);
  const [selectedIndustryVerticals, setSelectedIndustryVerticals] = useState(
    []
  );
  const [selectedRoundTypes, setSelectedRoundTypes] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  function clearAllFilters() {
    setSelectedEndMarketFocus([]);
    setSelectedIndustryVerticals([]);
    setSelectedRoundTypes([]);
    if (shouldUpdateUrl) {
      updateUrlParams([]);
    }
  }

  function getAllOptions(deals) {
    updateOptions(
      deals,
      "endMarket",
      setEndMarketFocus,
      setSelectedEndMarketFocus
    );
    updateOptions(
      deals,
      "industryVerticals",
      setIndustryVerticals,
      setSelectedIndustryVerticals
    );
    updateOptions(deals, "roundType", setRoundTypes, setSelectedRoundTypes);
  }

  function updateUrlParams(selectedFilters) {
    if (shouldUpdateUrl) {
      const params = new URLSearchParams();
      if (selectedFilters.selectedEndMarketFocus?.length > 0) {
        params.append(
          "endMarketFocus",
          selectedFilters.selectedEndMarketFocus.join(",")
        );
      }
      if (selectedFilters.selectedIndustryVerticals?.length > 0) {
        params.append(
          "industryVerticals",
          selectedFilters.selectedIndustryVerticals.join(",")
        );
      }
      if (selectedFilters.selectedRoundTypes?.length > 0) {
        params.append(
          "roundTypes",
          selectedFilters.selectedRoundTypes.join(",")
        );
      }
      navigate({ pathname: location.pathname, search: params.toString() });
    }
  }

  useEffect(() => {
    setRoundTypes([]);
    setEndMarketFocus([]);
    setIndustryVerticals([]);
    setSelectedEndMarketFocus([]);
    setSelectedIndustryVerticals([]);
    setSelectedRoundTypes([]);
    getAllOptions(deals);

    if (shouldUpdateUrl) {
      const params = new URLSearchParams(location.search);
      if (params.size > 0 && !activeBtn) {
        const endMarketFocusFromUrl = params.get("endMarketFocus")
          ? params.get("endMarketFocus").split(",")
          : params.get("emf")
          ? params.get("emf").split(",")
          : [];
        const industryVerticalsFromUrl = params.get("industryVerticals")
          ? params
              .get("industryVerticals")
              .split(",")
              .map(
                (shortForm) => industryVerticalsShort[shortForm] || shortForm
              )
          : params.get("inv")
          ? params
              .get("inv")
              .split(",")
              .map(
                (shortForm) => industryVerticalsShort[shortForm] || shortForm
              )
          : [];
        const roundTypesFromUrl = params.get("roundTypes")
          ? params.get("roundTypes").split(",")
          : params.get("rt")
          ? params.get("rt").split(",")
          : [];
        setSelectedEndMarketFocus(endMarketFocusFromUrl);
        setSelectedIndustryVerticals(industryVerticalsFromUrl);
        setSelectedRoundTypes(roundTypesFromUrl);
      }
    }
  }, [activeBtn, deals, shouldUpdateUrl]);

  function updateOptions(deals, fieldName, setState, setSelectedState) {
    deals?.forEach((deal) => {
      try {
        let parsedField;
        if (fieldName === "roundType") {
          parsedField = deal?.[fieldName] ? [deal[fieldName]] : [];
        } else {
          parsedField = deal[fieldName];
        }
        const fieldArray = Array.isArray(parsedField) ? parsedField : [];
        setState((prev) => [...new Set([...prev, ...fieldArray])]);
        setSelectedState((prev) => [...new Set([...prev, ...fieldArray])]);
      } catch (error) {
        console.error("Error parsing deal data:", error);
      }
    });
  }

  useEffect(() => {
    const filterDeals = () => {
      const filtered = deals?.filter((deal) => {
        try {
          const roundType = [deal?.roundType];
          const industryVerticals = deal?.industryVerticals;
          const endMarketFocus = deal?.endMarket;
          let roundTypeMatches = roundType.some(
            (type) =>
              selectedRoundTypes.length === 0 ||
              selectedRoundTypes.includes(type)
          );
          const industryVerticalsMatches = industryVerticals.some(
            (vertical) =>
              selectedIndustryVerticals.length === 0 ||
              selectedIndustryVerticals.includes(vertical)
          );
          const endMarketFocusMatches = endMarketFocus.some(
            (focus) =>
              selectedEndMarketFocus.length === 0 ||
              selectedEndMarketFocus.includes(focus)
          );
          if (deal?.dealType === "secondary") roundTypeMatches = true;

          return (
            (selectedEndMarketFocus.length > 0 ||
              selectedIndustryVerticals.length > 0 ||
              selectedRoundTypes.length > 0) &&
            roundTypeMatches &&
            industryVerticalsMatches &&
            endMarketFocusMatches
          );
        } catch (error) {
          console.error("Error parsing deal data:", error);
          return false;
        }
      });
      setFilteredDeals(filtered);

      if (shouldUpdateUrl) {
        updateUrlParams({
          selectedEndMarketFocus,
          selectedIndustryVerticals,
          selectedRoundTypes,
        });
      }
    };

    filterDeals();
  }, [
    deals,
    selectedRoundTypes,
    selectedIndustryVerticals,
    selectedEndMarketFocus,
    shouldUpdateUrl,
  ]);

  useEffect(() => {
    setDisplay(filterBoxOpen ? "flex" : "none");
  }, [filterBoxOpen]);

  return (
    // shouldUpdateUrl is true for openListing page where we want to change the link based on the params choosed by the investor and also we need some marginTop for that page
    <Slide direction="left" in={filterBoxOpen} mountOnEnter unmountOnExit>
      <div
        className="deals-filter-box"
        style={{ marginTop: shouldUpdateUrl ? "40px" : "20px" }}
      >
        <div className="deals-filter-box-header">
          <p>Filters</p>
          <Button className="clear-filters" onClick={clearAllFilters}>
            Clear All
          </Button>
        </div>
        <CheckboxGroup
          title="Industry Vertical"
          options={industryVerticals}
          selectedOptions={selectedIndustryVerticals}
          setSelectedOptions={setSelectedIndustryVerticals}
        />

        <CheckboxGroup
          title="End Market Focus"
          options={endMarketFocus}
          selectedOptions={selectedEndMarketFocus}
          setSelectedOptions={setSelectedEndMarketFocus}
          className="noNameDeal-EndMarketFocus"
        />
        <CheckboxGroup
          title="Round Type"
          options={roundTypes}
          selectedOptions={selectedRoundTypes}
          setSelectedOptions={setSelectedRoundTypes}
        />
      </div>
    </Slide>
  );
};

export default DealsFilterBox;
