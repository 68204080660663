import React, { useEffect, useState } from "react";
import { calculateProfileCompletion } from "../onBoarding/stepCompletion";

import moment from "moment";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";

const DealUnderReview = ({ deal }) => {
  const Navigate = useNavigate();
  const [profileRemainingPercentage, setProfileRemainingPercentage] =
    useState(0);
  function returnRoundType(roundType) {
    try {
      const arr = JSON.parse(roundType);
      return arr[0];
    } catch {
      return "Debt";
    }
  }
  useEffect(() => {
    setProfileRemainingPercentage(100 % -calculateProfileCompletion(deal));
  }, [deal]);
  return (
    <div className="dashBoard-state-card">
      <div className="inner-content-div">
        <div className="middle-div">
          <p className="roundType-text">{returnRoundType(deal.roundType)}</p>
          <p className="roundSize-text">
            <span>Round Size:</span> ₹{deal.fundingRequired}Cr
          </p>
          {deal.timeline && (
            <p className="roundSize-text">
              <span>End Date:</span>{" "}
              {moment(deal.timeline).format("D MMM YYYY")}
            </p>
          )}
        </div>
        <div className="button-div">
          <CustomBlackBtn
            text="Deal Under Review"
            filled={false}
            border="sm"
            variant="sm"
            disabled={true}
            width="100%"
            onClick={() => Navigate("/company/onboarding")}
          />
        </div>
      </div>
    </div>
  );
};

export default DealUnderReview;
