import React, { useState, useRef } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MSAAgreement from "./MSAAgreement";
import TermsOfUse from "./TermsOfUse";
import { Checkbox } from "@mui/material";
import { useFormikContext } from "formik";
import { toggleButtonStyles } from "../../../../Common/functions";
const TermsAndConditions = ({ tncChecked, setTncChecked }) => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();
  const [cardType, setCardType] = useState("MSA");
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  const termsRefs = {
    agreementRef: useRef(null),
    definitionsRef: useRef(null),
    scopeOfServicesRef: useRef(null),
    transactionFeeRef: useRef(null),
    fundraiserResponsibilitiesRef: useRef(null),
    intellectualPropertyRef: useRef(null),
    confidentialityRef: useRef(null),
    nonSolicitationRef: useRef(null),
    warrantiesRef: useRef(null),
    limitationOfLiabilityRef: useRef(null),
    indemnificationRef: useRef(null),
    termAndTerminationRef: useRef(null),
    disputeResolutionRef: useRef(null),
    dataProtectionRef: useRef(null),
    miscellaneousRef: useRef(null),
    saleOfCompany: useRef(null),
    mandatoryDisclosure: useRef(null),
    bindingNature: useRef(null),
  };

  return (
    <section className="TermsnConditions onboardingForm-card companies-TermsAndUse">
      <p className="heading">Terms & Conditions</p>
      <p className="subheading">
        Enter your pitch deck and video pitch to explain your business
      </p>
      <ToggleButtonGroup
        color="primary"
        value={cardType}
        exclusive
        onChange={handleCardTypeChange}
        aria-label="card-type"
        sx={{
          backgroundColor: "#D2DCFF",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "fit-content",
        }}
      >
        <ToggleButton value="MSA" sx={toggleButtonStyles}>
          Master Service Agreement
        </ToggleButton>
        <ToggleButton value="TnC" sx={toggleButtonStyles}>
          Terms of Use
        </ToggleButton>
      </ToggleButtonGroup>

      {cardType === "MSA" ? (
        <MSAAgreement setCardType={setCardType} termsRefs={termsRefs} />
      ) : (
        <TermsOfUse termsRefs={termsRefs} />
      )}
      <div className="tnc-text-block">
        <Checkbox
          className="checkbox"
          aria-label="Checkbox"
          sx={{
            color: "#00a6fb",
            "&.Mui-checked": {
              color: "#00a6fb",
            },
          }}
          checked={tncChecked}
          onChange={(event) => setTncChecked(event.target.checked)}
        />

        <p>
          By clicking this, I hereby acknowledge and confirm that I have read,
          understood, and fully accept the terms and conditions set forth in the
          Master Service Agreement and the Terms of Use
        </p>
      </div>
    </section>
  );
};

export default TermsAndConditions;
