import React, { useState } from "react";
import UploadFiles from "../components/uploadFiles";
import { MdLockOutline } from "react-icons/md";

const PitchCreation = ({
  fetchDetails,
  setSchema,
  validationSchema = { validationSchema },
  setIsUploading,
  uploadFileData,
}) => {
  const [lastHoveredIndex, setLastHoveredIndex] = useState(null);
  return (
    <div className="PitchCreation fundrev-card onboardingForm-card relative">
      <p className="heading">Create Your Pitch</p>
      <p className="subheading">
        Enter your pitch deck and video pitch to explain your business
      </p>
      <p className="flex flex-row gap-[8px] text-[14px] color-[#696F79] bg-zinc-50 p-[4px] rounded-[4px] items-center justify-center absolute top-[16px] right-[16px]">
        <MdLockOutline />
        Your info is secured with us
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <UploadFiles
          filename="Pitch Deck"
          name={"pitchDeck"}
          filesArray={"pitchDeckFiles"}
          documentsRequiredText="Upload your latest Pitch Deck here"
          index={1}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Video Pitch"
          name={"videoPitch"}
          filesArray={"videoPitchFiles"}
          documentsRequiredText="Upload a 3-min short video pitch"
          index={2}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
          multiple={false}
        />
      </div>
    </div>
  );
};

export default PitchCreation;
