import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { roundTypeOptions } from "../../Common/functions";
import CustomBlackBtn from "./custom/customBlackBtn";
import AddIcon from "@mui/icons-material/Add";

const PreviousRoundsEditable = ({ values, setFieldValue }) => {
  const [rowModesModel, setRowModesModel] = useState({});

  function EditToolbar(props) {
    const { values, setFieldValue } = props;

    const handleClick = () => {
      const id = randomId();
      const newRow = {
        id,
        date: "",
        round: "",
        raised: "",
        stake: "",
        valuation: "",
        isNew: true,
      };
      if (values?.previousRounds?.length > 0) {
        setFieldValue("previousRounds", [...values?.previousRounds, newRow]);
      } else {
        setFieldValue("previousRounds", [newRow]);
      }
    };

    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <p>Enter previous funding rounds details</p>
        <CustomBlackBtn
          onClick={handleClick}
          type="button"
          text={
            <>
              <AddIcon style={{ fontSize: "16px" }} /> Add Row
            </>
          }
          variant="sm"
          border="sm"
        />
      </GridToolbarContainer>
    );
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    // Prevent form submission on Enter key
    if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      if (event) {
        event.preventDefault();
      }
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const updatedRows = values.previousRounds.filter((row) => row.id !== id);
    setFieldValue("previousRounds", updatedRows);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = values.previousRounds.find((row) => row.id === id);

    if (editedRow.isNew) {
      const updatedRows = values.previousRounds.filter((row) => row.id !== id);
      setFieldValue("previousRounds", updatedRows);
    }

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow) => {
    const updatedRows = values.previousRounds.map((row) =>
      row.id === newRow.id ? { ...newRow, isNew: false } : row
    );
    setFieldValue("previousRounds", updatedRows);
    return newRow;
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 160,
      headerClassName: "border-t border-r",
      editable: true,
      sortable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (value) {
          return new Date(value);
        }
        return null;
      },
    },
    {
      field: "round",
      headerClassName: "border-t border-r",
      headerName: "Round",
      width: 160,
      type: "singleSelect",
      valueOptions: () => {
        return roundTypeOptions;
      },
      editable: true,
      sortable: false,
    },
    {
      field: "raised",
      headerName: "Raised",
      headerClassName: "border-t border-r",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
      valueSetter: (value, row) => {
        const newRaised = value;
        const newValuation = row.stake > 0 ? (newRaised * 100) / row.stake : 0;
        return { ...row, raised: newRaised, valuation: newValuation };
      },
    },
    {
      field: "stake",
      headerName: "Stake",
      headerClassName: "border-t border-r",
      width: 160,
      editable: true,
      sortable: false,
      type: "number",
      valueSetter: (value, row) => {
        const newStake = value;
        const newValuation = newStake > 0 ? (row.raised * 100) / newStake : 0;
        return { ...row, stake: newStake, valuation: newValuation };
      },
    },
    {
      field: "valuation",
      headerName: "Valuation",
      headerClassName: "border-t border-r",
      width: 160,
      editable: false,
      sortable: false,
      type: "number",
    },
    {
      field: "actions",
      type: "actions",
      headerClassName: "border-t",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              type="button"
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <DataGrid
      rows={values?.previousRounds}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      disableColumnMenu={true}
      disableColumnResize={true}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      slots={{
        toolbar: EditToolbar, // Updated from components to slots
      }}
      slotProps={{
        toolbar: { values, setFieldValue }, // Updated from componentsProps to slotProps
      }}
      hideFooter
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.stopPropagation();
        }
      }}
    />
  );
};

export default PreviousRoundsEditable;
