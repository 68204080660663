import React from "react";
import NoDealsImg from "../../../../Assets/Images/InvestorPage/Dashboard/noDeals.svg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";

const NoDeals = ({ text }) => {
  const navigate = useNavigate();
  const dealText = text || "deals";
  return (
    <div className="bg-[#fff] max-w-[825px] flex columns flex-col justify-center gap-4 items-center p-[40px] m-auto mt-[200px] rounded-[24px]">
      <img src={NoDealsImg} alt="No Deals available" className="w-[62px]" />
      <p className="text-[#1A1A1A] font-semibold">
        You currently don't have anything in your {dealText}!
      </p>
      <CustomBlackBtn
        text="View All Deals"
        onClick={() => navigate("/investor/newDeals/allListing")}
      />
    </div>
  );
};

export default NoDeals;
