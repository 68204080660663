import React, { useEffect, useState } from "react";
import { getInvestorToolkitLeads } from "../../endpoints/admin";

const InvestorToolkitLeads = () => {
  const [data, setData] = useState(null);
  const token = localStorage.getItem("token");
  const getLeads = async () => {
    if (token) {
      const response = await getInvestorToolkitLeads(token);
      setData(response);
    }
  };
  useEffect(() => {
    getLeads();
  }, []);
  return (
    <>
      {data && (
        <div className="InvestorSide-box table-container">
          <table aria-label="Investor Toolkit Leads Table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Revenue</th>
                <th>Backing</th>
                <th>Funding Required</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.fullName}</td>
                    <td>{row?.companyName}</td>
                    <td>{row?.email}</td>
                    <td>{row?.phoneNumber}</td>
                    <td>{row?.revenue} Cr</td>
                    <td>{row?.backing}</td>
                    <td>{row?.fundingRequired} Cr</td>
                    <td>{new Date(row?.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default InvestorToolkitLeads;
