import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  getSecondaryDealById,
  updateSecondaryDeal,
} from "../../../endpoints/secondary";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import Topbar from "../Company/Deal/topbar";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import uploadImg from "../../../Assets/Images/signup/upload-img.svg";
import editImg from "../../../Assets/Images/signup/edit-img.svg";
import {
  backingOptions,
  endMarketFocusOptions,
  industryVerticalsOptions,
  StyledFormControl,
  StyledFormLabelOptional,
  subIndustryVerticalOptions,
} from "../../Common/functions";
import CustomInput from "../../Common/components/custom/customInput";
import CustomSelect from "../../Common/components/custom/customSelect";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import Toast from "../../Common/Toast";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
const EditSecondaryDeal = () => {
  const location = useLocation();
  const { dealId } = location.state || {};
  const token = localStorage.getItem("token");
  const [newtag, setNewTag] = useState();
  const [field, setField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const [fileUrl, setFileUrl] = useState();
  const [initialValues, SetInitialValues] = useState({
    dealId: dealId,
    //Company Details
    companyLogo: "",
    companyName: "",
    legalEntityName: "",
    website: "",
    description: "",
    headquarters: "",
    foundedYear: null,
    numEmployees: null,
    industryVerticals: [],
    subIndustryVerticals: [],
    endMarket: [],
    backing: "",
    cin: "",
    taxNumber: "",
    //Deal Details
    fundingRequired: null,
    expectedValuation: null,
    stakeOffered: null,
    //No Name Details
    noNameTitle: "",
    noNameSubHeading: "",
    keyHighlights: "",
    noNameDescription: "",
    dealTags: [],
    investmentHighlights: {},
    financialMetrics: {},
    dataAvailabilityWithFundrev: {
      analytics: false,
      dataroom: false,
      forecastModel: false,
      managementPresentation: false,
    },
  });
  const handleDealTagsChange = (values, setFieldValue) => {
    const tags = values.dealTags || [];
    tags.push(newtag);
    setFieldValue(`dealTags`, tags);
    setNewTag("");
  };
  const deleteDealTag = (indexToDelete, values, setFieldValue) => {
    const updatedDealTags = values.dealTags.filter(
      (tag, index) => index !== indexToDelete
    );
    setFieldValue(`dealTags`, updatedDealTags);
    return {
      dealTags: updatedDealTags,
    };
  };
  function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const fileURL = URL.createObjectURL(file);
      setFileUrl(fileURL);
      setCompanyLogoFile(file);
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "secondaryDealEdit"
      );
    }
  }
  const fetchDealDetails = async () => {
    const response = await getSecondaryDealById(dealId, token);
    const data = response?.data;
    if (data) {
      SetInitialValues((prev) => ({
        ...prev,
        companyLogo: data?.companyLogo,
        companyName: data?.companyName,
        legalEntityName: data?.legalEntityName,
        website: data?.website,
        description: data?.description,
        headquarters: data?.headquarters,
        foundedYear: data?.foundedYear,
        numEmployees: data?.numEmployees,
        industryVerticals: data?.industryVerticals,
        subIndustryVerticals: data?.subIndustryVerticals,
        endMarket: data?.endMarket,
        backing: data?.backing,
        cin: data?.cin,
        taxNumber: data?.taxNumber,
        fundingRequired: data?.fundingRequired,
        expectedValuation: data?.expectedValuation,
        stakeOffered: data?.stakeOffered,
        noNameTitle: data?.noNameTitle,
        noNameSubHeading: data?.noNameSubHeading,
        keyHighlights: data?.keyHighlights,
        noNameDescription: data?.noNameDescription,
        dealTags: data?.dealTags,
        investmentHighlights: data?.investmentHighlights,
        financialMetrics: data?.financialMetrics,
        dataAvailabilityWithFundrev: data?.dataAvailabilityWithFundrev,
      }));
    }
  };
  const getFilteredSubIndustryOptions = (values) => {
    return values.industryVerticals.flatMap(
      (industry) => subIndustryVerticalOptions[industry] || []
    );
  };
  useEffect(() => {
    if (token && dealId) {
      fetchDealDetails();
    }
  }, [dealId]);
  const validationSchema = Yup.object().shape();
  const handleSave = async (values) => {
    const formDataInput = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] !== undefined) {
        if (typeof values[key] === "object" && !(values[key] instanceof File)) {
          formDataInput.append(key, JSON.stringify(values[key]));
        } else {
          formDataInput.append(key, values[key]);
        }
      }
    }
    if (companyLogoFile) {
      formDataInput.append("companyLogo", companyLogoFile);
    }
    const response = await updateSecondaryDeal(formDataInput, token);
    if (response?.data) {
      Toast("Data saved successfully", "success", "secondaryDealEdit");
    } else {
      Toast("Failed to save the data", "error", "secondaryDealEdit");
    }
  };
  const addFieldToMetrics = (type, values, setFieldValue) => {
    const inputField =
      type === "investmentHighlights" ? field.trim() : secondField.trim();

    if (inputField) {
      setFieldValue(type, { ...values[type], [inputField]: "" });
      setField("");
      setSecondField("");
    }
  };
  const deleteMetric = (type, key, values, setFieldValue) => {
    const metric = values[type];
    delete metric[key];
    setFieldValue(type, metric);
  };
  const renderMetricsRows = (values, type, groupSize, setFieldValue) => {
    const metrics = Object.entries(values?.[type] || {});
    const rows = [];
    for (let i = 0; i < metrics.length; i += groupSize) {
      const group = metrics.slice(i, i + groupSize);
      const emptyFieldsCount = groupSize - group.length;

      rows.push(
        <div key={`${type}-row-${i}`} className="input-div">
          {group.map(([key, value], index) => (
            <FormControl
              key={`${type}-input-${index}`}
              className="flex justify-evenly"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                {key}
                <RiDeleteBin6Line
                  className="red-bin"
                  onClick={() => {
                    deleteMetric(type, key, values, setFieldValue);
                  }}
                />
              </FormLabel>

              <Input
                className="input"
                placeholder={`Add value of ${key}`}
                color="neutral"
                name={key}
                type="text"
                value={value}
                onChange={(event) =>
                  setFieldValue(`${type}.${key}`, event.target.value)
                }
                required
              />
            </FormControl>
          ))}
          {Array.from({ length: emptyFieldsCount }).map((_, index) => (
            <FormControl
              key={`${type}-empty-${index}`}
              className="flex justify-evenly "
            >
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          ))}
        </div>
      );
    }

    return rows;
  };
  const handleKeyDown = (event, type, values, setFieldValue) => {
    if (event.key === "Enter") {
      addFieldToMetrics(type, values, setFieldValue);
    }
  };

  return (
    <>
      <Helmet>
        <title>Admin Panel - Secondary Deal</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="secondaryDealEdit" />
      <Topbar />
      <div className="mt-20">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div
                  className="editCompanyDetails fundrev-card"
                  style={{
                    margin: "0px 0px 0px 0px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 20px)",
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <div>
                      <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                        Company Details
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div className="fundrev-chip blue-chip">
                        Deal ID: {values.dealId}
                      </div>
                    </div>
                  </div>
                  <div class="upload-btn-wrapper">
                    <button className="w-[100px]">
                      <img
                        src={
                          !companyLogoFile
                            ? values.companyLogo
                              ? values.companyLogo
                              : uploadImg
                            : fileUrl
                        }
                        alt="Upload"
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                    <label htmlFor="company-logo" className="relative">
                      <img src={editImg} className="editImg" alt="Edit" />
                    </label>
                    <input
                      id="company-logo"
                      type="file"
                      name="image"
                      accept="image/png, image/jpeg"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </div>
                  <div className="company-details-input-fields">
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Company Name
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Company Name`}
                        name={`companyName`}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Legal Entity Name
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Legal Entity Name`}
                        name={`legalEntityName`}
                        value={values.legalEntityName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Company Website
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter website`}
                        name={`website`}
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>CIN</StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter CIN`}
                        name={`cin`}
                        value={values.cin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Headquarters
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter headquarters`}
                        name={`headquarters`}
                        value={values.headquarters}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Founded Year
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Founded Year`}
                        name={`foundedYear`}
                        type="number"
                        value={values.foundedYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Number of Employees
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Number of Employees`}
                        name={`numEmployees`}
                        value={values.numEmployees}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Tax Number
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Tax Number`}
                        name={`taxNumber`}
                        value={values.taxNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Industry Verticals
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="Industry Verticals"
                        name="industryVerticals"
                        multiple={true}
                        value={values.industryVerticals}
                        onChange={handleChange}
                        options={industryVerticalsOptions}
                        placeholder="Select Industry Verticals"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Sub Industry Verticals
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="Sub Industry Verticals"
                        name="subIndustryVerticals"
                        multiple={true}
                        value={values.subIndustryVerticals}
                        onChange={handleChange}
                        options={getFilteredSubIndustryOptions(values)}
                        placeholder="Select Sub Industry Verticals"
                      />
                    </StyledFormControl>

                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        End Market Focus
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="End Market Focus"
                        name="endMarket"
                        multiple={true}
                        value={values.endMarket}
                        onChange={handleChange}
                        options={endMarketFocusOptions}
                        placeholder="Select Industry Verticals"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>Backing</StyledFormLabelOptional>
                      <CustomSelect
                        label="Backing"
                        name="backing"
                        value={values.backing}
                        onChange={handleChange}
                        options={backingOptions}
                        placeholder="Select Backing"
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Company Description
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Company Description`}
                        name={`description`}
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        minRows={4}
                      />
                      <p>Rendered Business description</p>
                      <div className="setInnerHTML">
                        {/* {parseHTMLString(values.description)} */}
                        {values.description}
                      </div>
                    </StyledFormControl>
                  </div>
                </div>
                <div className="editCompanyDetails fundrev-card">
                  <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] mb-4">
                    Deal Details
                  </p>
                  <div className="company-details-input-fields">
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Funding Required (Cr.)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`fundingRequired`}
                        placeholder={`Enter funding required`}
                        onChange={(e) => {
                          setFieldValue("fundingRequired", e.target.value);
                          if (values.expectedValuation) {
                            setFieldValue(
                              "stakeOffered",
                              (
                                (e.target.value / values.expectedValuation) *
                                100
                              ).toFixed(2)
                            );
                          }
                        }}
                        value={values.fundingRequired}
                        onBlur={handleBlur}
                        type="number"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Expected Valuation (Cr.)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`expectedValuation`}
                        onChange={(e) => {
                          setFieldValue("expectedValuation", e.target.value);
                          if (e.target.value) {
                            setFieldValue(
                              "stakeOffered",
                              (
                                (values.fundingRequired / e.target.value) *
                                100
                              ).toFixed(2)
                            );
                          }
                        }}
                        value={values.expectedValuation}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Expected Valuation`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Stake Offered (%)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`stakeOffered`}
                        value={values.stakeOffered}
                        onBlur={handleBlur}
                        placeholder={`Enter Stake Offered`}
                        type="number"
                        disabled
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name Title
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`noNameTitle`}
                        onChange={handleChange}
                        value={values.noNameTitle}
                        onBlur={handleBlur}
                        placeholder={`Enter No Name Title`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name SubHeading
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`noNameSubHeading`}
                        onChange={handleChange}
                        value={values.noNameSubHeading}
                        onBlur={handleBlur}
                        placeholder={`Enter No Name Sub Heading`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Key Highlights
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Key Highlights`}
                        name={`keyHighlights`}
                        value={values.keyHighlights}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        minRows={4}
                      />
                      <p>Rendered Key Highlights</p>
                      <div className="setInnerHTML">
                        {/* {parseHTMLString(values.keyHighlights)} */}
                        {values.keyHighlights}
                      </div>
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name Description
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter No Name Description`}
                        name={`noNameDescription`}
                        value={values.noNameDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        minRows={4}
                      />
                      <p>Rendered No Name Description</p>
                      <div className="setInnerHTML">
                        {/* {parseHTMLString(values.noNameDescription)} */}
                        {values.noNameDescription}
                      </div>
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Financial Sub Heading
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`financialSubHeading`}
                        onChange={handleChange}
                        value={values.financialSubHeading}
                        onBlur={handleBlur}
                        placeholder={`Enter Financial Sub Heading`}
                      />
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Analytics
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={
                              values.dataAvailabilityWithFundrev.analytics ===
                              true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.analytics",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={
                              values.dataAvailabilityWithFundrev.analytics ===
                              false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.analytics",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Dataroom
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.dataroom"
                            checked={
                              values.dataAvailabilityWithFundrev.dataroom ===
                              true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.dataroom",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.dataroom"
                            checked={
                              values.dataAvailabilityWithFundrev.dataroom ===
                              false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.dataroom",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- ForecastModel
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.forecastModel"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .forecastModel === true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.forecastModel",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.forecastModel"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .forecastModel === false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.forecastModel",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Management Presentation
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.managementPresentation"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .managementPresentation === true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.managementPresentation",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.managementPresentation"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .managementPresentation === false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.managementPresentation",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl></StyledFormControl>
                    <StyledFormControl>
                      <StyledFormLabelOptional>
                        Deal Tags
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder="Enter your tag here..."
                        name="dealTags"
                        value={newtag}
                        onChange={(e) => setNewTag(e.target.value)}
                        multiline
                        minRows={1}
                      />
                      <div className="table-edit-buttons">
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleDealTagsChange(values, setFieldValue);
                          }}
                          className="add-row"
                          type="button"
                        >
                          <AiOutlinePlus />
                          &nbsp;&nbsp; Add tag
                        </button>
                      </div>
                      <div className="hightlights-div">
                        <b>Deal tags</b>
                        <ol style={{ listStyleType: "decimal" }}>
                          {values.dealTags.map((tag, index) => (
                            <li key={index} style={{ textWrap: "no-wrap" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p>{tag}</p>
                                <RiDeleteBin6Line
                                  className="red-bin"
                                  onClick={() =>
                                    deleteDealTag(index, values, setFieldValue)
                                  }
                                />
                              </div>
                            </li>
                          ))}
                        </ol>
                      </div>
                    </StyledFormControl>
                  </div>
                </div>
                <div className="admin-key-metrics fundrev-card mb-4">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                      Investment Highlights
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Input
                        placeholder="field"
                        type="text"
                        name="field"
                        value={field}
                        onChange={(e) => setField(e.target.value)}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            "investmentHighlights",
                            values,
                            setFieldValue
                          )
                        }
                      />
                      <Button
                        onClick={() =>
                          addFieldToMetrics(
                            "investmentHighlights",
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Add field
                      </Button>
                    </div>
                  </div>
                  {renderMetricsRows(
                    values,
                    "investmentHighlights",
                    3,
                    setFieldValue
                  )}
                </div>

                <div className="admin-card-metrics fundrev-card mb-4">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                      Financial Metrics
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Input
                        placeholder="field"
                        type="text"
                        name="secondField"
                        value={secondField}
                        onChange={(e) => setSecondField(e.target.value)}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            "financialMetrics",
                            values,
                            setFieldValue
                          )
                        }
                      />
                      <Button
                        onClick={() =>
                          addFieldToMetrics(
                            "financialMetrics",
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Add field
                      </Button>
                    </div>
                  </div>
                  {renderMetricsRows(
                    values,
                    "financialMetrics",
                    3,
                    setFieldValue
                  )}
                </div>
                <div className="navigation-buttons w-full flex justify-end">
                  <CustomBlackBtn text={`Save`} type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditSecondaryDeal;
