import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";

export default function BreadCrumbs({ breadCrumbs, BreadCrumbClick }) {
  const [condensed, setCondensed] = useState(false);
  const [visibleBreadcrumbs, setVisibleBreadcrumbs] = useState([]);

  useEffect(() => {
    if (!condensed && breadCrumbs.length > 4) {
      setVisibleBreadcrumbs(
        breadCrumbs.slice(0, 1).concat(["•••"], breadCrumbs.slice(-2))
      );
    } else {
      setVisibleBreadcrumbs(breadCrumbs);
    }
  }, [breadCrumbs, condensed]);

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumbs">
      {visibleBreadcrumbs.map((breadCrumb, index) => {
        const isFirst = index === 0;
        const isLast = index === visibleBreadcrumbs.length - 1;
        const isEllipsis = breadCrumb === "•••";

        if (isEllipsis) {
          return (
            <Button
              key="ellipsis"
              size="sm"
              variant="plain"
              color="primary"
              onClick={() => setCondensed(false)}
            >
              •••
            </Button>
          );
        }

        return isLast ? (
          <Typography
            key={breadCrumb.label || index}
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "karla",
              fontSize: "14px",
            }}
          >
            {isFirst ? (
              <HomeIcon sx={{ mr: 0.5 }} />
            ) : (
              <FolderIcon sx={{ mr: 0.5 }} />
            )}
            {breadCrumb.label}
          </Typography>
        ) : (
          <div
            key={breadCrumb.label || index}
            className="text-[var(--brand-color)] cursor-pointer flex items-center hover:underline"
            onClick={() => BreadCrumbClick(breadCrumb.path, index)}
          >
            {isFirst ? (
              <HomeIcon className="mr-2" />
            ) : (
              <FolderIcon className="mr-2" />
            )}
            <Typography sx={{ fontSize: "14px" }}>
              {breadCrumb.label}
            </Typography>
          </div>
        );
      })}
    </Breadcrumbs>
  );
}
