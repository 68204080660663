import React, { useEffect, useState } from "react";

import { calculateProfileCompletion } from "../onBoarding/stepCompletion";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import "../../../../CSS/FounderPage/Dashboard/dashboardState.css";
import { ShinyButton } from "../../../Common/animations/shinyButton";

//We are showing details that are filled by company here as deal might not be created yet by FA.
const CompleteDealDetails = ({ deal, details }) => {
  const Navigate = useNavigate();
  const [profileRemainingPercentage, setProfileRemainingPercentage] =
    useState(0);
  const [initialValues, setInitialValues] = useState({
    //deal details first stage
    dealType: "",
    timeline: null,
    fundingRequired: "",
    debtFundingRequired: "", // Only visible when the user has chosen equity + debt option
    roundType: [],
    debtType: "",
    term: "",
    preferredInvestors: [],

    //Expected Valuation second stage
    equityType: "",
    pricedEquityType: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    nonPricedEquityType: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    //Deal Interest third stage
    softCommitments: false,
    fundingRaised: 0,
    debtRaised: "", // Only visible when the user has chosen equity + debt option
    // leadInvestor: "",
    coInvestors: "",
    contactedInvestorNames: "",

    //purposeOfFundraising of fundraising fourth step
    purposeOfFundraising: "",

    //Pitch Creation fifth stage
    pitchDeck: [],
    videoPitch: [],

    //Meeting Calender setup
    availableDays: ["M1", "T2", "W3", "T4", "F5"],
    availableTimeStart: dayjs().set("hour", 10).set("minute", 0),
    availableTimeEnd: dayjs().set("hour", 18).set("minute", 0),
    googleAuthToken: false,
    microsoftAuthToken: false,

    //Analytics preparation seventh stage
    financialDocuments: [],
    invoiceDetails: [],
    bankStatements: [],

    //Dataroom preparation eighth stage
    business: [],
    incorporation: [],
    teamDetails: [],
    legalDocuments: [],
    complianceDetails: [],
    productTechnology: [],

    //Terms and conditions ninth step
    acceptTC: false,

    pitchDeckFiles: "",
    videoPitchFiles: "",
    financialDocumentsFiles: "",
    invoiceDetailsFiles: "",
    bankStatementsFiles: "",

    corporateDocumentsFiles: "",
    teamDetailsFiles: "",
    productTechnologyFiles: "",
    marketClientInfoFiles: "",
    legalComplianceDetailsFiles: "",
    financialPlanningFiles: "",

    deletedFileNames: "",
    isApplicationReviewed: "",
    profileCompleted: "",
  });
  useEffect(() => {
    setProfileRemainingPercentage(
      100 - calculateProfileCompletion(initialValues)
    );
  }, [initialValues]);
  useEffect(() => {
    setInitialValues({
      // Deal details first stage
      dealType: deal.dealType || "",
      timeline: deal.timeline || null,
      fundingRequired: deal.fundingRequired || "",
      debtFundingRequired: deal.debtFundingRequired || "", // Visible only for equity + debt option
      roundType: deal?.roundType || [],
      debtType: deal.debtType || "",
      term: deal.term || "",
      preferredInvestors: deal?.preferredInvestors || [],

      // Expected Valuation second stage
      equityType: deal.equityType || "",
      pricedEquityType: deal.pricedEquityType || "",
      stakeOffered: deal.stakeOffered || "",
      minPostMoneyValuation: deal.minPostMoneyValuation || "",
      nonPricedEquityType: deal.nonPricedEquityType || "",
      interestRate: deal.interestRate || "",
      valuationCap: deal.valuationCap || "",
      discountRate: deal.discountRate || "",

      // Deal Interest third stage
      softCommitments: deal.softCommitments || false,
      fundingRaised: deal.fundingRaised || 0,
      debtRaised: deal.debtRaised || "", // Visible only for equity + debt option
      // leadInvestor: deal.leadInvestor || "",
      coInvestors: deal.coInvestors || "",
      contactedInvestorNames: deal.contactedInvestorNames || "",

      // purposeOfFundraising of fundraising fourth step
      purposeOfFundraising: deal.purposeOfFundraising || "",

      // Pitch Creation - Fifth Stage
      pitchDeck: details.pitchDeck || [],
      videoPitch: deal.videoPitch || [],

      //Meeting Calender setup
      availableDays: details?.availableDays || ["M1", "T2", "W3", "T4", "F5"],
      availableTimeStart: details.availableTimeStart
        ? dayjs(details.availableTimeStart)
        : dayjs().set("hour", 10).set("minute", 0),
      availableTimeEnd: details.availableTimeEnd
        ? dayjs(details.availableTimeEnd)
        : dayjs().set("hour", 18).set("minute", 0),
      googleAuthToken: details.googleAuthToken || false,
      microsoftAuthToken: details.microsoftAuthToken || false,

      // Analytics Preparation - Sixth Stage
      financialDocuments: details.financialDocuments || [],
      invoiceDetails: details.invoiceDetails || [],
      bankStatements: details.bankStatements || [],

      // Data Room Preparation - Seventh Stage
      corporateDocuments: details.corporateDocuments || [],
      teamDetails: details.teamDetails || [],
      productTechnology: details.productTechnology || [],
      marketClientInfo: details.marketClientInfo || [],
      legalComplianceDetails: details.legalComplianceDetails || [],
      financialPlanning: details.financialPlanning || [],

      //Terms and conditions ninth step
      acceptTC: details.acceptTC || false,

      //Submit for review
      onBoardingComplete: details.onBoardingComplete || false,
      isApplicationReviewed: details.isApplicationReviewed || false,
    });
  }, [details]);

  return (
    <div className="dashBoard-state-card">
      <div className="inner-content-div">
        <div className="middle-div">
          <p className="roundType-text">{deal.roundType}</p>
          <p className="roundSize-text">
            <span>Round Size:</span> ₹{deal.fundingRequired}Cr
          </p>
          {deal.timeline && (
            <p className="roundSize-text">
              <span>End Date:</span>{" "}
              {moment(deal.timeline).format("D MMM YYYY")}
            </p>
          )}
        </div>
        <div className="button-div">
          <ShinyButton
            className={`rounded-[4px] px-6 py-2`}
            onClick={() => Navigate("/company/onboarding")}
          >
            Complete Deal Details
          </ShinyButton>
          {/* <p>{`${profileRemainingPercentage}% remaining`}</p> */}
        </div>
      </div>
    </div>
  );
};

export default CompleteDealDetails;
