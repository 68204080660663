import React, { useEffect, useState } from "react";
import Sidebar from "./Components/sidebar";
import Topbar from "./Components/topbar";
import NoNamePreview from "../../Common/components/NoNamePreview";
import { investorState } from "./investorState";
import { postStageData } from "../../../endpoints/deal";
import {
  getWishlist,
  fetchInvestorMemberDetails,
} from "../../../endpoints/investor";
import PassDealModal from "../../Common/modals/passDealModal";
import Toast from "../../Common/Toast";
import { useLoader } from "../../Common/LoaderProvider";
import CompanyDetailsSubheader from "./Components/companyDetailsSubheader";

import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/joy";
import { useRecoilState } from "recoil";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import "../../../CSS/InvestorPage/Dashboard/companyDetails.css";
import CustomRedBtn from "../../Common/components/custom/customRedBtn";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import GreenTickModal from "../../Common/components/custom/greenTickModal";

const InvestorSideCompanyDetails = () => {
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(investorState);
  const Navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const { deal, passedStage, isPassed } = location.state || {};
  const [dealId, setDealId] = useState(deal ? deal.dealId : "");
  const [investorId, setInvestorId] = useState("");
  const [name, setName] = useState("");
  // const { investorId, name } = useRecoilValue(investorState);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [stage, setStage] = useState(passedStage ? passedStage : "");
  const [isStar, setisStar] = useState(false);
  const [acceptanceModal, setAcceptanceModal] = useState(false);
  const [formData, setFormData] = useState({
    dealId: dealId,
    investorID: investorId,
  });
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchInvestorMemberDetails(token);
        setName(response.data.data.name);
        setInvestorId(response.data.data.investorID);
      }
    };
    fetchDetails();
  }, [token]);
  // useEffect(() => {
  //   if (!deal || !passedStage) {
  //     Navigate("/investor/ongoingdeals");
  //   }
  // }, [deal, passedStage, isPassed]);
  // useEffect(() => {
  //   let timer;
  //   if (openSaveModal) {
  //     timer = setTimeout(() => {
  //       setOpenSaveModal(false);
  //     }, 1000); // Close after 3 seconds
  //   }
  //   return () => clearTimeout(timer); // Clean up the timer
  // }, [openSaveModal]);
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        // loader.start();
        const response = await getWishlist(investorId, token);
        loader.stop();
        if (response) {
          //
          if (response.data) {
            const dealsId = response.data;
            if (dealsId.includes(deal ? deal.organizationId : "")) {
              setisStar(true);
            }
          }
        }
      } catch (error) {
        loader.stop();
      }
    };
    fetchWishlist();
  }, [investorId]);
  const handleRequestToStartup = async () => {
    const updatedFormData = {
      dealId: dealId,
      investorID: investorId,
      IsInterested: true,
      Stage: "requestSent",
    };
    setFormData(updatedFormData);
    let response;
    try {
      loader.start("Sending request to startup...");
      response = await postStageData(updatedFormData, token);
      loader.stop();
      if (response?.error?.status === 403) {
        Toast(
          "Error sending request",
          "error",
          "companyDetailsBeforeNDASigned"
        );
      } else {
        loader.stop();
        setOpenSaveModal(true);
        setStage("requestSent");
      }
    } catch (error) {
      setOpenSaveModal(false);
      setStage("initialStage");
      loader.stop();
    }

    setAcceptanceModal(false);
  };
  function handleCheckProfileComplete(type) {
    if (type === "reason") {
      setOpenReasonModal(true);
    } else {
      if (!state.profileCompleted) {
        Toast(
          "Please complete your profile before requesting to startup",
          "error",
          "companyDetailsBeforeNDASigned"
        );
        setAcceptanceModal(false);
        return;
      }
      setAcceptanceModal(true);
    }
  }
  // useZoomEffect();
  return (
    <div className="InvestorSide noNamePreview-page">
      <GreenTickModal
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        title={`Your request has been sent successfully!`}
      />
      {/* <Modal
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "600px", height: "398px" }}>
          <img src={Verify} alt="verify" className="verify-image" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Your request has been sent successfully!
          </Typography>
        </Box>
      </Modal> */}
      <Modal
        open={acceptanceModal}
        onClose={() => setAcceptanceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "fit-content" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            alt="close"
            onClick={() => setAcceptanceModal(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading">Send Request</p>
            </div>
            <div className="subheading">
              <p>Do you want to proceed with sending the request?</p>
            </div>
          </div>

          <div className="btn-box">
            <CustomBlackBtn
              text={`Cancel`}
              filled={false}
              onClick={() => setAcceptanceModal(false)}
            />
            {/* <button
              className="reason-submit cancel"
              style={{ height: "40px" }}
              onClick={() => setAcceptanceModal(false)}
            >
              Cancel
            </button> */}
            {/* <button className="reason-submit" onClick={handleRequestToStartup}>
              Confirm
            </button> */}
            <CustomBlackBtn
              text={`Send Request`}
              onClick={handleRequestToStartup}
            />
          </div>
        </Box>
      </Modal>
      <PassDealModal
        openReasonModal={openReasonModal}
        setOpenReasonModal={setOpenReasonModal}
        stage={stage}
        dealId={dealId}
        investorID={investorId}
        type={stage === "requestSent" ? "withdraw" : null}
        containerId={"companyDetailsBeforeNDASigned"}
      />
      <ToastContainer
        position="top-center"
        containerId="companyDetailsBeforeNDASigned"
      />
      <Sidebar active="ongoingDeals" />
      <Topbar title="Company Profile" />

      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            {stage === "requestSent" && (
              <CompanyDetailsSubheader activeButton={1} stage={stage} />
            )}
            <NoNamePreview
              deal={deal}
              stage={stage}
              isStar={isStar}
              setisStar={setisStar}
            />
            {isPassed !== true && (
              <>
                {stage === "initialStage" ? (
                  <div className="showInterestButtons-div">
                    <div className="btns-insideContent">
                      <div onClick={() => handleCheckProfileComplete("reason")}>
                        <CustomRedBtn type="button" text="Not interested" />
                      </div>
                      <div
                        onClick={() => handleCheckProfileComplete("acceptance")}
                      >
                        <CustomBlackBtn type="button" text="Send request" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {stage === "requestSent" && (
                  <div className="showInterestButtons-div">
                    <div className="btns-insideContent">
                      <div onClick={() => setOpenReasonModal(true)}>
                        <CustomRedBtn type="button" text="Withdraw request" />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorSideCompanyDetails;
