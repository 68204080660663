import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import Toast from "../../Common/Toast";
import star from "../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";
import pendingProfile from "../../../Assets/Images/InvestorPage/Dashboard/pendingProfile.svg";

import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { investorState } from "../../../Components/Investor/Dashboard/investorState";

import "react-circular-progressbar/dist/styles.css";
import "../../../CSS/InvestorPage/Dashboard/card.css";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
} from "../../../endpoints/investor";
import { getDealsByInvestorID } from "../../../endpoints/deal";
import ProgressBar from "./molecules/progressBar";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";
import CustomBlackBtn from "./custom/customBlackBtn";
import { Bolt } from "@mui/icons-material";

const Card = ({
  deal,
  isStar,
  investorId,
  startPoint,
  isRecommended,
  isPassed,
  stage,
  investorVerified,
  profileCompleted,
  elementRef,
  index,
  setWishlistDealIds,
}) => {
  const {
    organizationId,
    noNameTitle,
    noNameSubHeading,
    roundType,
    industryVerticals,
    backing,
    endMarket,
    fundingRequired,
    fundingRaised,
    dealTags,
    financialMetrics,
    dealType,
  } = deal;
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [state, setState] = useRecoilState(investorState);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [isStarFilled, setIsStarFilled] = useState(isStar);
  const [firstTag, setFirstTag] = useState();
  const [cardClick, setCardClick] = useState(false);

  function processFirstTag() {
    try {
      setFirstTag(dealTags);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [dealTags]);
  const addDealToWishlist = async (dealId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await addStartuptoWishlist(dealId, investorId, token);

      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return [...prev, dealId];
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Added to wishlist", "success");
      }
    } catch (error) {}
  };

  const removeDealFromWishlist = async (dealId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await removeStartupFromWishlist(
        dealId,
        investorId,
        token
      );
      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return prev.filter((id) => id !== dealId);
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Removed from wishlist", "success");
      }
    } catch (error) {}
  };
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle industryVerticals parsing
      try {
        if (industryVerticals.length > 0) {
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      // Handle endMarket parsing
      try {
        if (endMarket?.length > 0) {
          if (Array.isArray(endMarket)) tags.push(...endMarket);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (backing?.length > 0) {
          const fundingTypes = [backing];
          if (Array.isArray(fundingTypes)) tags.push(...fundingTypes);
        }
      } catch (error) {
        console.error("Error parsing Backing:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [roundType, industryVerticals, endMarket]);
  const handleStarClick = async () => {
    setIsStarFilled((prevIsStarFilled) => {
      const newIsStarFilled = !prevIsStarFilled;
      if (newIsStarFilled) {
        addDealToWishlist(deal.dealId, investorId);
      } else {
        removeDealFromWishlist(deal.dealId, investorId);
      }
      return newIsStarFilled;
    });
  };
  function navigateToCompanyDetails() {
    if (role === "company") {
      Navigate("/company/dashboard", {
        state: {
          deal,
        },
      });
    }
    if (startPoint === "noNameDeals") {
      const label = `No Name Deals Card - ${deal.dealId}`;
      ReactGA.event({
        category: "navigation",
        action: "button_click",
        label: label,
      });
      mixpanel.track("No Name Deal Card Click", {
        dealId: deal?.dealId,
      });
      if (token && role === "investor") {
        Navigate("/investor/newDeals/allListing", {
          state: {
            deal: deal,
            passedStage: stage,
          },
        });
      } else {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (!investorVerified || !profileCompleted) {
        setCardClick(true);
        return;
      }
      if (state.verified === false) {
      }
      Navigate(`/investor/companyDetails`, {
        state: {
          deal: deal,
          passedStage: stage,
          isPassed: isPassed,
        },
      });
    }
  }
  const handleHover = () => {
    const ele1 = document.getElementsByClassName("card-with-details")[index];
    const ele2 = document.getElementsByClassName("pending-profile")[index];

    if (ele1 && ele2) {
      ele2.style.height = `${ele1.offsetHeight}px`;
      ele2.style.justifyContent = "center";
    }
    if (role === "company") {
      return;
    }
    if (
      (!investorVerified || !profileCompleted) &&
      startPoint !== "noNameDeals"
    ) {
      setCardClick(true);
      return;
    }
  };
  const handleHoverLeave = () => {
    setCardClick(false);
  };
  //Calculates the no. of tags that can fit in the width
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      // Calculate width of "Recommended" tag if isRecommended is true
      let recommendedWidth = 0;
      if (isRecommended) {
        const recommendedElement = document.createElement("div");
        recommendedElement.style.display = "inline-block";
        recommendedElement.style.visibility = "hidden";
        recommendedElement.style.position = "absolute";
        recommendedElement.className = "Card-div-6";
        recommendedElement.innerHTML = `
          <div>
            <span class="icon"><Bolt /></span> Recommended
          </div>
        `;
        document.body.appendChild(recommendedElement);

        recommendedWidth = recommendedElement.offsetWidth;
        document.body.removeChild(recommendedElement);
      }

      // Adjust container width based on "Recommended" tag
      const availableWidth = isRecommended
        ? containerWidth - recommendedWidth
        : containerWidth;

      cardTags.forEach((tag) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= availableWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags, isRecommended]);
  return (
    <>
      <div
        className="Card-div"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        <ToastContainer position="top-center" containerId={"card-container"} />
        <div
          className="card-with-details"
          style={{ cursor: "pointer", display: cardClick ? "none" : "block" }}
          onClick={() => navigateToCompanyDetails()}
        >
          <div className="card-content-container">
            <div className="card-section card-section-1">
              <div className="Card-div-3">{noNameTitle}</div>

              <div className="Card-div-5" ref={containerRef}>
                {isRecommended && (
                  <div
                    className="Card-div-6 flex items-center text-white"
                    style={{
                      background:
                        "linear-gradient(90deg, #00A6FB 0%, #114FEE 100%)",
                    }}
                  >
                    <Bolt fontSize="13px" />
                    Recommended
                  </div>
                )}
                {visibleTags.map((tag, index) => (
                  <div
                    key={index}
                    className={`Card-div-6 ${
                      dealType === "secondary"
                        ? `text-[--brand-color]`
                        : "blue-text"
                    }`}
                  >
                    {tag}
                  </div>
                ))}
                {remainingTags > 0 && (
                  <div
                    className={`Card-div-7 ${
                      dealType === "secondary"
                        ? `text-[--brand-color]`
                        : "blue-text"
                    }`}
                  >
                    +{remainingTags}
                  </div>
                )}
                {startPoint !== "noNameDeals" && (
                  <img
                    src={isStarFilled ? filledStar : star}
                    className="card-star"
                    alt="star"
                    style={{
                      cursor: "pointer",
                      display: `${isPassed && "none"}`,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleStarClick();
                    }}
                  />
                )}
              </div>
              <div className="Card-div-4">{noNameSubHeading}</div>
            </div>
            <div className="card-section Card-section-2">
              <div className="Card-div-9 Financials">
                {(financialMetrics
                  ? Object.entries(financialMetrics).slice(0, 4)
                  : Array(4).fill(["-", "-"])
                ).map(([key, value], index) => (
                  <React.Fragment key={key || index}>
                    <div className="Card-div-10">
                      <div className="Card-div-11">{value || "-"}</div>{" "}
                      {/* Value or '-' */}
                      <div className="Card-div-12">
                        {key ? key : "-"} {/* Key or '-' */}
                      </div>
                    </div>
                    {index < Object.entries(financialMetrics).length - 1 && (
                      <div style={{ borderRight: "0.5px solid #4642CC" }}></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className="card-section-3">
            <ProgressBar
              totalFundingRaised={fundingRaised || 0}
              fundingRequired={fundingRequired}
              roundType={roundType}
              DealTag={firstTag}
              dealType={dealType}
            />
          </div>
        </div>
        <div
          className="pending-profile"
          style={{ display: cardClick ? "flex" : "none" }}
        >
          <img src={pendingProfile} alt="pending" />
          <p>
            {profileCompleted
              ? "You're on the Waitlist! Hang tight—exciting investment opportunities are just around the corner!"
              : "Please complete your profile to view the details of the company."}
          </p>
          <div
            onClick={() => {
              Navigate("/investor/account", {
                state: {
                  activeButton: 2,
                },
              });
            }}
            style={{
              display: profileCompleted ? "none" : "flex",
            }}
          >
            <CustomBlackBtn text="Complete Profile" type="button" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
