import React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

// Custom Select Component
const CustomSelect = ({
  label,
  name,
  value,
  onChange,
  error,
  options,
  placeholder,
  helperText,
  multiple = false,
  singleAsArray = false, // New custom prop
  ...rest
}) => {
  // Handle change to store single selection as an array if singleAsArray is true
  //needed for round type in company onboarding
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange({
      target: {
        name,
        value: singleAsArray ? [selectedValue] : selectedValue, // Use array only if singleAsArray is true
      },
    });
  };
  return (
    <FormControl
      fullWidth
      error={error}
      {...rest}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "6px",
          borderWidth: "0.5px",
          "& fieldset": {
            borderColor: "#8692A6",
          },
          "&:hover fieldset": {
            borderColor: "#3C82F6",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3C82F6",
          },
        },
      }}
    >
      <Select
        name={name}
        onChange={handleChange}
        multiple={multiple}
        displayEmpty
        value={value}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "300px",
            },
          },
        }}
        renderValue={(selected) => {
          if (selected && selected.length === 0) {
            return <em>{placeholder}</em>;
          } else if (!multiple && !selected) {
            return <em>{placeholder}</em>;
          }
          return multiple ? selected?.join(", ") : selected;
        }}
        sx={{
          padding: "10px 16px",
          fontFamily: "'Karla', sans-serif",
          fontSize: "15px",
          color: value && value.length ? "#000" : "#A0A0A0",
          "& .MuiSelect-select": {
            padding: "3px 4px",
          },
        }}
      >
        <MenuItem disabled value={null}>
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            style={{ fontFamily: "Karla" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Typography
        sx={{
          color: "#d32f2f",
          fontFamily: "Karla, sans-serif",
          fontWeight: "400px",
          fontSize: "0.75rem",
          lineHeight: "1.66",
          letterSpacing: "0.033em",
          textAlign: "left",
          marginTop: "3px",
          marginX: "14px",
          marginBottom: 0,
        }}
      >
        {helperText}
      </Typography>
    </FormControl>
  );
};

export default CustomSelect;
