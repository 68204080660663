import { Box, Modal } from "@mui/material";
import React from "react";
import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { useLoader } from "../../../Common/LoaderProvider";
import Toast from "../../../Common/Toast";

import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

const AcceptConfirmationModal = ({
  acceptanceModal,
  setAcceptanceModal,
  stage,
  dealId,
  investorID,
  getDealInterestByStage,
  fetchAllInteractions,
  postStageData,
  setGreenTickModal,
}) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const acceptIOIBid = async () => {
    try {
      const formData = new FormData();
      formData.append("dealId", dealId);
      formData.append("investorID", investorID);
      formData.append("IsInterested", true);
      formData.append("Stage", "ioiAccepted");

      loader.start();
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setGreenTickModal(true);
        await getDealInterestByStage(dealId, investorID, stage, token);
        fetchAllInteractions();
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to accept Term Sheet due to some internal errors",
        "error",
        "companyAgreement"
      );
      console.error("Error in acceptIOIBid:", error);
    }
  };
  const acceptLOIAgreement = async () => {
    try {
      const formData = new FormData();
      formData.append("dealId", dealId);
      formData.append("investorID", investorID);
      formData.append("IsInterested", true);
      formData.append("LOI_Accepted_Company", true);
      formData.append("Stage", "loiAccepted");

      loader.start();
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setGreenTickModal(true);
        await getDealInterestByStage(dealId, investorID, stage, token);
        fetchAllInteractions();
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to accept Final Bid to some internal errors",
        "error",
        "companyAgreement"
      );
    }
  };
  const handleAccept = () => {
    if (stage === "ioiSubmitted") {
      acceptIOIBid();
    } else if (stage === "loiSubmitted") {
      acceptLOIAgreement();
    }
    setAcceptanceModal(false);
  };

  return (
    <>
      <Modal
        open={acceptanceModal}
        onClose={() => setAcceptanceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "fit-content" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            onClick={() => setAcceptanceModal(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading text-[20px] text-[var(--Primary-Text-Color)]">
                {stage === "ioiSubmitted" && `Term Sheet`}{" "}
                {stage === "loiSubmitted" && `Due Diligence`}
              </p>
            </div>
            <div className="subheading text-[16px] text-[var(--Secondary-Text-Color)]">
              <p>
                Do you want to proceed with accepting the{" "}
                {stage === "ioiSubmitted" && `Term Sheet`}{" "}
                {stage === "loiSubmitted" && `Final Bid`} ?
              </p>
            </div>
          </div>

          <div className="btn-box">
            <CustomBlackBtn
              text="Cancel"
              filled={false}
              onClick={() => setAcceptanceModal(false)}
            />
            <CustomBlackBtn text="Accept" onClick={handleAccept} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AcceptConfirmationModal;
