import React, { useState, useEffect } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";
import {
  getDealDetailsByDealId,
  getDealIdbyStartupId,
  getDealSummaryPageData,
} from "../../../../endpoints/deal";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useRecoilState } from "recoil";
import { companyState } from "../companyState";
import OngoingInvestors from "./ongoingInvestors";
import { useLoader } from "../../../Common/LoaderProvider";
import FiveCards from "../../Overview/FiveCards";
import ActiveInvestorTable from "../../Overview/ActiveInvestorTable";

import { TbLayoutKanban } from "react-icons/tb";
import { CiCircleList } from "react-icons/ci";
// import OngoingInvestors from './ongoingInvestors'
const Fundraising = () => {
  const token = localStorage.getItem("token");
  const { type } = useParams();
  const [investorDetails, setinvestorDetails] = useState({});
  const loader = useLoader();
  const [companyData, setCompanyData] = useRecoilState(companyState);
  const [name, setName] = useState("");
  const [startupId, setStartupId] = useState("");
  const [deal, setDeal] = useState(null);
  const [dealId, setDealId] = useState("");
  const [data, setData] = useState(null);
  const [activeSection, setActiveSection] = useState("kanban");
  const open = localStorage.getItem("isOpen");
  useEffect(() => {
    if (token) {
      const newData = {
        ...companyData,
      };
      setCompanyData(newData);
      localStorage.setItem("companyState", JSON.stringify(newData));
    }
  }, [token]);

  //For startupId
  const fetchDetails = async () => {
    let response;
    if (token) {
      response = await fetchStartupMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setStartupId(response.data.data.startupId);
      } else {
        loader.stop();
      }
    }
  };
  const fetchDeals = async () => {
    try {
      const response = await getDealIdbyStartupId(startupId, token);
      setDealId(response.data.dealId);
      const newData = {
        ...companyData,
        dealId: response.data.dealId,
      };
      setCompanyData(newData);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (startupId) {
      fetchDeals();
    }
  }, [startupId, token]);
  useEffect(() => {
    loader.start("Fetching Deals...");
    if (token) fetchDetails();
  }, [token]);
  const getDealData = async () => {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      if (response) {
        setDeal(response.data);
      }
    } catch {}
  };
  const getData = async () => {
    try {
      const response = await getDealSummaryPageData(dealId);
      loader.stop();
      if (response) {
        setData(response);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (dealId) getData();
  }, [dealId]);
  useEffect(() => {
    if (dealId) getDealData();
  }, [dealId]);
  return (
    <div className="InvestorSide onGoingDeals-section newOngoingDeals">
      {/* <ToastContainer position="top-center" containerId="fundraising-id" /> */}
      <Sidebar active="ongoingDeals" type={type} />
      <Topbar title="Fundraising" />
      <Helmet>
        <title>Company | Fundraising</title>
      </Helmet>
      <div className="InvestorSide-box">
        <div className="InvestorSide-content company-ongoing-deals">
          {type === "current" && (
            <div className="w-[98%] ml-[10px]">
              <FiveCards data={data} fundingRequired={deal?.fundingRequired} />
            </div>
          )}

          <div className="flex justify-between my-[16px] items-center w-[98%] ml-[10px]">
            <p className="text-[var(--Secondary-Text-Color)] text-[16px] ">
              {type === "current" ? "Active Investors" : "Inactive Investors"}
            </p>
            <div className="flex flex-row gap-[10px] ">
              <button
                onClick={() => setActiveSection("kanban")}
                className={`view-type-btn ${
                  activeSection === "kanban" ? "active-view-type-button" : ""
                }`}
              >
                <TbLayoutKanban
                  className={
                    activeSection === "kanban"
                      ? "active-view-type-button-img"
                      : ""
                  }
                />
              </button>
              <button
                onClick={() => setActiveSection("list")}
                className={`view-type-btn ${
                  activeSection === "list" ? "active-view-type-button" : ""
                }`}
              >
                <CiCircleList
                  className={
                    activeSection === "list"
                      ? "active-view-type-button-img"
                      : ""
                  }
                />
              </button>
            </div>
          </div>
          {activeSection === "kanban" ? (
            <OngoingInvestors
              fundingRequired={deal?.fundingRequired}
              dealId={dealId}
              investorDetails={investorDetails}
              setinvestorDetails={setinvestorDetails}
            />
          ) : (
            <>
              {data && (
                <ActiveInvestorTable
                  data={data}
                  investorDetails={investorDetails}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Fundraising;
