import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Topbar from "./Deal/topbar";
import Sidebar from "./Deal/Sidebar";
import { getStartupDetails } from "../../../endpoints/admin";
import { Box } from "@mui/material";
import { handleFileDownload } from "../../Common/functions";
import CompanyUploadedFiles from "./companyUploadedFiles";
import TeamMemberCard from "../teamMemeberCard";

const AdminCompanyProfile = () => {
  const location = useLocation();
  const { dealId, startupId } = location.state;
  const [data, setData] = useState(null);
  const [videoURL] = useState(null);
  const token = localStorage.getItem("token");
  const arr = [
    "pitchDeck",
    "videoPitch",
    "financialDocuments",
    "invoiceDetails",
    "bankStatements",
    "corporateDocuments",
    "teamDetails",
    "productTechnology",
    "marketClientInfo",
    "legalComplianceDetails",
    "financialPlanning",
    "summary",
    "businessPlan",
    "competitorAnalysis",
    "incomeStatement",
    "balanceSheet",
    "cashFlowStatement",
    "auditReport",
    "mis",
    "financialModel",
    "invoiceDetail",
    "salesPipelineMetrics",
    "marketingExpenses",
    "profitabilityMetrics",
    "otherMetrics",
    "directorsProfiles",
    "managementTeamProfile",
    "organizationChart",
    "memorandum",
    "certificateIncorporation",
    "taxIdentificationDocuments",
    "sharePurchaseAgreements",
    "debtAgreements",
    "taxReturns",
    "employeeCensus",
    "employeeStockOptionPlan",
    "employmentAgreements",
    "productOverview",
    "productRoadmap",
    "recordedProductDemo",
    "trademarks",
    "patents",
    "keyCustomerContracts",
    "keyVendorContracts",
    "securityContracts",
    "otherMaterialAgreement",
  ];

  const getStartupData = async () => {
    try {
      if (token) {
        const response = await getStartupDetails(startupId, token);
        setData(response.data);
        const fields = [];

        const newFileInfo = {};

        const fetchBlobName = (url) => {
          try {
            const str = decodeURIComponent(url.split("/").pop());
            const lastIndex = str.lastIndexOf("_");
            if (lastIndex === -1 || str.indexOf("_") === lastIndex) {
              return str;
            }
            const result = str.replace(/_\d{8}_\d{6}/, "");
            return result;
          } catch (error) {
            console.error("Error extracting file name:", error);
            return "";
          }
        };
        const fetchFullFileName = (url) => {
          try {
            const str = decodeURIComponent(url.split("/").pop());
            return str;
          } catch (error) {
            console.error("Error extracting file name:", error);
            return "";
          }
        };
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStartupData();
  }, [startupId]);
  useEffect(() => {}, [videoURL]);
  const parseArray = (arr) => {
    try {
      return JSON.parse(arr);
    } catch (error) {
      return [];
    }
  };

  return (
    <>
      <div className="dealDetailsEdit">
        <Helmet>
          <title>Admin Panel - Company Profile</title>
        </Helmet>
        <Topbar title={`Company Profile`} />
        <Sidebar
          dealId={dealId}
          active={"companyProfile"}
          startupId={startupId}
        />
        <div className="dealDetailsEdit-box">
          {data && (
            <>
              <p className="investorDetails-heading">
                Company Details - {data.companyName}
              </p>
              <Box
                sx={{
                  margin: 0,
                  width: "100% !important",
                  padding: "10px",
                }}
                className="investorDetails sixth-step"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      Object?.entries(data)?.map(([key, value], index) => {
                        let document = false;
                        let foundingTeam = false;
                        let dealTeam = false;

                        if (
                          [
                            "pitchDeck",
                            "videoPitch",
                            "financialDocuments",
                            "invoiceDetails",
                            "bankStatements",
                            "corporateDocuments",
                            "teamDetails",
                            "productTechnology",
                            "marketClientInfo",
                            "legalComplianceDetails",
                            "financialPlanning",
                          ].includes(key)
                        ) {
                          document = true;
                        }
                        if (
                          key === "foundingMember" ||
                          key === "dealTeam" ||
                          arr.includes(key)
                        ) {
                          return null;
                        }
                        return (
                          <tr key={index}>
                            <td
                              className={
                                document && `text-[var(--brand-color)]`
                              }
                            >
                              {key}
                            </td>
                            {!foundingTeam && !dealTeam && (
                              <td
                                style={{
                                  maxWidth: "600px", // adjust as needed
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  overflow: "hidden",
                                  color: document && `var(--link-color)`,
                                }}
                              >
                                {!document && (value?.toString() || `-`)}
                                {document &&
                                  value.map((doc) => (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleFileDownload(doc)}
                                    >
                                      {doc}
                                      <br />
                                    </div>
                                  ))}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Box>
              <CompanyUploadedFiles startupId={startupId} />
              <p className="investorDetails-subheading">
                Founding Team Details
              </p>
              <Box
                sx={{
                  padding: 2,
                  display: "grid",
                  gap: "16px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                {data?.foundingMember &&
                  (data?.foundingMember).map((member, index) => (
                    <TeamMemberCard
                      key={index}
                      member={member}
                      foundingTeam={true}
                      role="company"
                    />
                  ))}
              </Box>

              <p className="investorDetails-subheading">Deal Team Details</p>
              <Box
                sx={{
                  padding: 2,
                  display: "grid",
                  gap: "16px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                {data?.dealTeam &&
                  data?.dealTeam.map((member, index) => (
                    <TeamMemberCard
                      key={index}
                      member={member}
                      role="company"
                    />
                  ))}
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCompanyProfile;
