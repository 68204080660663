import React, { useEffect, useState } from "react";
import Card from "../../../Common/components/Card";
import NoDeals from "../Components/noDeals";

import { Helmet } from "react-helmet";

const DealsBox = ({
  allDeals,
  filterDeals,
  details,
  title,
  setWishlistDealIds,
  stage,
  wishlistDealIds,
  recommendedDeals,
  isPassed = false,
}) => {
  const pageTitle = title || "deals";
  const [deals, setDeals] = useState([]);

  const isRecommended = (deal) => {
    if (recommendedDeals?.length > 0) {
      return recommendedDeals.some(
        (recommendedDeal) => recommendedDeal.dealId === deal.dealId
      );
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Filter and sort deals
    const filteredDeals = filterDeals
      .filter((deal) =>
        allDeals.some((filteredDeal) => filteredDeal.dealId === deal.dealId)
      )
      .sort((a, b) => {
        const aRecommended = isRecommended(a);
        const bRecommended = isRecommended(b);
        // Recommended deals should come first
        if (aRecommended && !bRecommended) return -1;
        if (!aRecommended && bRecommended) return 1;
        return 0;
      });

    setDeals(filteredDeals);
  }, [allDeals, filterDeals, recommendedDeals]);

  return (
    <>
      <Helmet>
        <title>Investor | {pageTitle}</title>
      </Helmet>
      {deals.length > 0 ? (
        <div className="deals-box">
          {deals.map((deal, index) => (
            <Card
              key={deal.organizationId}
              isNew={false}
              investorId={details?.investorID}
              isStar={wishlistDealIds?.includes(deal.dealId) ? true : false}
              isPassed={isPassed}
              deal={deal}
              stage={stage}
              investorVerified={details?.isApplicationReviewed}
              profileCompleted={details?.profileCompleted}
              index={index}
              isRecommended={isRecommended(deal)}
              setWishlistDealIds={setWishlistDealIds}
            />
          ))}
        </div>
      ) : (
        <>
          <NoDeals text={title} />
        </>
      )}
    </>
  );
};

export default DealsBox;
