import * as React from "react";
import Ripple from "../../Common/animations/ripple";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import fundrevLogo from "../../../Assets/Images/LandingPage/logo.svg";

function ElevateExperience() {
  return (
    <div className="flex flex-col rounded-none">
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex relative flex-col items-center px-20 py-32 w-full max-h-[900px] 2xl:max-h-[1200px] max-md:px-5 max-md:py-24 max-md:max-w-full bg-[var(--Primary-Text-Color)]">
          <HeroContent />
          <Ripple className=" h-[1100px] md:h-[1068px] lg:h-[1280px] 2xl:h-[1550px]" />
        </div>
      </div>
    </div>
  );
}

function HeroContent() {
  const Navigate = useNavigate();
  return (
    <div className="flex relative flex-col items-center mb-0 max-w-full w-[490px] 2xl:w-[610px] max-md:mb-2.5">
      <div className="w-[90%] md:w-full text-[28px] md:text-h2 2xl:text-res-h2 font-medium text-center text-white md:leading-[63px] max-md:max-w-full">
        Elevate your experience with Fundrev
      </div>
      <div className="self-stretch mt-10 text-base text-center text-white 2xl:mt-12 2xl:text-res-base text-opacity-80 max-md:mt-10 max-md:max-w-full">
        Participate in the cutting-edge private capital investment opportunities
        available on Fundrev
      </div>
      <CustomBlackBtn
        text={
          <>
            <p>Get Started</p>
            <ArrowRightAltIcon />
          </>
        }
        mode="blue"
        border="sm"
        style={{
          zIndex: 20,
          marginTop: "50px",
        }}
        onClick={() => Navigate("/signup")}
      />
      <CircleLogo />
    </div>
  );
}
function CircleLogo() {
  return (
    <div className="flex flex-col justify-center items-center px-9 mt-32 md:mt-28 2xl:mt-60 bg-white rounded-full border-solid border-[9px] border-white border-opacity-10 h-[116px] w-[116px] md:h-[174px] md:w-[174px] max-md:px-5 ">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="object-contain aspect-[0.68] md:w-[62px]"
        alt="Fundrev logo"
      />
    </div>
  );
}

export default ElevateExperience;
