import React from "react";

const CustomBlackBtn = ({
  type = "button",
  text,
  mode = "dark",
  border = "md",
  filled = true,
  onClick,
  disabled,
  width = "fit-content",
  variant = "md",
  textWeight = "md",
  additionalClasses = "",
  style = {},
}) => {
  // Define Tailwind classes for different modes
  const colorOptions = {
    dark: {
      filled: "bg-[#002132] hover:bg-[#00445E] text-white border-[#002132]",
      outlined:
        "bg-transparent hover:bg-gray-200 text-[#002132] border border-[#002132]",
    },
    light: {
      filled:
        "bg-transparent hover:bg-gray-200 text-[#002132] border border-[#002132]",
      outlined:
        "bg-transparent hover:bg-gray-200 text-[#002132] border border-[#002132]",
    },
    danger: {
      filled: "bg-[#d9534f] hover:bg-[#c9302c] text-white border-[#d9534f]",
      outlined:
        "bg-transparent hover:bg-[#d9534f] text-[#d9534f] hover:text-[#fff] border border-[#d9534f]",
    },
    success: {
      filled: "bg-[#5cb85c] hover:bg-[#4cae4c] text-white border-[#5cb85c]",
      outlined:
        "bg-transparent hover:bg-[#5cb85c] text-[#5cb85c] hover:text-[#fff] border border-[#5cb85c]",
    },
    blue: {
      filled: "bg-[#407BFF] hover:bg-[#305FBF] text-white border-[#407BFF]",
      outlined:
        "bg-transparent hover:bg-[#407BFF] text-[#407BFF] hover:text-[#fff] border border-[#407BFF]",
    },
  };

  // Define Tailwind classes for different sizes
  const sizeClasses = {
    sm: "h-10 px-4 text-xs md:text-sm",
    md: "h-12 px-6 text-sm md:text-base",
  };
  //Can use this when we want to change every style for larger screen
  // const sizeClasses = {
  //   sm: "h-10 px-4 text-xs md:text-sm 2xl:h-[50px] 2xl:px-5 2xl:text-res-sm",
  //   md: "h-12 px-6 text-sm md:text-base 2xl:px-[30px] 2xl:h-[60px]  2xl:text-res-base",
  // };
  // Define Tailwind classes for different border radius
  const borderRadiusClasses = {
    sm: "rounded-[8px]",
    md: "rounded-[100px]",
    lg: "rounded-[24px]",
  };

  // Define font weight classes
  const textWeightClasses = {
    sm: "font-light",
    md: "font-normal",
    lg: "font-semibold",
  };

  const baseClasses = `
    flex items-center justify-center gap-2
    transition-all duration-300 ease-in-out
    ${disabled ? "opacity-60 cursor-not-allowed" : "cursor-pointer"}
  `;

  const sizeClass = sizeClasses[variant];
  const modeClasses = colorOptions[mode][filled ? "filled" : "outlined"];
  const borderRadius = borderRadiusClasses[border];
  const fontWeight = textWeightClasses[textWeight];

  return (
    <button
      type={type}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={`text- ${baseClasses} ${modeClasses} ${sizeClass} ${borderRadius} ${fontWeight} ${additionalClasses}`}
      style={{
        width: width === "fit-content" ? "fit-content" : width,
        ...style,
      }}
    >
      {text}
    </button>
  );
};

export default CustomBlackBtn;
