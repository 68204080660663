import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
// import { companyState, saveState } from "../companyState";
import { companyState, saveState } from "../companyState";
import { getProfileCompletionPercentage } from "../../../../endpoints/startup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Slide } from "@mui/material";

import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import fundrevLogoSmall from "../../../../Assets/Images/InvestorPage/Dashboard/fundrevLogoSmall.svg";
import verifiedIcon from "../../../../Assets/Images/InvestorPage/Dashboard/verified.svg";
import newDealsSidebar from "../../../../Assets/Images/InvestorPage/Dashboard/newDealsSidebar.svg";
import profileSidebar from "../../../../Assets/Images/InvestorPage/Dashboard/profileSidebar.svg";
import featureRequestSidebar from "../../../../Assets/Images/InvestorPage/Dashboard/featureRequestSidebar.svg";
import dealFlowSidebar from "../../../../Assets/Images/InvestorPage/Dashboard/dealFlowSidebar.svg";
import { IoIosArrowDown } from "react-icons/io";
import { TbPointFilled } from "react-icons/tb";

import "../../../../CSS/InvestorPage/Dashboard/newSidebar.css";
import styles from "../../../../CSS/InvestorPage/Dashboard/sidebar.module.css";

const Sidebar = ({ active, type }) => {
  const Navigate = useNavigate();
  const [isDealFlowOpen, setIsDealFlowOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { wishListOpen, percentage } = useRecoilValue(companyState);
  const [state, setState] = useRecoilState(companyState);
  const [verified, setVerified] = useState(false);
  const [percentComplete, setPercentComplete] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const ToggleWishList = () => {
    const newState = { ...state, wishListOpen: !wishListOpen };
    setState(newState);
    saveState(newState);
  };

  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);

  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;
      setPercentComplete(percent);
      setVerified(response.data.verified);
      const newState = {
        ...state,
        percentComplete: percent,
        percentage: percent,
        verified: response.data.verified,
      };
      setState(newState);
      saveState(newState);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    profileCompletionPercentage();
  }, []);
  useEffect(() => {
    if (active === "ongoingDeals") {
      setIsDealFlowOpen(true);
    }
  }, [active]);
  return (
    <>
      <Slide direction="right" in={sidebarVisible} mountOnEnter unmountOnExit>
        <div
          className={styles.div}
          onMouseEnter={() => setSidebarVisible(true)}
          onMouseLeave={() => setSidebarVisible(false)}
        >
          <div className={styles.background}>
            <div className={styles.div2}>
              <div className={styles.div4}>
                <Link to="/company/dashboard" className="opimg">
                  <img
                    loading="lazy"
                    src={fundrevLogo}
                    style={{ cursor: "pointer", height: "23px" }}
                    alt="fundrevLogo"
                  />
                </Link>
              </div>
              <Link to="/company/dashboard">
                <div
                  className={active === "overview" ? styles.div3 : styles.div5}
                >
                  <div className={styles.text}>
                    <img src={newDealsSidebar} alt="new Deals" />
                    Overview
                  </div>
                </div>
              </Link>

              <div
                className={styles.div5}
                onClick={() => setIsDealFlowOpen(!isDealFlowOpen)}
              >
                <div className={styles.text}>
                  <img src={dealFlowSidebar} alt="deal flow" />
                  Fundraising
                </div>
                <IoIosArrowDown
                  className={`${styles.img} ${
                    isDealFlowOpen ? styles.rotate : ""
                  }`}
                />
              </div>

              <div
                className={`${styles.innerContent} ${
                  isDealFlowOpen ? styles.open : styles.closed
                }`}
              >
                <Link to="/company/ongoingDeals/current">
                  <div
                    className={
                      active === "ongoingDeals" &&
                      (type === "current" || type === undefined)
                        ? styles.div3
                        : styles.div4
                    }
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className={styles.text}>
                      <TbPointFilled />
                      Active Investors
                    </div>
                  </div>
                </Link>
                <Link to="/company/ongoingDeals/dead">
                  <div
                    className={
                      active === "ongoingDeals" && type === "dead"
                        ? styles.div3
                        : styles.div4
                    }
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className={styles.text}>
                      <TbPointFilled />
                      Inactive Investors
                    </div>
                  </div>
                </Link>
                <Link to="/company/ongoingDeals/watchlist">
                  <div
                    className={
                      active === "ongoingDeals" && type === "watchlist"
                        ? styles.div3
                        : styles.div4
                    }
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className={styles.text}>
                      <TbPointFilled />
                      Watchlist Investors
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className={active === "profile" ? styles.div3 : styles.div4}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Navigate("/company/editProfile", {
                    state: {
                      activeButton: 2,
                    },
                  });
                }}
              >
                <div className={styles.text}>
                  <img src={profileSidebar} alt="profile" />
                  Profile
                </div>
                {loading ? (
                  <div>Loading...</div>
                ) : verified && (percentage || percentComplete) === "100" ? (
                  <img src={verifiedIcon} alt="Verified" />
                ) : (
                  <div
                    style={{
                      position: "relative",
                      width: "36px",
                      height: "36px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage || percentComplete || 0}
                      styles={buildStyles({
                        pathColor:
                          (percentage || percentComplete) === "100"
                            ? "#00CD9E"
                            : "#FF9E2C",
                        trailColor: "#ebeff5",
                        strokeLinecap: "butt",
                      })}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor:
                          (percentage || percentComplete) === "100"
                            ? "#00CD9E"
                            : "#FF9E2C",
                        borderRadius: "50%",
                        width: "26px",
                        height: "26px",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {`${percentage || percentComplete || 0}%`}
                    </div>
                  </div>
                )}
              </div>
              <a
                href="https://fundrev.frill.co/"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.div5}>
                  <div className={styles.text}>
                    <img src={featureRequestSidebar} alt="Feature Request" />
                    Feature Request
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Slide>
      <div
        className={styles.div11}
        onMouseEnter={() => setSidebarVisible(true)}
        onMouseLeave={() => setSidebarVisible(false)}
      >
        <div className={styles.background}>
          <div className={styles.div2}>
            <div className={styles.div4}>
              <img
                loading="lazy"
                src={fundrevLogoSmall}
                style={{ cursor: "pointer", height: "23px" }}
                alt="logoSmall"
              />
            </div>

            <div className={active === "overview" ? styles.div3 : styles.div4}>
              <div className={styles.text}>
                <img src={newDealsSidebar} alt="overview" />
              </div>
            </div>
            <div
              className={active === "ongoingDeals" ? styles.div3 : styles.div5}
            >
              <div className={styles.text}>
                <img src={dealFlowSidebar} alt="deal flow" />
              </div>
            </div>
            <div className={active === "profile" ? styles.div3 : styles.div5}>
              <div className={styles.text}>
                <img src={profileSidebar} alt="profile" />
              </div>
            </div>
            <div className={styles.div4}>
              <div className={styles.text}>
                <img src={featureRequestSidebar} alt="Feature Request" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
