import React, { useEffect, useState } from "react";
import { stages } from "../../Common/functions";
import {
  getAllDealInteractions,
  getDealByStage,
} from "../../../endpoints/deal";

import { useNavigate } from "react-router-dom";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import Typography from "@mui/joy/Typography";

import AdjustIcon from "@mui/icons-material/Adjust";

import tick from "../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Tick.svg";

import "../../../CSS/FounderPage/Dashboard/timeline.css";

const TimelineComponent = ({
  passedStage,
  dealId,
  investorID,
  companyName,
  role,
}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [allInterest, setAllInterest] = useState([]);
  const passedStageIndex = stages.indexOf(passedStage);

  useEffect(() => {
    const fetchAllInterest = async () => {
      try {
        const response = await getAllDealInteractions(
          dealId,
          investorID,
          token
        );
        setAllInterest(response.data);
      } catch (error) {
        console.error("Failed to fetch deal interactions:", error);
      }
    };
    if (dealId && investorID) fetchAllInterest();
  }, [dealId, investorID, token, passedStage]);

  const handleStageClick = async (stage) => {
    const navigateMap = {
      investor: {
        ioiSubmitted: "/investor/TermSheet",
        loiSubmitted: "/investor/dueDiligence",
        spaNegotiation: "/investor/spaNegotiation",
      },
      company: {
        ioiSubmitted: "/company/termSheet",
        loiSubmitted: "/company/dueDiligence",
        spaNegotiation: "/company/spaNegotiation",
      },
    };

    if (navigateMap[role] && navigateMap[role][stage]) {
      if (role === "investor") {
        const response = await getDealByStage(dealId, token);
        if (response && response.data) {
          navigate(navigateMap[role][stage], {
            state: {
              stage,
              dealId,
              investorID,
              companyName,
              deal: response.data,
              fundingRequired: response.data.fundingRequired,
              minPostMoneyValuation: response.data.minPostMoneyValuation,
            },
          });
        } else {
          // console.error("Failed to fetch deal data. Response:", response.data);
        }
      } else {
        navigate(navigateMap[role][stage], {
          state: { stage, dealId, investorID, companyName },
        });
      }
    }
  };

  return (
    <div className="fundrev-card w-[260px] h-fit">
      <p
        style={{
          color: "var(--Primary-Text-Color)",
          fontSize: "20px",
          marginBottom: "16px",
          fontWeight: "500",
        }}
      >
        Timeline
      </p>
      <Stepper
        orientation="vertical"
        sx={(theme) => ({
          "--Stepper-verticalGap": "1.4rem",
          "--StepIndicator-size": "1.6rem",
          "--Step-gap": "0.5rem",
          "--Step-connectorInset": "0.2rem",
          "--Step-connectorRadius": "1rem",
          "--Step-connectorThickness": "3px",
          "--joy-palette-success-solidBg": "var(--timeline-color)",
          [`& .${stepClasses.completed}`]: {
            "&::after": { bgcolor: "success.solidBg" },
          },
          [`& .${stepClasses.active}`]: {
            [`& .${stepIndicatorClasses.root}`]: {
              border: "10px solid",
              borderColor: "#fff",
              boxShadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
            },
          },
          [`& .${stepClasses.disabled} *`]: {
            color: "neutral.softDisabledColor",
          },
        })}
      >
        {stages.slice(0, stages.length - 1).map((stage, index) => (
          <Step
            key={index}
            completed={index <= passedStageIndex}
            indicator={
              index <= passedStageIndex ? (
                <StepIndicator
                  variant="solid"
                  sx={{
                    color: "var(--timeline-color)",
                    backgroundColor: "#fff",
                  }}
                >
                  {/* <CheckCircleIcon sx={{ fontSize: "1.8rem" }} /> */}
                  <img src={tick} alt="completed" />
                </StepIndicator>
              ) : (
                <StepIndicator
                  sx={{
                    color: "#B9BBBD",
                    backgroundColor: "#fff",
                  }}
                >
                  <AdjustIcon sx={{ fontSize: "1.8rem" }} />
                </StepIndicator>
              )
            }
          >
            <div>
              {allInterest &&
                allInterest.some((item) => item.Stage === stage) && (
                  <Typography
                    level="body-sm"
                    sx={{
                      color: "var(--Secondary-Text-Color)",
                      fontSize: "12px",
                      fontFamily: "Karla",
                    }}
                  >
                    {new Date(
                      allInterest.find((item) => item.Stage === stage).createdAt
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </Typography>
                )}
              <Typography sx={{ fontSize: "14px", fontFamily: "Karla" }}>
                {stage
                  .replace("requestSent", "Request Received")
                  .replace("ioiSubmitted", "Term Sheet Received")
                  .replace("loiSubmitted", "Final Bid Received")
                  .replace("requestAccepted", "Request Accepted")
                  .replace("ioiAccepted", "Term Sheet Accepted")
                  .replace("loiAccepted", "Final Bid Accepted")
                  .replace("spaNegotiation", "SPA Negotiation")
                  .replace("dealClosed", "Deal Closed")}
              </Typography>
              <Typography>
                {(stage === "ioiSubmitted" ||
                  stage === "loiSubmitted" ||
                  stage === "spaNegotiation") &&
                  index <= passedStageIndex && (
                    <p
                      className="text-xs text-[var(--timeline-color)] h-[0px] cursor-pointer font-bold"
                      onClick={() => handleStageClick(stage)}
                    >
                      View Details
                    </p>
                  )}
              </Typography>
            </div>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default TimelineComponent;
