import React, { useState, useEffect, useRef } from "react";
import { getAllNoNameDeals } from "../../../endpoints/deal";
import Card from "../../Common/components/Card";
import Header from "../../LandingPage/sections/header";
import Footer from "../../LandingPage/Footer";
import NoDeal from "../../Investor/Dashboard/Opportunities/NoDeal";
import Toast from "../../Common/Toast";
import DealsFilterBox from "../../Common/components/DealsFilterBox";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../../../CSS/InvestorPage/noNameDeals.css";
import { FiFilter } from "react-icons/fi";
import { useLoader } from "../../Common/LoaderProvider";
import { ShinyButton } from "../../Common/animations/shinyButton";
import frontSectionBg from "../../../Assets/Images/LandingPage/frontSectionBg.svg";
import { TopInvestors } from "../../LandingPage/sections/topInvestors";

const NoNameDeals = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const elementRef = useRef(null);
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const loader = useLoader();
  async function getNoNameDeals() {
    try {
      loader.start();
      const data = await getAllNoNameDeals(token);
      setDeals(data.data);
      loader.stop();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
      loader.stop();
    }
  }
  useEffect(() => {
    getNoNameDeals();
  }, [token]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) setFilterBoxOpen(false);
  }, [windowWidth]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getRequiredHeight = () => {
    const ele = document.getElementsByClassName("Financials");
    const ele2 = document.getElementsByClassName("Card-div");
    const box = document.getElementsByClassName("investor-noName-deals");
    const len = ele.length;

    if (len > 4) {
      const rect = ele[4].getBoundingClientRect();
      const rect2 = ele2[0].getBoundingClientRect();
      const height = rect.top - rect2.top;
      if (box.length > 0) {
        box[0].style.height = height + 70 + "px";
      }
    } else if (window.innerWidth < 768 && len > 0) {
      const cards = Array.from(document.getElementsByClassName("Card-div"));
      let totalHeight = 0;
      if (cards.length > 0) {
        cards.forEach((card) => {
          totalHeight += card.offsetHeight;
        });
      }
      if (box.length > 0) {
        box[0].style.height = totalHeight + 50 + "px";
      }
    } else if (window.innerWidth < 768 && len === 0) {
      if (box.length > 0) {
        box[0].style.height = 0 + "px";
      }
    }
  };

  // Execute when the document is fully loaded
  window.onload = () => {
    getRequiredHeight();
  };
  useEffect(() => {
    getRequiredHeight();
  });
  return (
    <section
      className="open-listing-page"
      style={{
        backgroundImage: `url(${frontSectionBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
    >
      <Helmet>
        <title>Investor | Deals</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="noNameDeals" />
      <Header />
      <div className="investor-noName-deals">
        <div>
          <div className="noNameDeal-first-section">
            <p className="noNameDeal-heading">
              Find highly qualified investments on Fundrev
            </p>
            <button
              className="InvestorSide-content-button-1 "
              onClick={() => setFilterBoxOpen(!filterBoxOpen)}
              style={{
                backgroundColor: filterBoxOpen ? "#114fee" : "#fff",
                color: filterBoxOpen ? "#fff" : "#114fee",
                transition: "all 0.3s ease",
              }}
            >
              <FiFilter />
            </button>
            {/* <div className="mobile-stats">
              <button
                className="InvestorSide-content-button-1 mobile-stats"
                onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                style={{ padding: "10px 20px", borderRadius: "10px" }}
              >
                <CiFilter />
              </button>
            </div> */}
          </div>

          <DealsFilterBox
            deals={deals}
            setFilteredDeals={setFilteredDeals}
            filterBoxOpen={filterBoxOpen}
            shouldUpdateUrl={true}
          />
        </div>
        <div className="opportunites-display">
          {filteredDeals && filteredDeals.length > 0 ? (
            filteredDeals.map((deal) => (
              <Card
                startPoint="noNameDeals"
                key={deal.dealId}
                isNew={false}
                deal={deal}
                elementRef={elementRef}
              />
            ))
          ) : windowWidth > 768 ? (
            <NoDeal />
          ) : (
            <></>
          )}
        </div>
      </div>
      <section className="scroll-mt-[70px]" ref={elementRef}>
        <div className="noName-signup-box flex overflow-hidden justify-center items-center px-14 py-14 bg-white border-solid backdrop-blur-[200px] max-md:px-6 border-t-[2px] border-[#000] border-opacity-40 ">
          <div className="flex flex-col justify-center items-center self-stretch my-auto min-w-[240px] w-[1191px]">
            <div className="flex flex-col w-full max-w-[1191px] max-md:max-w-full">
              <div className="flex flex-wrap items-center w-full gap-6 mt-4 max-md:max-w-full">
                <TopInvestors page={"openListing"} />
              </div>
            </div>
            <div className="flex flex-col items-center mt-16 max-w-full text-base font-medium tracking-tighter text-white w-[783px] max-md:mt-5">
              <Link to="/investor/Register">
                {/* <ShimmerButtonDemo text="Join Now" /> */}
                <ShinyButton
                  className="rounded-full h-12 px-6 py-3 max-[576px]:py-2.5 max-[576px]:h-10"
                  // front={true}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Join Now
                  </span>
                </ShinyButton>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="landing-page ">
        <Footer />
      </div>
    </section>
  );
};

export default NoNameDeals;
