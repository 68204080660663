import React, { useState } from "react";
import CardTags from "../../../Common/components/molecules/cardTags";
import ProgressBar from "../../../Common/components/molecules/progressBar";

const CompanyOverviewLeftCard = ({ deal }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const maxLength = 135;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="NoName-heading-block">
      <div className="detailed-page-img-block">
        <img src={deal ? deal.companyLogo : ""} alt="Company logo" />
        <p className="noName-heading">{deal ? deal.companyName : ""}</p>
      </div>
      <p className="noName-subheading">
        <span>
          {deal &&
            deal?.noNameSubHeading &&
            deal.noNameSubHeading.length > maxLength && (
              <>
                {isExpanded
                  ? deal.noNameSubHeading
                  : deal.noNameSubHeading.substr(
                      0,
                      deal.noNameSubHeading.lastIndexOf(" ", maxLength)
                    )}
                {isExpanded ? (
                  <span
                    onClick={toggleExpand}
                    style={{ color: "#00A6FB", cursor: "pointer" }}
                  >
                    {" show less"}
                  </span>
                ) : (
                  <span
                    onClick={toggleExpand}
                    style={{ color: "#00A6FB", cursor: "pointer" }}
                  >
                    {"... more"}
                  </span>
                )}
              </>
            )}
          {deal &&
            deal?.noNameSubHeading &&
            deal.noNameSubHeading.length <= maxLength && (
              <span>{deal.noNameSubHeading}</span>
            )}
        </span>
      </p>
      <CardTags deal={deal} />
      <div style={{ width: "100%" }}>
        <ProgressBar
          totalFundingRaised={deal?.fundingRaised}
          fundingRequired={deal?.fundingRequired}
          roundType={deal?.roundType}
        />
      </div>
    </div>
  );
};

export default CompanyOverviewLeftCard;
