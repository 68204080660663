import tick from "../../../Assets/Images/signup/tick.svg";
import DealAnalystDetails from "./dealAnalystDetails";
const StepProgress = ({
  currentStep,
  stepsCompleted,
  fundrevAnalystID,
  startupId,
  onBoardingComplete,
  fetchMembersData,
}) => {
  const stepNames = [
    "Company Details",
    "Founding Team",
    "Funding Details",
    "Dataroom Preparation",
    "Accept T&C",
    "Pitch Review",
    "Go Live",
    "Fundrev Review",
    "Deal Team",
    "Video Pitch",
  ];
  const steps = [
    "step1",
    "step2",
    "step3",
    "step4",
    "step5",
    "step6",
    "step7",
    "step8",
    "step9",
    "step10",
  ];
  function stepClick(stepNumber) {
    if (onBoardingComplete || currentStep === 7) {
      return;
    }
    if (stepNumber === 6) {
      fetchMembersData();
    }
    document.getElementsByClassName("first-step")[0].style.display = "none";
    document.getElementsByClassName("second-step")[0].style.display = "none";
    document.getElementsByClassName("third-step")[0].style.display = "none";
    document.getElementsByClassName("fourth-step")[0].style.display = "none";
    document.getElementsByClassName("fifth-step")[0].style.display = "none";
    document.getElementsByClassName("sixth-step")[0].style.display = "none";
    document.getElementsByClassName("seventh-step")[0].style.display = "none";
    document.getElementsByClassName("eighth-step")[0].style.display = "none";
    document.getElementsByClassName("ninth-step")[0].style.display = "none";
    document.getElementsByClassName("eleventh-step")[0].style.display = "none";

    switch (stepNumber) {
      case 1:
        document.getElementsByClassName("first-step")[0].style.display = "flex";
        break;
      case 2:
        document.getElementsByClassName("second-step")[0].style.display =
          "flex";
        break;
      case 3:
        document.getElementsByClassName("third-step")[0].style.display = "flex";
        break;
      case 4:
        document.getElementsByClassName("fourth-step")[0].style.display =
          "flex";
        break;
      case 5:
        document.getElementsByClassName("fifth-step")[0].style.display = "flex";
        break;
      case 6:
        document.getElementsByClassName("sixth-step")[0].style.display = "flex";
        break;
      case 7:
        document.getElementsByClassName("seventh-step")[0].style.display =
          "flex";
        break;
      case 8:
        document.getElementsByClassName("eighth-step")[0].style.display =
          "flex";
        break;
      case 9:
        document.getElementsByClassName("ninth-step")[0].style.display = "flex";
        break;
      case 10:
        document.getElementsByClassName("eleventh-step")[0].style.display =
          "flex";
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="companyDetails-left-section">
        <div className="div">
          <div className="div-2">Complete Your Profile</div>

          {/* Step 1 */}
          <div className="div-10" onClick={() => stepClick(1)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step1 || currentStep === 1) && "active-step"
              }`}
            >
              {stepsCompleted.step1 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "1"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 1</div>
              <div className={currentStep === 1 ? "div-7" : "div-13"}>
                Company Details
              </div>
              <div
                className={
                  currentStep === 1
                    ? stepsCompleted.step1
                      ? "div-9 complete"
                      : "div-9"
                    : stepsCompleted.step1
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 1
                  ? stepsCompleted.step1
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step1
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div className="div-10" onClick={() => stepClick(9)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step9 || currentStep === 9) && "active-step"
              }`}
            >
              {stepsCompleted.step9 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "2"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 2</div>
              <div className={currentStep === 9 ? "div-7" : "div-13"}>
                Deal Team
              </div>
              <div
                className={
                  currentStep === 9
                    ? stepsCompleted.step9
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step9
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 9
                  ? stepsCompleted.step9
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step9
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 3 */}
          <div className="div-10" onClick={() => stepClick(2)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step2 || currentStep === 2) && "active-step"
              }`}
            >
              {stepsCompleted.step2 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "3"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 3</div>
              <div className={currentStep === 2 ? "div-7" : "div-13"}>
                Founding Team
              </div>
              <div
                className={
                  currentStep === 2
                    ? stepsCompleted.step2
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step2
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 2
                  ? stepsCompleted.step2
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step2
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 4 */}
          <div className="div-10" onClick={() => stepClick(3)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step3 || currentStep === 3) && "active-step"
              }`}
            >
              {stepsCompleted.step3 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "4"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 4</div>
              <div className={currentStep === 3 ? "div-7" : "div-13"}>
                Funding Details
              </div>
              <div
                className={
                  currentStep === 3
                    ? stepsCompleted.step3
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step3
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 3
                  ? stepsCompleted.step3
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step3
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 5 */}
          <div className="div-10" onClick={() => stepClick(4)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step4 || currentStep === 4) && "active-step"
              }`}
            >
              {stepsCompleted.step4 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "5"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 5</div>
              <div className={currentStep === 4 ? "div-7" : "div-13"}>
                Dataroom Preparation
              </div>
              <div
                className={
                  currentStep === 4
                    ? stepsCompleted.step4
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step4
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 4
                  ? stepsCompleted.step4
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step4
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 6 */}
          <div
            className="div-10"
            onClick={() => {
              stepClick(10);
              window.scroll(0, 0);
            }}
          >
            <div
              className={`div-11 ${
                (stepsCompleted.step10 || currentStep === 10) && "active-step"
              }`}
            >
              {stepsCompleted.step10 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "6"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 6</div>
              <div className={currentStep === 10 ? "div-7" : "div-13"}>
                Elevator Pitch
              </div>
              <div
                className={
                  currentStep === 10
                    ? stepsCompleted.step10
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step10
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 10
                  ? stepsCompleted.step10
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step10
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 7 */}
          <div className="div-10" onClick={() => stepClick(5)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step5 || currentStep === 5) && "active-step"
              }`}
            >
              {stepsCompleted.step5 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "7"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 7</div>
              <div className={currentStep === 5 ? "div-7" : "div-13"}>
                Accept T&C
              </div>
              <div
                className={
                  currentStep === 5
                    ? stepsCompleted.step5
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step5
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 5
                  ? stepsCompleted.step5
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step5
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Step 8 */}
          <div className="div-10" onClick={() => stepClick(6)}>
            <div
              className={`div-11 ${
                (stepsCompleted.step6 || currentStep === 6) && "active-step"
              }`}
            >
              {stepsCompleted.step6 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "8"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 8</div>
              <div className={currentStep === 6 ? "div-7" : "div-13"}>
                Pitch Review
              </div>
              <div
                className={
                  currentStep === 6
                    ? stepsCompleted.step6
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step6
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 6
                  ? stepsCompleted.step6
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step6
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          <div className="div-10">
            <div
              className={`div-31 ${
                (stepsCompleted.step7 || currentStep === 7) && "active-step"
              }`}
            >
              {stepsCompleted.step7 ? (
                <img src={tick} className="tick" alt="Tick" />
              ) : (
                "9"
              )}
            </div>
            <div className="div-30">
              <div className="div-12">Step 9</div>
              <div className={currentStep === 7 ? "div-7" : "div-13"}>
                Go Live
              </div>
              <div
                className={
                  currentStep === 7
                    ? stepsCompleted.step7
                      ? "div-9 complete"
                      : "div-9 in-progress"
                    : stepsCompleted.step7
                    ? "div-9 complete"
                    : "div-14"
                }
              >
                {currentStep === 7
                  ? stepsCompleted.step7
                    ? "Complete"
                    : "In Progress"
                  : stepsCompleted.step7
                  ? "Complete"
                  : "Pending"}
              </div>
            </div>
          </div>

          {/* Add more steps here similarly */}
        </div>
        <DealAnalystDetails
          user="investor"
          fundrevAnalystID={fundrevAnalystID}
          startupId={startupId}
        />
      </div>
      <div className="mobile-div">
        <div className="mobile-div-2">
          <div className="mobile-div-3">
            <div className="mobile-div-5">
              {stepNames[parseInt(currentStep) - 1]}
            </div>
            <div className="mobile-div-6">
              {stepsCompleted[steps[parseInt(currentStep) - 1]]
                ? "Complete"
                : "In progress"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepProgress;
