import React, { useState } from "react";
import { Modal, Box, TextField } from "@mui/material";
import CustomBlackBtn from "./customBlackBtn";
import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

const CustomModal = ({
  open,
  onClose,
  title,
  description,
  primaryAction,
  secondaryAction,
  primaryText = "Confirm",
  secondaryText = "Cancel",
  customStyles = {},
  children, // Customizable content before buttons
  showPrimaryOnly = false, // Prop to show only the primary button
  secondaryButtonMode = "light", // Mode for secondary action button, defaults to light
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-200 shadow-md p-8 rounded-[34px] flex flex-col items-center justify-evenly"
        style={{ width: "700px", height: "fit-content", ...customStyles }}
      >
        <img
          className="cursor-pointer fixed right-5 top-5"
          src={closeIcon}
          alt="close"
          onClick={onClose}
        />
        <div className="w-full text-center">
          <p className="text-[20px] font-bold text-[var(--Primary-Text-Color)]">
            {title}
          </p>
          <p className="mt-5 text-[16px] text-[var(--Secondary-Text-Color)]">
            {description}
          </p>
        </div>

        {/* Customizable Section */}
        {children && <div className="w-full mt-4">{children}</div>}

        {/* Action Buttons */}
        <div className="w-full mt-7 flex justify-center gap-5">
          {!showPrimaryOnly && (
            <CustomBlackBtn
              text={secondaryText}
              mode={secondaryButtonMode}
              filled={false}
              onClick={secondaryAction || onClose}
            />
          )}
          <CustomBlackBtn text={primaryText} onClick={primaryAction} />
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
