import React, { useState } from "react";
import Particles from "../../Common/animations/particles";
import { ShineBorder } from "../../Common/animations/shineBorder";
import encryption from "../../../Assets/Images/icons/encryption.svg";
import protection from "../../../Assets/Images/icons/protection.svg";
import help from "../../../Assets/Images/icons/help.svg";
const features = [
  {
    icon: encryption,
    title: "End to End Encryption",
    isShiny: true,
    description:
      "All communications and transactions are encrypted end-to-end, ensuring utmost security of sensitive information",
  },
  {
    icon: protection,
    title: "Data Protection",
    isShiny: true,
    description:
      "Our RBAC feature gives every customer control over user permissions and access to sensitive financial data.",
  },
  {
    icon: help,
    title: "We're here to help",
    isShiny: true,
    description:
      "Our customer experience team is ready to answer your questions 7 days a week.",
  },
];

function FeatureCard({ icon, title, description, isShiny }) {
  return (
    <div className="flex flex-col w-[100%] lg:w-[33%] max-md:ml-0 max-md:w-full ">
      {isShiny ? (
        <>
          <ShineBorder className="relative text-center" color={"white"}>
            <div
              className={`relative flex flex-col items-start grow h-48 py-5 px-5 text-white rounded-lg m-auto `}
            >
              <img
                loading="lazy"
                src={icon}
                alt=""
                className="aspect-square z-20 object-contain w-8"
              />
              <div className="2xl:text-res-base md:text-lg relative z-20 mt-5 text-base font-bold">
                {title}
              </div>
              <div className="2xl:text-res-xs text-start z-20 mt-5 text-xs">
                {description}
              </div>
              <div className="absolute h-[186px] md:w-[296px] 2xl:w-[360px] w-[97%] py-5 px-5 bg-[var(--Primary-Text-Color)] top-1 left-1 z-10"></div>
            </div>
          </ShineBorder>
        </>
      ) : (
        <div
          className={`flex flex-col items-start grow h-48 py-5 px-5 text-white rounded-lg m-auto border border-[#343434] border-4" `}
        >
          <img
            loading="lazy"
            src={icon}
            alt=""
            className="aspect-square object-contain w-8"
          />
          <div className="mt-5 text-lg font-bold">{title}</div>
          <div className="text-start mt-5 text-xs">{description}</div>
        </div>
      )}
    </div>
  );
}
export function SecureDeals() {
  const [color, setColor] = useState("#fff");

  return (
    <div className="flex flex-col mt-32 rounded-none">
      <div className="relative flex flex-col justify-center items-center px-20 py-[112px] 2xl:py-[140px] w-full bg-[var(--Primary-Text-Color)] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[954px] 2xl:w-[1150px]">
          <div className="mr-4 ml-5 text-[28px] md:text-h2 2xl:text-res-h2 font-medium text-center text-white max-md:mr-2.5 max-md:max-w-full">
            Your Secure Path to Deal Making & Management
          </div>
          <div className="max-md:mt-10 lg:max-w-full mt-16">
            <div className="lg:flex-row flex flex-col gap-5">
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  hasBorder={feature.hasBorder}
                  isShiny={feature.isShiny}
                />
              ))}
            </div>
          </div>
        </div>

        <Particles
          className="absolute inset-0"
          quantity={200}
          ease={10}
          color={color}
          refresh
        />
      </div>
    </div>
  );
}

// export function ParticlesDemo() {
//   return (
//     <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
//       <span className="bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-8xl dark:from-white dark:to-slate-900/10 font-semibold leading-none text-center text-transparent whitespace-pre-wrap pointer-events-none">
//         Particles
//       </span>
//     </div>
//   );
// }
