import { cn } from "../../../lib/utils";
import Marquee from "../../Common/animations/marquee";

const founderReviews = [
  {
    name: "Founder at Climate Tech",
    body: "Fundrev's profiles save time by skipping initial exploratory calls and connecting fundraisers with truly interested investors.",
  },
  {
    name: "Founder at ConsumerTech",
    body: "Fundrev streamlines your journey to being investor-ready with a seamless and intuitive onboarding process.",
  },
  {
    name: "Founder at CleanTech",
    body: "I wish I could download these analytics to share with my existing investors—it would save my team hours of work.",
  },
  {
    name: "Founder at HealthTech",
    body: "This is the smartest way for investor outreach, outperforming emails and connections with higher response rates.",
  },
  {
    name: "Founder at AgriTech",
    body: "Fundrev's team acts as true partners, guiding me through the entire deal-making process with tailored funding strategies.",
  },
];

const investorReviews = [
  {
    name: "Investor at Growth VC Fund",
    body: "My team saves significant time analyzing companies with Fundrev's analytics and structured data room!",
  },
  {
    name: "Investor at VC Fund",
    body: "Fundrev goes beyond traditional marketplaces by providing access to curated deals directly from active fundraisers.",
  },
  {
    name: "Investor at Family Office",
    body: "Profiles are concise, insightful, and save time by eliminating the need to review pitch decks before scheduling calls.",
  },
  {
    name: "Investor at Consumer VC Fund",
    body: "Their analytics are impressive, offering actionable insights that enable faster decision-making.",
  },
  {
    name: "Investor at ClimateTech Fund",
    body: "I'd love to see more founders joining the platform—it structures our deal flow much better than cold outreach.",
  },
];

const ReviewCard = ({ img, name, username, body }) => {
  return (
    <figure
      className={cn(
        "relative w-52 md:w-80 2xl:w-[400px] cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]"
        // dark styles
      )}
    >
      <div className="flex flex-col gap-4">
        <p className="text-[var(--Primary-Text-Color)] text-sm 2xl:text-res-sm">
          {body}
        </p>
        <p className="text-[#737373] text-xs 2xl:text-res-xs">- {name}</p>
      </div>
    </figure>
  );
};

export function Testimonials() {
  return (
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg my-[150px] lg:mt-[160px] lg:mb-[195px] xl:my-[250px]">
      <p className="md:w-full w-[70%] text-center text-[28px] md:text-h2 2xl:text-res-h2  mb-[60px] text-[var(--Primary-Text-Color)]">
        See What Our Early Users Say!
      </p>
      <Marquee pauseOnHover className="[--duration:40s]">
        {founderReviews.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover className="[--duration:40s]">
        {investorReviews.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="absolute inset-y-0 left-0 w-1/4 pointer-events-none md:w-1/3 bg-gradient-to-r from-white dark:from-background "></div>
      <div className="absolute inset-y-0 right-0 w-1/4 pointer-events-none md:w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
  );
}
