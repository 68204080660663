import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getAllInvestors } from "../../../../endpoints/investor";

import { useLocation, useNavigate } from "react-router-dom";
import {
  clearDealIdIndustryVerticals,
  getInvestorIdIndustryVerticals,
  setDealToInvestor,
} from "../../../../endpoints/admin";
import Toast from "../../../Common/Toast";

import CustomModal from "../../../Common/components/custom/customModal";

const InvestorPanel = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [investors, setInvestors] = useState([]);
  const [fitSelections, setFitSelections] = useState({});
  const token = localStorage.getItem("token");
  const [selectInvestorID, setSelectedInvestorID] = useState();
  const [fit, setFit] = useState("");
  const { dealId, startupId } = location.state;

  useEffect(() => {
    const fetchAllInvestors = async () => {
      try {
        const response = await getAllInvestors(token);
        setInvestors(response.data);
      } catch (error) {}
    };
    fetchAllInvestors();
  }, []);

  useEffect(() => {
    const fetchInvestorDeals = async () => {
      try {
        const response = await getInvestorIdIndustryVerticals(dealId, token);
        const fitMap = {};
        response.data.forEach((investor) => {
          fitMap[investor.investorId] = {
            ideal: investor.ideal,
            moderate: investor.moderate,
            nonFit: investor.nonFit,
          };
        });
        setFitSelections(fitMap);
      } catch (error) {}
    };
    fetchInvestorDeals();
  }, []);

  const handleFitChange = async () => {
    try {
      const response = await setDealToInvestor(
        selectInvestorID,
        dealId,
        fit,
        token
      );
      setFitSelections((prevState) => ({
        ...prevState,
        [selectInvestorID]: {
          ideal: fit === "ideal",
          moderate: fit === "moderate",
          nonFit: fit === "nonFit",
        },
      }));
      Toast("Fit type set successfully!", "success", "investorPanel");
      setModalOpen(false);
      setSelectedInvestorID("");
      setFit("");
    } catch (error) {
      Toast("Failed to set fit type.", "error", "investorPanel");
    }
  };

  const handleClearSelection = async (investorId) => {
    try {
      const response = await clearDealIdIndustryVerticals(
        investorId,
        dealId,
        token
      );
      setFitSelections((prevState) => {
        const newState = { ...prevState };
        delete newState[investorId];
        return newState;
      });
      Toast("Fit type cleared successfully!", "success", "investorPanel");
    } catch (error) {
      Toast("Failed to clear fit type.", "error", "investorPanel");
    }
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Assign match"
        description="This will trigger an email to associated investor"
        primaryAction={handleFitChange}
        secondaryAction={() => setModalOpen(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
      />
      <ToastContainer position="top-center" containerId="investorPanel" />
      <div className="InvestorSide-box table-container">
        <table>
          <thead>
            <tr>
              <th>Investor Firm Name</th>
              <th>Website</th>
              <th>Investor ID</th>
              <th>Onboarding Completed</th>
              <th>Preview</th>
              <th>Ideal Fit</th>
              <th>Moderate Fit</th>
              <th>Non Fit</th>
              <th>Clear</th>
            </tr>
          </thead>
          <tbody>
            {investors.map((investor) => (
              <tr key={investor.investorId}>
                <td>{investor.companyName}</td>
                <td>
                  <a
                    href={`https:\\${investor.investorWebsite}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {investor.investorWebsite}
                  </a>
                </td>
                <td>{investor.investorID}</td>
                <td>
                  {investor.onBoardingComplete ? "Completed" : "Not Completed"}
                </td>
                <td>
                  <button
                    className="toggleStatusbtn"
                    onClick={() =>
                      navigate("/admin/investor/preview", {
                        state: {
                          investor,
                          startupId,
                          dealId,
                          state: "companies",
                        },
                      })
                    }
                  >
                    Preview
                  </button>
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.ideal ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="ideal"
                    checked={fitSelections[investor.investorID]?.ideal || false}
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("ideal");
                      setModalOpen(true);
                    }}
                  />
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.moderate ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="moderate"
                    checked={
                      fitSelections[investor.investorID]?.moderate || false
                    }
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("moderate");
                      setModalOpen(true);
                    }}
                  />
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.nonFit ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="non"
                    checked={
                      fitSelections[investor.investorID]?.nonFit || false
                    }
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("nonFit");
                      setModalOpen(true);
                    }}
                  />
                </td>

                <td>
                  <button
                    onClick={() => handleClearSelection(investor.investorID)}
                    className="download_btn"
                  >
                    Clear
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvestorPanel;
