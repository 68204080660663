import React from "react";

import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { BorderLinearProgress } from "../../functions";
import { Star } from "@mui/icons-material";
import SecondaryIcon from "../../../../Assets/Images/icons/secondaryDealIcon.svg";

const ProgressBar = ({
  totalFundingRaised,
  fundingRequired,
  roundType,
  DealTag,
  dealId,
  card,
  requestedDate,
  submittedDate,
  acceptedDate,
  IOIBidSubmittedAmount,
  IOIBidAcceptedAmount,
  LOIBidSubmittedAmount,
  LOIBidAcceptedAmount,
  closingDate,
  stage,
  dealType,
}) => {
  const progress =
    fundingRequired > 0 ? (totalFundingRaised / fundingRequired) * 100 : 0;

  return (
    <>
      <Stack spacing={2} sx={{ flexGrow: 1 }} className="Card-div-22">
        {dealType === "secondary" ? (
          <div className="Card-div-24 bg-[#114FEE20] text-[--brand-color]">
            <img src={SecondaryIcon} alt="secondary" />
            Secondary
          </div>
        ) : (
          <div className="Card-div-24 bg-[#00A6FB20] text-[--blue-color]">
            <Star fontSize="12px" />
            {roundType}
          </div>
        )}

        {card === "ongoingDealCard" && (
          <>
            <div className="Card-div-36">
              {stage === "requestSent" && (
                <>
                  Request Sent:{" "}
                  <span className="Card-div-37">{requestedDate}</span>
                </>
              )}
              {(stage === "ioiSubmitted" || stage === "loiSubmitted") && (
                <>
                  Submitted Date:{" "}
                  <span className="Card-div-37">{submittedDate}</span>
                </>
              )}
              {(stage === "requestAccepted" ||
                stage === "ioiAccepted" ||
                stage === "loiAccepted") && (
                <>
                  Accepted Date:{" "}
                  <span className="Card-div-37">{acceptedDate}</span>
                </>
              )}
              {stage === "dealClosed" && (
                <>
                  Closing Date :
                  <span className="Card-div-37">&nbsp;{closingDate}</span>{" "}
                </>
              )}
            </div>
            <div
              className="Card-div-38"
              style={{
                marginTop: "4px",
              }}
            >
              <div className="Card-div-36">
                {stage === "requestSent" || stage === "requestAccepted" ? (
                  <>
                    Deal ID : <span className="Card-div-37">{dealId}</span>
                  </>
                ) : stage === "ioiSubmitted" ? (
                  <>
                    Bid Amount :{" "}
                    <span className="Card-div-37">
                      &nbsp;{IOIBidSubmittedAmount}Cr
                    </span>
                  </>
                ) : stage === "ioiAccepted" ? (
                  <>
                    Accepted bid amount :
                    <span className="Card-div-37">
                      &nbsp;{IOIBidAcceptedAmount}Cr
                    </span>{" "}
                  </>
                ) : stage === "loiSubmitted" ? (
                  <>
                    Bid Amount :
                    <span className="Card-div-37">
                      &nbsp;{LOIBidSubmittedAmount}Cr
                    </span>{" "}
                  </>
                ) : stage === "loiAccepted" ? (
                  <>
                    Accepted bid Amount :
                    <span className="Card-div-37">
                      &nbsp;{LOIBidAcceptedAmount}Cr
                    </span>{" "}
                  </>
                ) : (
                  <>
                    Deal ID : <span className="Card-div-37">{dealId}</span>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <Box
          sx={{
            marginTop:
              card === "ongoingDealCard" ? "4px !important" : "0px !important",
          }}
        >
          <Box
            sx={{ position: "relative", display: "inline-flex", width: "100%" }}
          >
            <BorderLinearProgress
              variant="determinate"
              value={(
                (fundingRequired > 0
                  ? totalFundingRaised / fundingRequired
                  : 0) * 100
              ).toFixed(2)}
              sx={{
                height: 15,
                width: "100%",
                borderRadius: "10px",
                overflow: "hidden",
                "& .MuiLinearProgress-bar": {
                  borderRadius: "0px",
                  backgroundColor: "#00A6FB",
                },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: progress >= 60 ? "50%" : `${progress + 12}%`, // Center if progress > 60, else at the end
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                className="card-raised-text"
                sx={{ whiteSpace: "nowrap" }}
                style={{
                  color: progress >= 60 ? "#FFF" : "#1a1a1a",
                }}
              >
                {`${Math.round(progress)}% raised`}
              </Typography>
            </Box>
          </Box>
          <div className="Card-div-32">
            <div className="Card-div-33">
              <p className="Card-div-34">₹{totalFundingRaised}Cr</p>
            </div>
            <div>
              <p className="Card-div-34">₹{fundingRequired}Cr</p>
            </div>
          </div>
        </Box>
      </Stack>
      {DealTag && DealTag?.length > 0 && card !== "ongoingDealCard" && (
        <div className="analyst-tag">
          <p>{DealTag}</p>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
