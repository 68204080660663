import {
  Box,
  Dialog,
  Modal,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cross from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useLoader } from "../LoaderProvider";
import {
  getAnalystCalendarFreeSlots,
  scheduleMeetingWithAnalyst,
} from "../../../endpoints/common";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import { Person2Outlined } from "@mui/icons-material";
import greenTick from "../../../Assets/Images/icons/greenTick.svg";
import { CalendarIcon, GlobeIcon } from "@radix-ui/react-icons";
import Toast from "../Toast";

dayjs.extend(timezone);
const ScheduleMeetingWithAnalystModal = ({ values, modal, setModal }) => {
  const loader = useLoader();
  const [scheduled, setScheduled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [freeSlots, setFreeSlots] = useState([]);
  const [data, setData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      borderRadius: "4px",
      height: "42px",
      border: "1px solid var(--brand-color)",
      color: "var(--brand-color)",
      width: "100px",
      '&[aria-pressed="true"]': {
        color: "white",
        background: "var(--brand-color)",
        borderColor: "var(--brand-color)",
        border: "1px solid var(--brand-color)",
        "&:hover": {
          backgroundColor: "var(--brand-color)",
        },
      },
    },
  }));
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };
  const handleClose = () => {
    setModal(false);
  };
  const handleSlotClick = (e) => {
    setSelectedSlot(e.target.value);
  };
  useEffect(() => {
    if (selectedDate && data?.length > 0) {
      // Get the current time and add the IST offset (5 hours 30 minutes)
      const currentTime = dayjs().tz(`Asia/Kolkata`);

      // Filter the slots based on the selected date and time
      const filteredSlots = data.filter((slot) => {
        // Parse the start and end times as dayjs objects, assuming they are in IST
        const slotStart = dayjs(slot.start).tz(`Asia/Kolkata`);
        // Check if the slot's date matches the selectedDate
        const isSameDate = slotStart.isSame(selectedDate, "day");

        // Ensure the slot's start time is greater than the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });
      // Set the filtered slots to the state
      setFreeSlots(filteredSlots);
    }
  }, [data, selectedDate]);
  const shouldDisableDate = (date) => {
    if (data?.length > 0) {
      // Get the current time with IST timezone
      const currentTime = dayjs().tz("Asia/Kolkata");

      // Filter slots based on selected date and time
      const availableSlots = data?.filter((slot) => {
        // Parse the slot's start time as a dayjs object in IST
        const slotStart = dayjs(slot.start).tz("Asia/Kolkata");
        // Check if the slot's date matches the selected date
        const isSameDate = slotStart.isSame(date, "day");
        // Ensure the slot's start time is after the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });

      // Disable the date if there are no available slots
      return availableSlots.length === 0;
    } else {
      return true;
    }
  };
  const handleSchedule = async () => {
    if (selectedSlot) {
      if (!scheduled) {
        loader.start(`Scheduling meeting ...`);
        const response = await scheduleMeetingWithAnalyst(values, selectedSlot);
        loader.stop();
        if (response) {
          setScheduled(true);
        } else {
          Toast(
            `Failed to schedule meeting with analyst. Please try again later.`,
            `error`,
            `verificationPage`
          );
        }
      } else {
        setScheduled(false);
        setSelectedSlot(null);
        setSelectedDate(null);
        setFreeSlots([]);
        setModal(false);
      }
    } else {
      Toast(`Please select a slot.`, `info`, `verificationPage`);
    }
  };
  const fetchFreeSlots = async () => {
    if (modal) {
      loader.start(`Fetching Slots`);
      const response = await getAnalystCalendarFreeSlots();
      loader.stop();
      setData(response);
    }
  };
  useEffect(() => {
    fetchFreeSlots();
  }, [modal]);
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "fit-content",
          width: "600px",
          backgroundColor: "white",
          borderRadius: "20px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <p className="font-[Roboto] text-xl">
              Schedule a call with Fundrev
            </p>

            {
              <img
                src={cross}
                alt="cross"
                onClick={handleClose}
                className="cursor-pointer"
              />
            }
          </Box>
          {!scheduled && (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
              }}
            >
              <Box>
                Please select a date to schedule
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    disablePast
                    showDaysOutsideCurrentMonth
                    views={["day"]}
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={shouldDisableDate}
                    minDate={dayjs().tz(`Asia/Kolkata`).add(24, "hours")}
                    maxDate={dayjs()
                      .tz(`Asia/Kolkata`)
                      .add(9, "day")
                      .add(24, "hours")}
                    sx={{
                      height: 310,
                      "& .Mui-selected": {
                        backgroundColor: "var(--brand-color)", // Set selected date background
                        color: "white", // Optionally set the text color for selected date
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: "var(--brand-color)", // Maintain the color on hover
                      },
                      "& .Mui-selected:focus": {
                        backgroundColor: "var(--brand-color)", // Maintain the color on focus
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  minWidth: "232px",
                }}
              >
                <p className="mt-3 mb-2 w-full flex justify-center">
                  Available Slots
                </p>
                <Box
                  sx={{
                    overflowY: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    maxHeight: "250px",
                  }}
                >
                  <StyledToggleButtonGroup
                    exclusive
                    aria-label="Period"
                    value={selectedSlot}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: freeSlots?.length === 0 && "center",
                      flexWrap: "wrap",
                    }}
                    onChange={handleSlotClick}
                  >
                    {freeSlots?.length > 0
                      ? freeSlots.map((freeSlot, index) => (
                          <ToggleButton key={index} value={freeSlot.start}>
                            {dayjs(freeSlot.start).format("HH:mm")}
                          </ToggleButton>
                        ))
                      : freeSlots?.length === 0 && (
                          <div>
                            {selectedDate ? (
                              <div>No slots available.</div>
                            ) : (
                              <div>Please select a date</div>
                            )}
                          </div>
                        )}
                  </StyledToggleButtonGroup>
                </Box>
              </Box>
            </Box>
          )}
          {scheduled && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                <img src={greenTick} alt="greenTick" className="mr-2" />
                Meeting Scheduled
              </Box>
              <div className="mt-4 text-sm">
                A calendar invitation has been sent to your email address.
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "16px",
                  width: "400px",
                  border: "1px solid #DADADA",
                  borderRadius: "4px",
                }}
              >
                <div className="mb-4 font-medium">Meeting Info</div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <Person2Outlined
                    sx={{
                      height: "20px",
                      marginRight: "4px",
                    }}
                  />{" "}
                  Fundrev, {values?.companyName}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <CalendarIcon className="mr-1 ml-0.5" width={`20px`} />{" "}
                  {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`HH:mma`)} -{" "}
                  {dayjs(selectedSlot)
                    .tz(`Asia/Kolkata`)
                    .add(30, `minutes`)
                    .format(`HH:mma`)}
                  , {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`dddd`)},
                  {dayjs(selectedSlot)
                    .tz(`Asia/Kolkata`)
                    .format(` MMMM, D, YYYY `)}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <GlobeIcon width={`20px`} className="mr-1 ml-0.5" /> Indian
                  Standard Time
                </Box>
              </Box>
            </Box>
          )}
          <div className={`w-full flex justify-center ${scheduled && `mt-8`} `}>
            <CustomBlackBtn
              text={!scheduled ? `Schedule` : `Close`}
              onClick={handleSchedule}
            />
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ScheduleMeetingWithAnalystModal;
