import React, { useState, useEffect, useRef } from "react";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../components/custom/customBlackBtnPhone";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";
import WelcomeInvestor from "../modals/welcomeInvestor";
import WelcomeCompany from "../modals/welcomeCompany";

import { resendCode, verifyEmail } from "../../../endpoints/common";

import VerificationInput from "react-verification-input";
import RefreshIcon from "@mui/icons-material/Refresh";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import { CiLock } from "react-icons/ci";

import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import "../../../CSS/Signup/emailVerification.css";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loader = useLoader();
  const { values, role, startPoint, investorToken, startupToken } =
    location.state || {};
  const [time, setTime] = useState(5 * 60);
  const [timeUp, setTimeUp] = useState(false);
  const timerRef = useRef(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCompanyOpen = () => setCompanyOpen(true);
  const handleCompanyClose = () => setCompanyOpen(false);
  const [investorDetailsToken, setInvestorDetailsToken] = useState();
  const [updatedInvestorToken] = useState(investorToken);
  const [updatedStartupToken] = useState(startupToken);

  useEffect(() => {
    if (!startupToken && !investorToken) {
      navigate(`/signin`);
    }
  }, [startupToken, investorToken]);

  useEffect(() => {
    if (role === "investor") {
      setToken(updatedInvestorToken);
    } else {
      setToken(updatedStartupToken);
    }
  }, [updatedInvestorToken, updatedStartupToken]);

  const [email] = useState(values?.email || "");

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          setTimeUp(true);
          clearInterval(timerRef.current);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  const navigateToInvestorProfile = () => {
    localStorage.setItem("token", investorDetailsToken);
    localStorage.setItem("role", "investor");
    setTimeout(() => {
      navigate("/investor/newDeals/allListing", {
        state: {
          token: investorDetailsToken,
        },
      });
    }, 300);
  };
  const verifyCode = async () => {
    if (timeUp) {
      Toast(
        "Time up! Please click on the send again.",
        "warning",
        "verificationPage"
      );
      return;
    }
    try {
      loader.start("Verifying OTP...");
      const response = await verifyEmail(email, verificationCode, token);
      loader.stop();
      if (response && response?.data && response.data.status === 200) {
        if (role === "startup") {
          localStorage.removeItem("companyDetails");
          localStorage.setItem("token", response.data.data.data.token);
          localStorage.setItem("role", "company");
          handleCompanyOpen();
        } else {
          const tkn = response.data.data.data.token;
          setInvestorDetailsToken(tkn);
          handleOpen();
        }
        if (startPoint === "noNameDeals")
          mixpanel.track("Email Verified through No Name Deals");
        //This is case when someone from tries to register on the platform using the domain that is being already used by someone on the platform
      } else if (response && response?.error && response.error.status === 409) {
        if (role === "startup") {
          navigate("/company/joinFirm", {
            state: {
              name: response.error.response.data.data.name,
              email: response.error.response.data.data.email,
              companyName: response.error.response.data.data.companyName,
              token: response.error.response.data.data.token,
              values: values,
              type: "startup",
            },
          });
        } else {
          navigate("/investor/joinFirm", {
            state: {
              name: response.error.response.data.data.name,
              email: response.error.response.data.data.email,
              companyName: response.error.response.data.data.companyName,
              token: response.error.response.data.data.token,
              values: values,
              type: "investor",
            },
          });
        }
      } else {
        Toast("Invalid or expired code!", "error", "verificationPage");
        setVerificationCode("");
      }
    } catch (error) {
      loader.stop();
      console.log(error);
      Toast("Invalid or expired code!", "error", "verificationPage");
      setVerificationCode("");
      return;
    }
  };
  const handleSendAgain = async () => {
    let data = null;
    if (token) {
      try {
        loader.start("Sending OTP again...");
        data = await resendCode(token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }

      if (data?.response !== null) {
        const response = data.response;

        if (!response.ok) {
        }

        Toast(
          "Verification code resent successfully.",
          "success",
          "verificationPage"
        );

        setTime(5 * 60);
        setTimeUp(false);
      } else {
      }
    }
  };
  return (
    <div className="emailVerificationPage">
      <ToastContainer position="top-center" containerId="verificationPage" />
      <WelcomeInvestor
        open={open}
        values={values}
        navigateToInvestorProfile={navigateToInvestorProfile}
        handleClose={handleClose}
      />
      <WelcomeCompany
        values={values}
        open={companyOpen}
        handleClose={handleCompanyClose}
      />
      {/* Desktop Header */}
      <div className="emailVerificationPage-header laptop-design">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>

      {/* Mobile Header */}
      <div className="emailVerificationPage-header phone-design">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <div className="emailVerification-block">
        <div className="heading-block">
          <p className="fundrev-heading emailVerification-heading">
            Check your Email
          </p>
        </div>

        <div className="middle-block">
          <div className="middle-block-header">
            <p>
              Please enter the confirmation code sent on your email address for
              verification
            </p>
          </div>

          <div className="otp-block">
            <div className="otp-block-first">
              <VerificationInput
                length={6}
                placeholder=""
                value={verificationCode}
                onChange={(code) => setVerificationCode(code)}
              />

              <div className="time-up-send-again laptop-design">
                <p className="timer">{timeUp ? "Time up" : formattedTime}</p>
              </div>
            </div>

            <div
              className="send-again-div laptop-design"
              onClick={handleSendAgain}
              style={{ cursor: "pointer" }}
            >
              <RefreshIcon style={{ color: "#3C82F6" }} />
              <p>Send again</p>
            </div>
            <div
              className="send-again-div phone-design"
              onClick={handleSendAgain}
              style={{ cursor: "pointer" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RefreshIcon style={{ color: "#3C82F6" }} />
                <p>Send again</p>
              </div>
              <div className="time-up-send-again">
                <p className="timer">{timeUp ? "Time up" : formattedTime}</p>
              </div>
            </div>

            {/* Desktop Button */}
            <div className="btn-block laptop-design">
              <div onClick={verifyCode}>
                <CustomBlackBtn text="Verify" type="submit" />
              </div>
              <p>
                {" "}
                <CiLock /> &nbsp;Your info is safely secured
              </p>
            </div>

            {/* Mobile Button */}
            <div className="btn-block phone-design">
              <div onClick={verifyCode} style={{ width: "100%" }}>
                <CustomBlackBtnPhone text="Verify" type="submit" />
              </div>
              <p>
                <CiLock /> &nbsp;Your info is safely secured
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
