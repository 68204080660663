import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import FinancialStatementSmallComparisonTable from "./AnalyticsPlots/FinancialStatementSmallComparisonTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import { Link2Icon } from "@radix-ui/react-icons";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import BalanceSheetSummaryTable from "./AnalyticsPlots/BalanceSheetSummaryTable";
import { dumpInvestorInteraction } from "../../../../../endpoints/common";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";

const AnalyticsOverview = ({ deal, data, setAnalytics, stage }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "summary",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <>
      <div
        style={{
          marginBottom: "100px",
        }}
      >
        <SmallCardGrid data={data?.fiveCards} title={`Analytics Summary`} />
        {data.plots.financialPlot.isVisible && (
          <Box>
            <AnalyticsPageTitle
              title={`Business Performance`}
              viewMore={true}
              handleClick={() => setAnalytics(`businessAnalytics`)}
            />
            <FinancialPlot data={data.plots.financialPlot} />
          </Box>
        )}
        {(data.plots.revenueByCategoryPlot.isVisible ||
          data.tables.topCustomerTable.isVisible) && (
          <Box>
            <AnalyticsPageTitle
              title={`Sales Summary`}
              viewMore={true}
              handleClick={() => setAnalytics(`salesSummary`)}
            />
            <Grid container spacing={1} marginBottom={3} sx={{ width: "101%" }}>
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "16px",
                    fontFamily: "Karla, sans-serif",
                    height: "336px",
                  }}
                >
                  <PlotTemplate data={data.plots.revenueByCategoryPlot} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "16px",
                    fontFamily: "Karla, sans-serif",
                    height: "336px",
                  }}
                >
                  <TopCustomerTable data={data.tables.topCustomerTable} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {(data.tables.financialSmallTable.isVisible ||
          data.tables.balanceSheetSummary.isVisible) && (
          <Box>
            <AnalyticsPageTitle
              title={`Historical Financial Summary`}
              viewMore={true}
              handleClick={() => setAnalytics(`historicalFinancials`)}
            />
            <Grid container spacing={1} sx={{ width: "101%" }}>
              <Grid item xs={12} md={6} lg={6}>
                {data.tables.financialSmallTable.isVisible && (
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      width: "100%",
                      borderRadius: "4px",
                      padding: "16px",
                      fontFamily: "Karla, sans-serif",
                      height: "336px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <FinancialStatementSmallComparisonTable
                      data={data.tables.financialSmallTable}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                {data.tables.balanceSheetSummary.isVisible && (
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      width: "100%",
                      borderRadius: "4px",
                      padding: "16px",
                      fontFamily: "Karla, sans-serif",
                      height: "336px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <BalanceSheetSummaryTable
                      data={data.tables.balanceSheetSummary}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
};

export default AnalyticsOverview;
