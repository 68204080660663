import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../Company/Deal/Sidebar";
import InvestorSidebar from "./investorSidebar";
import InvestorOverview from "../../../Common/components/investorOverview";
import ProfileCard from "../../../Common/components/profileCard";

import { getInvestmentTeamDetails } from "../../../../endpoints/investor";
import { useLoader } from "../../../Common/LoaderProvider";

import { useLocation } from "react-router-dom";
import Topbar from "../../Company/Deal/topbar";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  Chip,
} from "@mui/material";
import {
  Mail as MailIcon,
  Phone as PhoneIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import TeamMemberCard from "../../teamMemeberCard";

const InvestorPreview = () => {
  const location = useLocation();
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const { dealId, investor, startupId, state } = location.state;
  const [teamDetails, setTeamDetails] = useState([]);
  const getTeamDetails = async () => {
    try {
      if (!investor?.investorID) return;
      const response = await getInvestmentTeamDetails(
        investor?.investorID,
        token
      );
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    getTeamDetails();
  }, [investor]);
  return (
    <>
      <div className="adminSide InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" />
        {state === "companies" ? (
          <Sidebar
            dealId={dealId}
            active={"investorPanel"}
            startupId={startupId}
          />
        ) : (
          <InvestorSidebar active="investorPreview" investor={investor} />
        )}

        <Topbar title="Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div" style={{ paddingTop: "16px" }}>
              <InvestorOverview investor={investor} mode={"admin"} />
              <Box
                sx={{
                  padding: 2,
                  display: "grid",
                  gap: "16px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                {teamDetails &&
                  teamDetails.map((member, index) => (
                    <TeamMemberCard
                      key={index}
                      member={member}
                      role="investor"
                    />
                  ))}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorPreview;
