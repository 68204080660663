import React, { useState } from "react";
import Companies from "./companies";
import Investors from "./investors";
import OpenListing from "./openListing";
import Toast from "../Common/Toast";
import { getAllDeals, createOpenListing } from "../../endpoints/deal";

import { Button } from "@mui/joy";
import { ToastContainer } from "react-toastify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "../../CSS/Admin/adminPanel.css";
import { Helmet } from "react-helmet";
import UnifiedInvestor from "./UnifiedInvestor/unifiedInvestor";
import GenerateURL from "./generateURL";
import SystemStatus from "./systemStatus";
import InvestorToolkitLeads from "./invsetorToolkitLeads";
import LoginLogs from "./LoginLogs";
import CalendarSync from "./CalendarSync";
import { useLocation } from "react-router-dom";
import SecondaryDeals from "./secondary/secondaryDeals";

const AdminPanel = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { card } = location.state || {};
  const [cardType, setCardType] = useState(card ? card : "Companies");
  const [createNewDeal, setCreateNewDeal] = useState(false);
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  async function CreateNewDeal() {
    try {
      const response = await createOpenListing(token);
      if (response.response.status === 200) {
        Toast("New Deal created successfully", "success", "adminPanel");
        setCreateNewDeal(!createNewDeal);
      } else {
        Toast("Error creating new listing", "error", "adminPanel");
      }
    } catch (error) {
      console.error("Error creating new Deal:", error);
      Toast("Error creating new Deal", "error", "adminPanel");
    }
  }

  const renderCard = () => {
    switch (cardType) {
      case "Companies":
        return <Companies refresh={createNewDeal} />;
      case "Investors":
        return <Investors />;
      // case "OpenListing":
      //   return (
      //     <OpenListing
      //       createNewDeal={createNewDeal}
      //       setCreateNewDeal={setCreateNewDeal}
      //     />
      //   );
      case "unifiedInvestors":
        return <UnifiedInvestor />;
      case "generateURL":
        return <GenerateURL />;
      case "systemStatus":
        return <SystemStatus />;
      case "investorToolkitLeads":
        return <InvestorToolkitLeads />;
      case "loginLogs":
        return <LoginLogs />;
      case "scheduleMeeting":
        return <CalendarSync />;
      case "secondaryDeals":
        return <SecondaryDeals />;
      default:
        return null;
    }
  };

  return (
    <div className="admin-panel-page">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="adminPanel" />
      <h1 style={{ fontSize: "30px" }}>Admin Panel</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ToggleButtonGroup
          color="primary"
          value={cardType}
          exclusive
          onChange={handleCardTypeChange}
          aria-label="card-type"
          className="toggle-card-type-btn"
        >
          <ToggleButton
            value="Companies"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Companies
          </ToggleButton>
          {/* <ToggleButton
            value="OpenListing"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Open Listing
          </ToggleButton> */}
          <ToggleButton
            value="Investors"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Investors
          </ToggleButton>
          <ToggleButton
            value="secondaryDeals"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Secondary Deals
          </ToggleButton>
          <ToggleButton
            value="unifiedInvestors"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Unified Investors
          </ToggleButton>
          <ToggleButton
            value="generateURL"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Generate URL
          </ToggleButton>
          <ToggleButton
            value="systemStatus"
            sx={{
              textTransform: "capitalize",
            }}
          >
            System Status
          </ToggleButton>
          <ToggleButton
            value="investorToolkitLeads"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Investor Toolkit Leads
          </ToggleButton>
          <ToggleButton
            value="loginLogs"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Login Logs
          </ToggleButton>
          <ToggleButton
            value="scheduleMeeting"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Calendar Sync
          </ToggleButton>
        </ToggleButtonGroup>
        {cardType === "OpenListing" ||
          (cardType === "Companies" && (
            <div>
              <Button onClick={() => CreateNewDeal()}>Create New Deal</Button>
            </div>
          ))}
      </div>
      {renderCard()}
    </div>
  );
};

export default AdminPanel;
