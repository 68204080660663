import React, { useEffect, useRef } from "react";
import { Modal, Box } from "@mui/material";
import { Confetti } from "../../animations/Confetti";

const GreenTickModal = ({
  open,
  customStyles,
  onClose,
  title,
  confetti = false,
}) => {
  const timeoutRef = useRef(null);
  const confettiRef = useRef(null);

  useEffect(() => {
    if (open) {
      // Set a timeout to call onClose after a specified time
      confettiRef.current?.fire({});
      timeoutRef.current = setTimeout(() => {
        onClose();
      }, 3000); // Change 3000 to your desired timeout in milliseconds
    }

    // Clear the timeout if the component unmounts or open changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [open, onClose]);
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 40,
        }}
      >
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-200 shadow-md p-8 rounded-[34px] flex flex-col items-center justify-evenly"
          style={{ width: "500px", height: "fit-content", ...customStyles }}
        >
          <div className="w-full text-center">
            <p className="text-[20px] font-semibold text-[var(--Primary-Text-Color)]">
              {title}
            </p>
          </div>
          <div>
            <img
              src={
                "https://fundrevstorage2.blob.core.windows.net/website-images/be3aab65-3f6a-42fd-b3fa-7a1546ed40e4.gif"
              }
              alt="greenTick"
              style={{
                height: "200px",
                objectFit: "contain",
              }}
            />
          </div>
        </Box>
      </Modal>
      {confetti && open && (
        <Confetti
          options={{
            tick: 300,
            spread: 60,
            particles: 100,
          }}
          ref={confettiRef}
          className="absolute left-0 top-0 z-50 size-full"
        />
      )}
    </>
  );
};

export default GreenTickModal;
