const stepFields = {
  "Deal Details": [
    "dealType",
    "timeline",
    "fundingRequired",
    "debtFundingRequired",
    "roundType",
    "debtType",
    "preferredInvestors",
    // "term",
  ],
  "Expected Valuation": [
    "equityType",
    "pricedEquityType",
    "stakeOffered",
    "minPostMoneyValuation",
    "nonPricedEquityType",
    // "interestRate",
    // "valuationCap",
    // "discountRate",
  ],
  "Deal Interest": [
    "softCommitments",
    // "fundingRaised",
    // "debtRaised",
    // "leadInvestor",
    // "coInvestors",
    // "contactedInvestorNames",
  ],
  "Deal Purpose": ["purposeOfFundraising"],
  "Pitch Creation": ["pitchDeck", "videoPitch"],
  "Meeting Calendar Setup": ["googleAuthToken", "microsoftAuthToken"],
  "Analytics Preparation": [
    "financialDocuments",
    "invoiceDetails",
    "bankStatements",
  ],
  "Data Room Setup": [
    "corporateDocuments",
    "teamDetails",
    "productTechnology",
    "marketClientInfo",
    "legalComplianceDetails",
    "financialPlanning",
  ],
  "Submit for Review": ["onBoardingComplete"],
};
//This function is used while calculating if a step is completed or not here we are not considering optional fields
const getRequiredFields = (stageName, values) => {
  switch (stageName) {
    case "Deal Details":
      if (values.dealType === "Equity") {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "roundType",
          "preferredInvestors",
        ];
      } else if (values.dealType === "Equity + Debt") {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "debtFundingRequired",
          "roundType",
          "debtType",
          "preferredInvestors",
          // "term",
        ];
      } else {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "roundType",
          "debtType",
          "preferredInvestors",
          // "term",
        ];
      }
      break;

    case "Expected Valuation":
      if (values.dealType === "Equity" || values.dealType === "Equity + Debt") {
        if (values.equityType === "Priced") {
          return [
            "equityType",
            "pricedEquityType",
            "stakeOffered",
            "minPostMoneyValuation",
          ];
        } else if (values.equityType === "Non-Priced") {
          if (values.nonPricedEquityType === "Convertible Debt") {
            return [
              "equityType",
              "nonPricedEquityType",
              // "interestRate",
              // "valuationCap",
              // "discountRate",
            ];
          } else if (values.nonPricedEquityType === "iSAFE") {
            return [
              "equityType",
              "nonPricedEquityType",
              // "valuationCap",
              // "discountRate",
            ];
          }
        }
      }
      break;

    case "Deal Interest":
      if (values.dealType === "Debt" && values.fundingRaised > 0) {
        return ["softCommitments", "fundingRaised", "coInvestors"];
      } else if (
        values.dealType === "Debt" &&
        (values.fundingRaised === 0 || !values.fundingRaised)
      ) {
        return ["softCommitments", "fundingRaised"];
      } else if (
        (values.dealType === "Debt" || values.dealType === "Equity + Debt") &&
        (values.fundingRaised > 0 || values.debtRaised > 0)
      ) {
        return [
          "softCommitments",
          "fundingRaised",
          "debtRaised",
          "coInvestors",
        ];
      } else if (
        (values.dealType === "Debt" || values.dealType === "Equity + Debt") &&
        (values.fundingRaised === 0 || !values.fundingRaised)
      ) {
        return ["softCommitments", "fundingRaised", "debtRaised"];
      } else if (
        values.dealType === "Equity" &&
        (values.fundingRaised === 0 || !values.fundingRaised)
      ) {
        return ["softCommitments", "fundingRaised"];
      } else if (values.dealType === "Equity" && values.fundingRaised > 0) {
        return ["softCommitments", "fundingRaised", "coInvestors"];
      } else {
        return [
          "softCommitments",
          "fundingRaised",
          // "leadInvestor",
          // "coInvestors",
          // "contactedInvestorNames",
        ];
      }

      break;

    default:
      return stepFields[stageName] || []; // No fields required by default if stageName does not match
  }
  return stepFields[stageName] || [];
};

// Helper function to get unfilled fields based on required fields
const getUnfilledFields = (fields, values) => {
  return fields.filter((field) => {
    const value = values[field];
    if (field === "googleAuthToken" || field === "microsoftAuthToken") {
      return value === false;
    } else if (field === "onBoardingComplete") {
      return value === false;
    }
    return (
      value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    );
  });
};
// Function to check if a specific stage is completed based on conditions
const isStageCompleted = (stageName, values) => {
  const requiredFields = getRequiredFields(stageName, values);

  // If "Expected Valuation" stage is not needed (Debt scenario), mark it as complete by default
  if (stageName === "Expected Valuation" && values.dealType === "Debt") {
    return true;
  }
  //Only one of the google or microsoft should be connected
  if (stageName === "Meeting Calendar Setup") {
    return values["googleAuthToken"] || values["microsoftAuthToken"];
  }
  const unfilledFields = getUnfilledFields(requiredFields, values);
  if (unfilledFields.length === 0) {
    return true; // All required fields are filled
  } else {
    return false; // Not all required fields are filled
  }
};
const getUnfilledStepIndices = (values) => {
  const steps = Object.keys(stepFields);

  const unfilledStepIndices = steps
    .map((step, index) => (!isStageCompleted(step, values) ? index : null))
    .filter((index) => index !== null); // Filter out null values

  return unfilledStepIndices;
};
//This function to calculate how much profile is completed
const getRequiredFieldsForCompletion = (stageName, values) => {
  switch (stageName) {
    case "Deal Details":
      if (values.dealType === "Equity") {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "roundType",
          "preferredInvestors",
        ];
      } else if (values.dealType === "Equity + Debt") {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "debtFundingRequired",
          "roundType",
          "debtType",
          "term",
          "preferredInvestors",
        ];
      } else {
        return [
          "dealType",
          "timeline",
          "fundingRequired",
          "roundType",
          "debtType",
          "term",
          "preferredInvestors",
        ];
      }
      break;

    case "Expected Valuation":
      if (values.dealType === "Equity" || values.dealType === "Equity + Debt") {
        if (values.equityType === "Priced") {
          return [
            "equityType",
            "pricedEquityType",
            "stakeOffered",
            "minPostMoneyValuation",
          ];
        } else if (values.equityType === "Non-Priced") {
          if (values.nonPricedEquityType === "Convertible Debt") {
            return [
              "equityType",
              "nonPricedEquityType",
              "interestRate",
              "valuationCap",
              "discountRate",
            ];
          } else if (values.nonPricedEquityType === "iSAFE") {
            return [
              "equityType",
              "nonPricedEquityType",
              "valuationCap",
              "discountRate",
            ];
          }
        }
      } else {
        return [];
      }
      break;

    case "Deal Interest":
      if (values.softCommitments === true && values.fundingRaised > 0) {
        return [
          "softCommitments",
          "fundingRaised",
          "coInvestors",
          "contactedInvestorNames",
        ];
      } else if (
        values.softCommitments === true &&
        values.fundingRaised === 0
      ) {
        return ["softCommitments", "fundingRaised", "contactedInvestorNames"];
      } else if (values.softCommitments === false && values.fundingRaised > 0) {
        return ["softCommitments", "fundingRaised", "coInvestors"];
      } else {
        // values.softCommitments === false && values.fundingRaised === 0
        return ["softCommitments", "fundingRaised"];
      }

      break;
    case "Submit for Review": {
      return [];
    }
    default:
      return stepFields[stageName] || []; // No fields required by default if stageName does not match
  }
  return stepFields[stageName] || [];
};
const calculateProfileCompletion = (values) => {
  // Get all step names
  try {
    const steps = Object.keys(stepFields);

    // Initialize counters and unfilled fields array
    let totalFields = 0;
    let filledFields = 0;
    let unfilledFields = []; // Store unfilled fields here

    // Iterate over each step and its required fields
    steps.forEach((step) => {
      let requiredFields = getRequiredFieldsForCompletion(step, values); // Get required fields for each step dynamically
      if (step === "Meeting Calendar Setup") {
        if (values["googleAuthToken"]) {
          requiredFields = requiredFields.filter(
            (field) => field !== "microsoftAuthToken"
          );
        } else if (values["microsoftAuthToken"]) {
          requiredFields = requiredFields.filter(
            (field) => field !== "googleAuthToken"
          );
        }
      }
      totalFields += requiredFields.length; // Add to total fields

      // Calculate filled fields in the current step
      requiredFields.forEach((field) => {
        const value = values[field];

        // Check if the field is filled based on its type and add to filledFields
        const isFilled =
          field === "onBoardingComplete" ||
          field === "googleAuthToken" ||
          field === "microsoftAuthToken"
            ? value === true
            : value !== "" &&
              value !== null &&
              !(Array.isArray(value) && value.length === 0);

        if (isFilled) {
          filledFields += 1;
        } else {
          unfilledFields.push(field); // Add unfilled field to the list
        }
      });
    });

    const completionPercentage =
      totalFields > 0 ? (filledFields / totalFields) * 100 : 0;

    // Log unfilled fields
    return completionPercentage.toFixed(0);
  } catch {
    return 0;
  }
};

export {
  stepFields,
  getRequiredFields,
  isStageCompleted,
  getUnfilledStepIndices,
  calculateProfileCompletion,
};
