import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomToolTip from "../../../Common/components/custom/customToolTip";

import defaultCompanyLogo from "../../../../Assets/Images/InvestorPage/Dashboard/defaultCompanyLogo.png";
import Accepted from "../../../../Assets/Images/InvestorPage/Dashboard/accepted.svg";
import Pending from "../../../../Assets/Images/InvestorPage/Dashboard/pending.svg";
import ProgressBar from "../../../Common/components/molecules/progressBar";
const OnGoingDealCard = ({
  deal,
  requestedDate,
  submittedDate,
  acceptedDate,
  IOIBidSubmittedAmount,
  IOIBidAcceptedAmount,
  LOIBidSubmittedAmount,
  LOIBidAcceptedAmount,
  isInterested,
  isRejected,
  stage,
  closingDate,
}) => {
  const {
    noNameTitle,
    roundType,
    industryVerticals,
    backing,
    endMarket,
    fundingRequired,
    fundingRaised,
    dealTags,
    companyLogo,
    companyName,
  } = deal;
  const [firstTag, setFirstTag] = useState();

  function processFirstTag() {
    try {
      setFirstTag(dealTags);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [dealTags]);
  const Navigate = useNavigate();
  const [expand, setExpand] = React.useState(false);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const toggleExpand = (stage, index) => {
    const col = document.getElementById(stage);
    const ele1 = col.getElementsByClassName("group-5")[index];
    const ele2 = col.getElementsByClassName("group-8")[index];
    if (ele1)
      if (ele1.style.display === "none") {
        setExpand(false);
        ele1.style.display = "grid";
      } else {
        setExpand(true);
        ele1.style.display = "none";
      }
    if (ele2) {
      if (ele2.style.display === "none") {
        ele2.style.display = "block";
      } else {
        ele2.style.display = "none";
      }
    }
  };
  // useEffect(() => {
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.offsetWidth;
  //     let totalWidth = 0;
  //     let tagsToShow = [];
  //     let remainingCount = 0;

  //     props.badges.forEach((tag, index) => {
  //       const tagElement = document.createElement("div");
  //       tagElement.style.display = "inline-block";
  //       tagElement.style.visibility = "hidden";
  //       tagElement.style.position = "absolute";
  //       tagElement.className = "Card-div-6";
  //       tagElement.innerText = tag;
  //       document.body.appendChild(tagElement);

  //       const tagWidth = tagElement.offsetWidth;
  //       document.body.removeChild(tagElement);

  //       if (totalWidth + tagWidth <= containerWidth) {
  //         totalWidth += tagWidth;
  //         tagsToShow.push(tag);
  //       } else {
  //         remainingCount = props.badges.length - tagsToShow.length;
  //         return false; // Break out of the loop
  //       }
  //     });

  //     setVisibleTags(tagsToShow);
  //     setRemainingTags(remainingCount);
  //   }
  // }, [props.badges]);
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      cardTags.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags]);
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle industryVerticals parsing
      try {
        if (industryVerticals.length > 0) {
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      // Handle endMarket parsing
      try {
        if (endMarket.length > 0) {
          if (Array.isArray(endMarket)) tags.push(...endMarket);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (backing.length > 0) {
          const backings = [backing];
          if (Array.isArray(backings)) tags.push(...backings);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [roundType, industryVerticals, endMarket]);
  const handleCardClick = () => {
    (isInterested || stage === "watchlist") &&
      Navigate(
        stage === "requestSent"
          ? "/investor/companyDetails"
          : "/investor/companyDetails/requestAccepted",
        {
          state: {
            deal: deal,
            passedStage: stage,
          },
        }
      );
  };
  const stageDisplayMap = {
    requestSent: "Approval Pending",
    requestAccepted: "Request Accepted",
    ioiSubmitted: "Bid Approval Pending",
    ioiAccepted: "Bid Accepted",
    loiSubmitted: "Bid Approval Pending",
    loiAccepted: "Final Bid Accepted",
    dealClosed: "Deal Closed",
  };
  return (
    <div className="Card-div">
      <div
        style={{
          cursor:
            isInterested || stage === "watchlist" ? "pointer" : "not-allowed",
        }}
        onClick={handleCardClick}
      >
        <div className="card-section-1">
          <div className="Card-div-3">
            {stage === "requestSent" ? (
              <>{noNameTitle}</>
            ) : (
              <div className="card-div-img-section">
                <img
                  src={companyLogo ? companyLogo : defaultCompanyLogo}
                  alt="company Logo"
                />
                <p>{companyName}</p>
              </div>
            )}

            {stage === "requestAccepted" ||
            stage === "ioiAccepted" ||
            stage === "loiAccepted" ||
            stage === "dealClosed" ? (
              <CustomToolTip
                noNametitle={stageDisplayMap[stage]}
                position="bottom"
                theme="black"
              >
                <img src={Accepted} alt="accepted" />
              </CustomToolTip>
            ) : (
              <CustomToolTip
                noNametitle={stageDisplayMap[stage]}
                position="bottom"
                theme="black"
              >
                <img src={Pending} alt="pending" />
              </CustomToolTip>
            )}
          </div>

          <div className="Card-div-5" ref={containerRef}>
            {visibleTags.map((tag, index) => (
              <div key={index} className="Card-div-6 blue-text">
                {tag}
              </div>
            ))}
            {remainingTags > 0 && (
              <div className="Card-div-7 blue-text">+{remainingTags}</div>
            )}
          </div>
        </div>
        <div className="card-section-3">
          <ProgressBar
            totalFundingRaised={fundingRaised}
            fundingRequired={fundingRequired}
            roundType={roundType}
            DealTag={firstTag}
            dealId={deal.dealId}
            card={"ongoingDealCard"}
            requestedDate={requestedDate}
            submittedDate={submittedDate}
            acceptedDate={acceptedDate}
            IOIBidSubmittedAmount={IOIBidSubmittedAmount}
            IOIBidAcceptedAmount={IOIBidAcceptedAmount}
            LOIBidSubmittedAmount={LOIBidSubmittedAmount}
            LOIBidAcceptedAmount={LOIBidAcceptedAmount}
            closingDate={closingDate}
            stage={stage}
          />
        </div>
      </div>
    </div>
  );
};

export default OnGoingDealCard;
